import gql from 'graphql-tag';
import { ID, IFunctionsProps } from '.';
import { RouteComponentProps } from 'react-router';
import { DataProps, compose, graphql } from 'react-apollo';

export interface ICarModel {
  model: string;
  number: string;
  // garage:
  insurance_from: string | null;
  insurance_to: string | null;
  permit_from: string | null;
  permit_to: string | null;
  maintenance_from: string | null;
  maintenance_to: string | null;
  maintenance_mileage: number | null;
  maintenance_comment: string | null;
  mot_from: string | null;
  mot_to: string | null;
}

export interface ICar extends ID, ICarModel {
  [x: string]: any;
}

export interface ICarsProps
  extends RouteComponentProps,
    DataProps<{ cars: ICar[] }>,
    IFunctionsProps<ICarModel, ICar> {}

export const CARS = gql`
  query {
    cars {
      id
      model
      number
      insurance_from
      insurance_to
      permit_from
      permit_to
      maintenance_from
      maintenance_to
      maintenance_mileage
      maintenance_comment
      mot_from
      mot_to
    }
  }
`;

export const CREATE_CAR = gql`
  mutation($data: CarCreateInput!) {
    createCar(data: $data) {
      id
    }
  }
`;

export const UPDATE_CAR = gql`
  mutation($data: CarUpdateInput!, $where: CarWhereUniqueInput!) {
    updateCar(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_MANY_CARS = gql`
  mutation($where: CarWhereInput!) {
    deleteManyCars(where: $where) {
      count
    }
  }
`;
const options = { refetchQueries: [{ query: CARS }] };
export const carsCompose = compose(
  graphql(CARS),
  graphql(CREATE_CAR, { name: 'create', options }),
  graphql(UPDATE_CAR, { name: 'update', options }),
  graphql(DELETE_MANY_CARS, { name: 'deleteMany', options })
);
