import { IUser, USER_FRAGMENT } from './users'
import { ID } from './index'
import { RouteComponentProps } from 'react-router'
import { compose, DataProps, graphql } from 'react-apollo'
import gql from 'graphql-tag'

export interface IProformModel {
  number: number;
  transfers: {
    date: string;
    price: number;
    description: string[];
    transferType: string | null;
  }[];
  company: string;
  lang: string;
  address: string;
  country: string;
  telephone: string;
  user: IUser | null;
  users: string[];
}

export interface IProformEditModel {
  number: number;
  transfers: {
    date: string;
    price: number;
    description: string[];
  }[];
  company: string;
  lang: string;
  address: string;
  country: string;
  telephone: string;
  user: { connect: { id: string } } | { disconnect: boolean } | null;
  users: { set: string[] };
}

export interface IProform extends IProformModel, ID {
  [x: string]: any;
}

export interface IProformComponentProps extends RouteComponentProps, DataProps<{ proforms: IProform[] }> {
  create(options: { variables: { data: IProformEditModel; where?: any } }): Promise<{ data: { create: IProform } }>;

  update(options: { variables: { data: IProformEditModel; where: Pick<IProform, 'id'> } }): Promise<{ data: { update: IProform } }>;

  deleteMany(options: { variables: { where: { id_in: string[] } } }): Promise<{ data: { deleteMany: { count: number } } }>;
}

export const PROFORM_FRAGMENT = gql`
  fragment ProformFragment on Proform {
    number
    transfers
    company
    address
    country
    lang
    telephone
    user {
      id
      ...UserFragment
    }
    users
  }
  ${USER_FRAGMENT}
`

export const PROFORMS = gql`
  query proforms {
    proforms {
      id
      ...ProformFragment
    }
  }
  ${PROFORM_FRAGMENT}
`

export const CREATE_PROFORM = gql`
  mutation($data: ProformCreateInput!) {
    createProform(data: $data) {
      id
      ...ProformFragment
    }
  }
  ${PROFORM_FRAGMENT}
`

export const UPDATE_PROFORM = gql`
  mutation($data: ProformUpdateInput!, $where: ProformWhereUniqueInput!) {
    updateProform(data: $data, where: $where) {
      id
      ...ProformFragment
    }
  }
  ${PROFORM_FRAGMENT}
`

export const DELETE_MANY_PROFORMS = gql`
  mutation($where: ProformWhereInput!) {
    deleteManyProforms(where: $where) {
      count
    }
  }
`

const options = { refetchQueries: [{ query: PROFORMS }] }
export const proformsCompose = compose(
  graphql(PROFORMS),
  graphql(CREATE_PROFORM, { name: 'create', options }),
  graphql(UPDATE_PROFORM, { name: 'update', options }),
  graphql(DELETE_MANY_PROFORMS, { name: 'deleteMany', options }),
)
