import * as React from 'react';
import { Grid, GridColumn } from '@atlaskit/page';
import FieldText from '@atlaskit/field-text';
import Modal from '@atlaskit/modal-dialog';
import { IModalProps } from '../shared';
import { IExpense, expenseTypes } from '../shared/expenses';
import { DatePicker } from '@atlaskit/datetime-picker';
import { Label } from '@atlaskit/field-base';
import { RadioGroup } from '@atlaskit/radio';
import { UserSelect } from './UserSelect';
import { User } from '../shared/users';

export default class ExpenseModal extends React.Component<IModalProps<IExpense>, IExpense> {
  state: IExpense = this.props.item;

  render () {
    return (
      <Modal
        actions={[
          { text: 'Сохранить', onClick: () => this.props.onSave(this.state), isDisabled: !this.state.employee },
          { text: 'Закрыть', onClick: () => this.props.onCancel(), autoFocus: true }
        ]}
        autoFocus={false}
        onClose={() => this.props.onCancel()}
        heading={this.props.heading}
        scrollBehavior="outside"
      >
        <Grid>
          <GridColumn medium={6}>
            <Label label={'Дата'}/>
            <DatePicker
              value={this.state.timestamp}
              onChange={value => this.setState({ timestamp: value })}
              autoFocus={true}
              dateFormat={'DD.MM.YY'}
              placeholder={'Введите дату'}
            />
          </GridColumn>
          <GridColumn medium={6}>
            <FieldText
              label={'Сумма'}
              shouldFitContainer={true}
              value={this.state.price.toString(10)  || ''}
              type={'number'}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                this.setState({ price: parseFloat(event.target.value) })
              }
            />
            </GridColumn>
          <GridColumn medium={12}>
            <Label label={'Сотрудник'}/>
            <UserSelect
              onSelect={user => {
                if (user) {
                  this.setState({ employee: user });
                }
              }}
              placeholder="Выберите сотрудника"
              selected={this.state.employee}
              isClearable={false}
              filter={item => ( User.hasType(item, 'EMPLOYEE') && User.hasRole(item, 'EXPENSE') ) || User.hasType(item, 'COMPANY')}
            />
          </GridColumn>
          <GridColumn medium={12}>
            <Label label={'Тип'}/>
            <RadioGroup
              options={Object.keys(expenseTypes).map(type => ( {
                name: 'type',
                value: type,
                label: expenseTypes[ type ]
              } ))}
              value={this.state.type}
              onChange={event => this.setState({ type: event.target.value })}
            />
          </GridColumn>
          <GridColumn medium={12}>
            <FieldText
              label={'Описание'}
              shouldFitContainer={true}
              value={this.state.description  || ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                this.setState({ description: event.target.value })
              }
            />
          </GridColumn>
        </Grid>
      </Modal>
    );
  }
}
