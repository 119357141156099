import { IUser, USER_FRAGMENT } from './users'
import { ID } from './index'
import { RouteComponentProps } from 'react-router'
import { compose, DataProps, graphql } from 'react-apollo'
import gql from 'graphql-tag'

export interface IAuthorizationModel {
  number: number;
  description: string[];
  amount: string;
  date: string;
  user: IUser | null;
  users: string[]
}

export interface IAuthorizationEditModel {
  number: number;
  description: { set: string[] };
  amount: string;
  date: string;
  user: { connect: { id: string } } | { disconnect: boolean } | null;
  users: { set: string[] };
}

export interface IAuthorization extends IAuthorizationModel, ID {
  [x: string]: any;
}
export interface IAuthorizationComponentProps extends RouteComponentProps, DataProps<{ authorizations: IAuthorization[] }> {
  create (options: { variables: { data: IAuthorizationEditModel; where?: any } }): Promise<{ data: { create: IAuthorization } }>;

  update (options: { variables: { data: IAuthorizationEditModel; where: Pick<IAuthorization, 'id'> } }): Promise<{ data: { update: IAuthorization } }>;

  deleteMany (options: { variables: { where: { id_in: string[] } } }): Promise<{ data: { deleteMany: { count: number } } }>;
}

export const AUTHORIZATION_FRAGMENT = gql`
  fragment AuthorizationFragment on Authorization {
    number
    amount
    date
    description
    user {
      id
      ...UserFragment
    }
    users
  }
  ${USER_FRAGMENT}
`;

export const AUTHORIZATIONS = gql`
  query vouchers {
    authorizations {
      id
      ...AuthorizationFragment
    }
  }
  ${AUTHORIZATION_FRAGMENT}
`;

export const CREATE_AUTHORIZATION = gql`
  mutation($data: AuthorizationCreateInput!) {
    createAuthorization(data: $data) {
      id
      ...AuthorizationFragment
    }
  }
  ${AUTHORIZATION_FRAGMENT}
`;

export const UPDATE_AUTHORIZATION = gql`
  mutation($data: AuthorizationUpdateInput!, $where: AuthorizationWhereUniqueInput!) {
    updateAuthorization(data: $data, where: $where) {
      id
      ...AuthorizationFragment
    }
  }
  ${AUTHORIZATION_FRAGMENT}
`;

export const DELETE_MANY_AUTHORIZATIONS = gql`
  mutation($where: AuthorizationWhereInput!) {
    deleteManyAuthorizations(where: $where) {
      count
    }
  }
`;

const options = { refetchQueries: [ { query: AUTHORIZATIONS } ] };
export const authorizationsCompose = compose(
  graphql(AUTHORIZATIONS),
  graphql(CREATE_AUTHORIZATION, { name: 'create', options }),
  graphql(UPDATE_AUTHORIZATION, { name: 'update', options }),
  graphql(DELETE_MANY_AUTHORIZATIONS, { name: 'deleteMany', options })
);
