import * as React from 'react';
import { Query } from 'react-apollo';
import Select from '@atlaskit/select';
import { USERS, IUser, UserTypes } from '../shared/users';

interface IProps {
  placeholder: string;
  selected?: IUser | null;
  defaultValue?: IUser | null;
  spacing?: string;
  isMulti?: boolean;
  isClearable?: boolean;
  menuPlacement?: string;
  maxMenuHeight?: number;
  onSelect (user: IUser | null): void;

  filter (user: IUser): boolean;
}

export class UserSelect extends React.PureComponent<IProps, {}> {

  render () {
    return (
      <Query query={USERS}>
        {({ data, loading }: { data: { users: IUser[] } | undefined; loading: boolean }) => {
          const users: IUser[] = ( ( data && data.users ) || [] )
            .filter(this.props.filter);
          return (
            <Select
              options={
                [
                  {
                    label: UserTypes.COMPANY,
                    options: users
                      .filter(user => user.types.includes('COMPANY'))
                      .map(user => ( { label: user.name, value: user.id } ))
                  },
                  {
                    label: UserTypes.PARTNER,
                    options: users
                      .filter(user => user.types.includes('PARTNER'))
                      .map(user => ( { label: user.name, value: user.id } ))
                  },
                  {
                    label: UserTypes.EMPLOYEE,
                    options: users
                      .filter(user => user.types.includes('EMPLOYEE'))
                      .map(user => ( { label: user.name, value: user.id } ))
                  },
                  {
                    label: UserTypes.CLIENT,
                    options: users
                      .filter(user => user.types.includes('CLIENT'))
                      .map(user => ( { label: user.name, value: user.id } ))
                  },
                ]
              }
              maxMenuHeight={this.props.maxMenuHeight || 200}
              isLoading={loading}
              loadingMessage={() => 'Загружаю...'}
              noOptionsMessage={() => 'Список пуст'}
              placeholder={this.props.placeholder}
              onChange={
                (item: { value: string } | null) => {
                  const selected = item ? users.find(user => user.id === item.value) || null : null;
                  this.props.onSelect(selected);
                }
              }
              value={this.props.selected ? {
                label: this.props.selected.name,
                value: this.props.selected.id
              } : null }
              defaultValue={this.props.defaultValue ? {
                label: this.props.defaultValue.name,
                value: this.props.defaultValue.id
              } : null}
              isClearable={this.props.isClearable !== undefined ? this.props.isClearable : true}

              menuPortalTarget={document.body}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              {...this.props}
            />
          );
        }}
      </Query>
    );
  }
}
