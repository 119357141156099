import * as React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'

import Modal from '@atlaskit/modal-dialog'
import DownloadIcon from '@atlaskit/icon/glyph/download'
import SendIcon from '@atlaskit/icon/glyph/send'
import { UserSelect } from './UserSelect'
import TextArea from '@atlaskit/textarea/dist/es5/components/TextArea'
import { IAuthorization } from '../shared/authorization'
import { Label } from '@atlaskit/field-base'
import { IUser } from '../shared/users'
import Textfield from '@atlaskit/textfield'
import { localePrice, utcDate } from '../shared'
import { IPayment } from '../shared/payments'

interface IProps {
  description: string[];
  payments: IPayment[];
  users: IUser[];

  onCancel(): void;

  onSave(item: IAuthorization): Promise<{ data: { createAuthorization: IAuthorization } }>;

  onUpdate(item: IAuthorization): Promise<{ data: { updateAuthorization: IAuthorization } }>;
}

export const CreateAuthorizationModal: React.FC<IProps> = (props) => {
  const [authorization, setAuthorization] = useState<IAuthorization>({
    id: '',
    number: 1,
    description: props.description,
    amount: props.payments.length ? localePrice(props.payments.reduce((p, c) => p + c.price, 0)) : '',
    date: props.payments.length ? utcDate(props.payments[0].timestamp).format('DD.MM.YYYY') : '',
    user: props.users[0],
    users: props.users.map(u => u.id),
  })
  const [downloading, setDownloading] = useState(false)
  const [sending, setSending] = useState(false)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    axios.post(`${ location.origin }/settings`)
      .then(response => {
        setAuthorization({ ...authorization, number: response.data.AuthorizationNumber + 1 })
      })
  }, [])

  const onDownload = () => {
    setDownloading(true)

    return fetch(`${ location.origin }/authorization/download`, {
      method: 'POST',
      body: JSON.stringify(authorization),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `authorization-${ authorization.number }.pdf`
        document.body.appendChild(a)
        a.click()
        a.remove()
      })
      .then(() => setDownloading(false))
  }
  const onSend = () => {
    setSending(true)

    return axios
      .post(`${ location.origin }/authorization/send`, authorization)
      .then(() => setSending(false))
  }

  return <Modal
    actions={ [
      {
        text: 'Сохранить',
        onClick: () => {
          setSaving(true)

          if (authorization.id) {
            props.onUpdate(authorization)
              .then(() => setSaving(false))
          } else {
            props.onSave(authorization)
              .then(response => {
                setAuthorization({
                  ...authorization,
                  id: response.data.createAuthorization.id,
                  number: response.data.createAuthorization.number,
                })
                setSaving(false)
              })
          }
        },
        isLoading: saving,
      },
      {
        onClick: () => onDownload(),
        iconBefore: <DownloadIcon label={ 'Скачать' }/>,
        isLoading: downloading,
        isDisabled: !authorization.id,
      },
      {
        onClick: () => onSend(),
        iconBefore: <SendIcon label={ 'Отправить' }/>,
        isLoading: sending,
        isDisabled: !authorization.id || !(authorization.user && authorization.user.email),
      },
      { text: 'Закрыть', onClick: () => props.onCancel() },
    ] }
    autoFocus={ false }
    onClose={ () => props.onCancel() }
    heading={ `${ authorization.id ? 'Редактировать' : 'Создать' } лист авторизации ${ authorization.number ? `#${ authorization.number }` : '' }` }
    width="x-large"
    scrollBehavior="outside"
  >
    <Label label={ 'Заказчик' }/>
    <UserSelect
      placeholder={ 'Выберите закачика' }
      onSelect={ user => setAuthorization({ ...authorization, user }) }
      filter={ user => authorization.users.includes(user.id) }
      selected={ authorization.user }
    />
    <Label label={ 'Цена' }/>
    <Textfield
      placeholder='Введите цену'
      defaultValue={ authorization.amount }
      onChange={ (event) => setAuthorization({ ...authorization, amount: event.target.value }) }
    />
    <Label label={ 'Дата' }/>
    <Textfield
      placeholder='Введите дату'
      defaultValue={ authorization.date }
      onChange={ (event) => setAuthorization({ ...authorization, date: event.target.value }) }
    />
    <Label label={ 'Дополнительная информация' }/>
    <div style={ { marginBottom: '20px', minHeight: '180px' } }>
      <TextArea
        value={ authorization.description.join('\n') }
        onChange={ (e) => setAuthorization({ ...authorization, description: e.target.value.split('\n') }) }
        minimumRows={ 10 }
        maxHeight={ '180px' }
      />
    </div>
  </Modal>
}
