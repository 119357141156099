import * as React from 'react';
import PageHeader from '@atlaskit/page-header';
import Button, { ButtonGroup } from '@atlaskit/button';
import { IDefaultState, defaultState, ID } from '../shared';
import { ModalTransition } from '@atlaskit/modal-dialog';
import Item from '@atlaskit/item';
import DropList from '@atlaskit/droplist';
import MoreIcon from '@atlaskit/icon/glyph/more';
import TextField from '@atlaskit/textfield';
import { IOrder } from '../shared/orders';
import DeleteModal from '../components/DeleteModal';

interface IDeleteResult {
  [ x: string ]: { count: number }
}

interface IProps<T> {
  title: React.ReactChild | string;
  clearEdit: T;
  items: T[];
  defaultSortKey: string | null;
  defaultSortOrder?: string;
  addable?: boolean;
  bottomBar?: React.ReactChild;
  noClose?: boolean;

  table (props): React.ReactChild;

  modal (props): React.ReactChild;

  onUndo? (item: T): IOrder;

  onSave (item: T, isAddModalOpen: boolean): Promise<{ data: any }>;

  onDelete (items: T[]): Promise<{ data: IDeleteResult }>;

  onSearch (text: string): void;
}

export class Default<T extends ID | IOrder> extends React.Component<IProps<T>, IDefaultState<T>> {
  state: IDefaultState<T> = {
    ...defaultState,
    edit: this.props.clearEdit,
    defaultSortKey: this.props.defaultSortKey,
    defaultSortOrder: this.props.defaultSortOrder || 'DESC'
  };

  closeModal () {
    this.setState({
      isAddModalOpen: false,
      isEditModalOpen: false,
      isDeleteModalOpen: false,
      edit: this.props.clearEdit,
    });
  }

  render () {
    return (
      <>
        <PageHeader
          actions={
            <ButtonGroup>
              <TextField
                isCompact={true}
                width="medium"
                placeholder="Поиск"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  this.props.onSearch(event.target.value.replace(/ /g, ''))
                }
              />
              {this.props.addable && (
                <Button appearance="primary" onClick={() => this.setState({ isAddModalOpen: true })}>
                  Добавить
                </Button>
              )}
              <DropList
                appearance="default"
                position="bottom right"
                onOpenChange={() => this.setState({ isActionOpen: false })}
                isOpen={this.state.isActionOpen}
                onClick={() => this.setState({ isActionOpen: true })}
                trigger={<Button iconBefore={<MoreIcon label="Еще"/>}/>}
              >
                <Item
                  isDisabled={!this.state.selected.length}
                  onClick={() => {
                    this.setState({ isDeleteModalOpen: true });
                  }}
                >
                  {'Удалить' + ( this.state.selected.length > 1 ? ` (${this.state.selected.length})` : '' )}
                </Item>
              </DropList>
            </ButtonGroup>
          }
          bottomBar={this.props.bottomBar}
        >
          {this.props.title}
        </PageHeader>
        {this.props.table({
          isSelectMode: this.state.isSelectMode,
          selected: this.state.selected.filter(select => this.props.items.find(item => item.id === select.id)),
          defaultPage: this.state.defaultPage,
          defaultSortKey: this.state.defaultSortKey,
          defaultSortOrder: this.state.defaultSortOrder,
          page: this.state.defaultPage,
          sortKey: this.state.defaultSortKey,
          sortOrder: this.state.defaultSortOrder,
          rowsPerPage: localStorage.getItem('rowsPerPage') || 10,
          onEdit: (item: T) => this.setState({ edit: item, isEditModalOpen: true }),
          onSelect: (item: T) =>
            this.setState(prev => ( {
              selected: prev.selected.find(select => select.id === item.id)
                ? prev.selected.filter(select => select.id !== item.id)
                : [ ...prev.selected, item ]
            } )),
          onUndo: (item: T) => this.setState({
            edit: this.props.onUndo ? this.props.onUndo(item) as T : item,
            isAddModalOpen: true
          }),
          onSelectAll: (items: T[]) => this.setState({ selected: items }),
          onUnSelectAll: () => this.setState({ selected: [] }),
          onSetPage: page => this.setState({ defaultPage: page }),
          onSort: ({ sortOrder, key }) => this.setState({ defaultSortKey: key, defaultSortOrder: sortOrder })
        })}
        <ModalTransition>
          {( this.state.isAddModalOpen || this.state.isEditModalOpen ) &&
          this.props.modal({
            scrollBehavior: "outside",
            item: this.state.edit,
            isAddModalOpen: this.state.isAddModalOpen,
            onSave: (item: T) => this.props
              .onSave(item, this.state.isAddModalOpen)
              .then(() => !this.props.noClose ? this.closeModal() : null),
            onCancel: () => this.closeModal()
          })}

          {
            this.state.isDeleteModalOpen &&
            <DeleteModal
              onCancel={() => this.closeModal()}
              onDelete={() => this.props
                .onDelete(this.state.selected)
                .then(() => this.setState({ isDeleteModalOpen: false, selected: [] }))}
              count={this.state.selected.length}
            />
          }
        </ModalTransition>
      </>
    );
  }
}
