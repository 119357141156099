export const companies = {
  RimTransfer: {
    name: 'Rim Transfer',
    url: 'такси-в-италии.рф'
  },
  CesareTransfer: {
    name: 'Cesare Transfer',
    url: 'transfer-cesare-tour.com'
  }
}
