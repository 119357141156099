import gql from 'graphql-tag'
import { ID, IFunctionsProps } from '.'
import { RouteComponentProps } from 'react-router'
import { DataProps, compose, graphql } from 'react-apollo'
import { ICar } from './cars'
import { themed, colors } from '@atlaskit/theme'
import { IPayment } from './payments'
import { ORDER_FRAGMENT } from './fragments'
import { CREATE_USER, IUser, IUserEdit, USERS } from './users'
import { CREATE_VOUCHER, IVoucher, IVoucherEdit, UPDATE_VOUCHER, VOUCHERS } from './voucher'
import { EXCURSIONS, IExcursion } from './excursions'
import * as startOfDay from 'date-fns/start_of_day'
import * as endOfDay from 'date-fns/end_of_day'
import * as addDays from 'date-fns/add_days'
import {
  AUTHORIZATIONS,
  CREATE_AUTHORIZATION,
  IAuthorization,
  IAuthorizationEditModel,
  UPDATE_AUTHORIZATION,
} from './authorization'
import { CREATE_PROFORM, IProform, IProformEditModel, PROFORMS, UPDATE_PROFORM } from './proform'
export const transferType = {
  ARRIVAL: {
    ru: 'Прилет',
    en: 'Arrival',
    it: 'Arrival',
  },
  DEPARTURE: {
    ru: 'Вылет',
    en: 'Departure',
    it: 'Departure',
  },
  DISPOSAL: {
    ru: 'В распоряжение',
    en: 'In your disposal',
    it: 'In your disposal'
  },
  CITY: {
    ru: 'По городу',
    en: 'City transfer',
    it: 'City transfer'
  },
  INTERCITY: {
    ru: 'Междугородний',
    en: 'Intercity transfer',
    it: 'Intercity transfer'
  },
  EXCURSION: {
    ru: 'Экскурсия',
    en: 'Excursion',
    it: 'Excursion'
  },
  OTHER: {
    ru: 'Другое',
    en: 'Other',
    it: 'Other'
  }
}

export const orderStatus = {
  REQUEST: 'Запрос',
  ORDER: 'Заказ',
  CONFIRMED: 'Подтвержден',
  ASSIGNED: 'Распределен',
  CANCELLED: 'Отменен',
}

export const orderStatusAppearance = {
  REQUEST: 'default',
  ORDER: 'moved',
  CONFIRMED: 'inprogress',
  ASSIGNED: 'success',
  CANCELLED: 'removed',
}

export const orderStatusButtonAppearance = {
  REQUEST: 'default',
  ORDER: 'warning',
  CONFIRMED: 'primary',
  ASSIGNED: 'success',
  CANCELLED: 'danger',
}

export const orderTheme = {
  success: {
    background: {
      default: themed({ light: colors.G400, dark: colors.G400 }),
      hover: themed({ light: colors.G500, dark: colors.G500 }),
      selected: themed({ light: colors.G500, dark: colors.G500 }),
    },
    boxShadowColor: {
      focus: themed({ light: colors.G100, dark: colors.G100 }),
    },
    color: {
      default: themed({ light: colors.N0, dark: colors.N0 }),
      selected: themed({ light: colors.N0, dark: colors.N0 }),
    },
  },
}

export interface IOrderModel {
  timestamp: string;
  delivery: string;
  status: string;

  voucher: string | null;
  referer: IUser | null;
  provider: IUser | null;

  driver: IUser | null;
  car: ICar | null;
  transferType: string | null;

  client: IUser | null;

  description: string | null;

  departure: string | null;
  intermediate: string | null;
  destination: string | null;

  passengers: number | null;
  luggage: string | null;
  flight: string | null;
  flightTime: string | null;
  carClass: string | null;
  sign: string | null;

  payments: IPayment[] | null;
  clientNotification: boolean | null;
  income: {
    CASH: number;
    INVOICE: number;
    CARD: number;
  };

  tid: string | null;
  cid: string | null;
  seo: { [x: string]: any } | null;
}

export interface IOrder extends ID, IOrderModel {
}

export interface IOrderEditModel {
  timestamp: string;
  delivery: string;
  status: string;

  voucher: string | null;
  referer: { connect: { id: string } } | { disconnect: boolean } | null;
  provider: { connect: { id: string } } | { disconnect: boolean } | null;

  driver: { connect: { id: string } } | { disconnect: boolean } | null;
  car: { connect: { id: string } } | { disconnect: boolean } | null;
  transferType: string | null;

  client:
    { connect: { id: string } }
    | { disconnect: boolean }
    | null
    | { create: { name: string, phone: string | null, email: string | null, types: { set: string[] }, roles: { set: string[] } } };

  description: string | null;

  departure: string | null;
  destination: string | null;
  intermediate: string | null;
  passengers: number | null;
  luggage: string | null;
  flight: string | null;
  flightTime: string | null;
  carClass: string | null;
  sign: string | null;
  clientNotification: boolean | null;
  payments: any | null;
}

export interface IOrderProps
  extends RouteComponentProps,
    DataProps<{ orders: IOrder[] }>,
    IFunctionsProps<IOrderEditModel, IOrder> {

  loadExcursions: { excursions: IExcursion[] },

  updateOrderVoucher(options: { variables: { ids: string[], voucher: string } }): Promise<{ data: { updateOrderVoucher: IOrder } }>;

  createUser(options: { variables: { data: IUserEdit } }): Promise<{ data: { createUser: IUser } }>;

  createVoucher(options: { variables: { data: IVoucherEdit } }): Promise<{ data: { createVoucher: IVoucher } }>;

  updateVoucher(options: { variables: { data: IVoucherEdit; where: Pick<IVoucher, 'id'> } }): Promise<{ data: { updateVoucher: IVoucher } }>;

  createAuthorization(options: { variables: { data: IAuthorizationEditModel } }): Promise<{ data: { createAuthorization: IAuthorization } }>;

  updateAuthorization(options: { variables: { data: IAuthorizationEditModel; where: Pick<IAuthorization, 'id'> } }): Promise<{ data: { updateAuthorization: IAuthorization } }>;

  createProform(options: { variables: { data: IProformEditModel } }): Promise<{ data: { createProform: IProform } }>;

  updateProform(options: { variables: { data: IProformEditModel; where: Pick<IAuthorization, 'id'> } }): Promise<{ data: { updateProform: IProform } }>;

}

export const ORDERS = gql`
  query($where: OrderWhereInput) {
    orders(where: $where) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`

export const CREATE_ORDER = gql`
  mutation($data: OrderCreateInput!) {
    createOrder(data: $data) {
      ...OrderFragment
    }
  }

  ${ORDER_FRAGMENT}
`

export const UPDATE_ORDER = gql`
  mutation($data: OrderUpdateInput!, $where: OrderWhereUniqueInput!) {
    updateOrder(data: $data, where: $where) {
      ...OrderFragment
    }
  }

  ${ORDER_FRAGMENT}
`

export const UPDATE_ORDER_VOUCHER = gql`
  mutation($ids: [ID!], $voucher: String) {
    updateManyOrders(where: { id_in: $ids }, data: {voucher: $voucher} ) {
      count
    }
  }
`

export const DELETE_MANY_ORDERS = gql`
  mutation($where: OrderWhereInput!) {
    deleteManyOrders(where: $where) {
      count
    }
  }
`

export const ordersCompose = compose(
  graphql(ORDERS, {
    options: {
      variables: {
        where: {
          delivery_gte: startOfDay(Date.now()),
          delivery_lte: endOfDay(addDays(Date.now(), 2)),
        },
      },
      notifyOnNetworkStatusChange: true,
    },
  }),
  graphql(CREATE_ORDER, { name: 'create' }),
  graphql(UPDATE_ORDER, { name: 'update' }),
  graphql(UPDATE_ORDER_VOUCHER, { name: 'updateOrderVoucher' }),
  graphql(DELETE_MANY_ORDERS, { name: 'deleteMany' }),

  graphql(CREATE_USER, { name: 'createUser', options: { refetchQueries: [{ query: USERS }] } }),
  graphql(EXCURSIONS, { name: 'loadExcursions' }),

  graphql(CREATE_VOUCHER, { name: 'createVoucher', options: { refetchQueries: [{ query: VOUCHERS }] } }),
  graphql(UPDATE_VOUCHER, { name: 'updateVoucher', options: { refetchQueries: [{ query: VOUCHERS }] } }),

  graphql(CREATE_AUTHORIZATION, {
    name: 'createAuthorization',
    options: { refetchQueries: [{ query: AUTHORIZATIONS }] },
  }),
  graphql(UPDATE_AUTHORIZATION, {
    name: 'updateAuthorization',
    options: { refetchQueries: [{ query: AUTHORIZATIONS }] },
  }),

  graphql(CREATE_PROFORM, {
    name: 'createProform',
    options: { refetchQueries: [{ query: PROFORMS }] },
  }),
  graphql(UPDATE_PROFORM, {
    name: 'updateProform',
    options: { refetchQueries: [{ query: PROFORMS }] },
  }),
)
