import * as React from 'react';
import { Default } from './Default';
import EmployeesTable from '../components/EmployeesTable';
import EmployeeModal from '../components/EmployeeModal';
import { User, usersCompose, IUserComponentProps, UserRoles, IUser, clearUser } from '../shared/users';
import { IState } from '../shared';

class Employees extends React.Component<IUserComponentProps, IState<IUser>> {
  state: IState<IUser> = { filters: new Map() };

  render () {
    const employees: IUser[] = Array.from(this.state.filters.values()).reduce(
      (prev: IUser[], item) => prev.filter(item),
      ( this.props.data.users || [] )
        .filter(user => User.hasType(user, 'EMPLOYEE'))
    );

    return (
      <Default
        title={'Сотрудники'}
        addable={true}
        clearEdit={{ ...clearUser, types: [ 'EMPLOYEE' ] }}
        defaultSortKey={'name'}
        items={employees}
        table={props => (
          <EmployeesTable
            items={employees}
            isLoading={this.props.data.loading}
            {...props}
          />
        )}
        modal={props => (
          <EmployeeModal heading={`${props.isAddModalOpen ? 'Добавить' : 'Редактировать'} сотрудника`} {...props} />
        )}
        onSave={(item: IUser, isAddModalOpen: boolean) =>
          isAddModalOpen
            ? this.props.create({ variables: { data: User.format(item) } })
            : this.props.update({ variables: { where: { id: item.id }, data: User.format(item) } })
        }
        onDelete={(items: IUser[]) =>
          this.props.deleteMany({ variables: { where: { id_in: items.map(item => item.id as string) } } })
        }
        onSearch={(text: string) =>
          this.setState({
            filters: this.state.filters.set('search', item =>
              [
                item.name,
                item.email,
                item.phone,
                item.roles.reduce((p, v) => p + UserRoles[ v ], '')
              ]
                .join('')
                .toUpperCase()
                .replace(/ /g, '')
                .includes(text.toUpperCase())
            )
          })
        }
      />
    );
  }
}

export default usersCompose(Employees);
