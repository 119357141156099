import * as React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { Checkbox } from '@atlaskit/checkbox';
import Button from '@atlaskit/button';
import EditorEditIcon from '@atlaskit/icon/glyph/editor/edit';
import { ICar } from '../shared/cars';
import { IDefaultTableProps } from '../shared';
import * as isFuture from 'date-fns/is_future';
import * as subMonths from 'date-fns/sub_months';

import { Date } from '@atlaskit/date';

export default class CarsTable extends React.PureComponent<IDefaultTableProps<ICar>, {}> {
  state = {
    expanded: false,
    sortOrder: 'DESC',
    page: 1
  };

  render() {
    return (
      <DynamicTable
        head={{
          cells: [
            {
              content: (
                <Checkbox
                  onChange={() =>
                    !this.props.selected.length ? this.props.onSelectAll(this.props.items) : this.props.onUnSelectAll()
                  }
                  label={null}
                  isIndeterminate={this.props.selected.length !== this.props.items.length}
                  isChecked={this.props.selected.length}
                />
              ),
              width: '20px',
              shouldTruncate: false,
              isSortable: false
            },
            {
              key: 'model',
              content: 'Марка',
              shouldTruncate: true,
              isSortable: true,
              width: '150px'
            },
            {
              key: 'number',
              content: 'Номер',
              shouldTruncate: true,
              isSortable: true,
              width: '100px'
            },
            {
              key: 'insurance_to',
              content: 'Страховка',
              shouldTruncate: true,
              isSortable: true,
              width: '100px'
            },
            {
              key: 'maintenance_to',
              content: 'Техобслуживание',
              shouldTruncate: true,
              isSortable: true,
              width: '100px'
            },
            {
              key: 'mot_to',
              content: 'ТехОсмотр',
              shouldTruncate: true,
              isSortable: true,
              width: '100px'
            },
            {
              key: 'maintenance_comment',
              content: 'Комментарий',
              shouldTruncate: !this.state.expanded,
              isSortable: true
            },
            {
              width: '40px'
            }
          ]
        }}
        rows={this.props.items.map(item => ({
          cells: [
            {
              content: (
                <Checkbox
                  label={null}
                  isChecked={!!this.props.selected.find(select => select.id === item.id)}
                  onChange={() => this.props.onSelect(item)}
                />
              )
            },
            {
              key: item.model.replace(/ /g, ''),
              content: item.model
            },
            {
              key: item.number,
              content: item.number
            },
            {
              key: item.insurance_to,
              content: item.insurance_to ? (
                <Date
                  value={item.insurance_to}
                  format="DD.MM.YYYY"
                  color={isFuture(subMonths(item.insurance_to, 1)) ? '' : 'red'}
                />
              ) : (
                ''
              )
            },
            {
              key: item.maintenance_to,
              content: item.maintenance_to ? (
                <Date
                  value={item.maintenance_to}
                  format="DD.MM.YYYY"
                  color={isFuture(subMonths(item.maintenance_to, 1)) ? '' : 'red'}
                />
              ) : (
                ''
              )
            },
            {
              key: item.mot_to,
              content: item.mot_to ? (
                <Date
                  value={item.mot_to}
                  format="DD.MM.YYYY"
                  color={isFuture(subMonths(item.mot_to, 1)) ? '' : 'red'}
                />
              ) : (
                ''
              )
            },
            {
              key: item.maintenance_comment ? item.maintenance_comment.replace(/ /g, '') : null,
              content: item.maintenance_comment,
              onClick: () => this.setState({ expanded: !this.state.expanded })
            },
            {
              content: (
                <Button iconBefore={<EditorEditIcon label="Редактировать" />} onClick={() => this.props.onEdit(item)}/>
              )
            }
          ],
          key: item.id
        }))}
        emptyView={<h2>Список автомобилей пуст</h2>}
        rowsPerPage={localStorage.getItem('rowsPerPage' || 10)}
        page={this.state.page}
        sortOrder={this.state.sortOrder}
        loadingSpinnerSize="large"
        {...this.props}
      />
    );
  }
}
