import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter, Redirect } from 'react-router-dom';
import App from './App';
import axios from 'axios';
import './index.css';
import '@atlaskit/css-reset';
import registerServiceWorker from './registerServiceWorker';
import { ApolloClient, InMemoryCache, HttpLink } from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import { Route } from 'react-router-dom';
import Login from './views/Login';
import * as isFuture from 'date-fns/is_future';

(async () => {
  const token = localStorage.getItem('token');

  try {
    if (token && !isFuture(JSON.parse(atob(token.split('.')[1] || '')).exp * 1000)) {
      const response = await axios.post(`${location.origin}/auth`, { token });
      localStorage.setItem('token', response.data.token);
    }
  }

  catch (e) {
    console.error(e)
    localStorage.removeItem('token')
    location.replace('/login')
  }

  const client = new ApolloClient({
    link: new HttpLink({
      uri: 'https://gql.blutaxi.ru',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }),
    cache: new InMemoryCache()
  });

  ReactDOM.render(
    <BrowserRouter>
      <Route
        path="/"
        render={props => {
          if (localStorage.getItem('token') && !props.location.pathname.includes('login')) {
            return (
              <ApolloProvider client={client}>
                <App />
              </ApolloProvider>
            );
          } else if (localStorage.getItem('token')) {
            return <Redirect to="/dashboard" />;
          }

          return <Login />;
        }}
      />
    </BrowserRouter>,
    document.getElementById('root') as HTMLElement
  );
})();

registerServiceWorker();
