import gql from 'graphql-tag';
import { compose, DataProps, graphql } from 'react-apollo';
import { ID } from './index';
import { RouteComponentProps } from 'react-router';
import { EXCURSION_FRAGMENT, EXCURSIONS, IExcursion } from './excursions';
import { IUser, USER_FRAGMENT } from './users';

export interface ITransfer {
  id: string;
  delivery: string;
  transferType: string;
  flight: string;
  flightTime: string;
  route: string;
  passengers: string;
  luggage: string;
  car: string;
  sign: string;
  paymentType: string;
  paymentPrice: string;
  description: string;
}

export interface IVoucherModel {
  number: number;

  user: IUser | null;
  driver: IUser | null;

  url: string | null;
  company: string | null;
  lang: string | null;
  transfers: ITransfer[];
  excursions: IExcursion[];
  description: string[];

  users: string[];
  drivers: string[];
}

export interface IVoucher extends ID, IVoucherModel {
}

export interface IVoucherEdit {
  number: number;
  user: { connect: { id: string } } | { disconnect: boolean } | null;
  driver: { connect: { id: string } } | { disconnect: boolean } | null;
  url: string | null;
  company: string | null;
  lang: string | null;
  transfers: ITransfer[];
  excursions: { connect: Array<{ id: string }>, disconnect?: Array<{ id: string }> };
  description: { set: string[] };
  users: { set: string[] };
  drivers: { set: string[] };
}

export interface IVoucherComponentProps extends RouteComponentProps, DataProps<{ vouchers: IVoucher[] }> {
  loadExcursions: { excursions: IExcursion[] },
  lastVoucher: { vouchers: IVoucher[] },

  create (options: { variables: { data: IVoucherEdit; where?: any } }): Promise<{ data: { create: IVoucher } }>;

  update (options: { variables: { data: IVoucherEdit; where: Pick<IVoucher, 'id'> } }): Promise<{ data: { update: IVoucher } }>;

  deleteMany (options: { variables: { where: { id_in: string[] } } }): Promise<{ data: { deleteMany: { count: number } } }>;
}

export const VOUCHER_FRAGMENT = gql`
    fragment VoucherFragment on Voucher {
        number
        user {
            id
            ...UserFragment
        }
        driver {
            id
            ...UserFragment
        }
        url
        company
        lang
        transfers
        excursions {
            ...ExcursionFragment
        }
        description
        users
        drivers
    }
    ${USER_FRAGMENT}
    ${EXCURSION_FRAGMENT}
`;

export const VOUCHERS = gql`
    query vouchers {
        vouchers {
            id
            ...VoucherFragment
        }
    }
    ${VOUCHER_FRAGMENT}
`;

export const LAST_VOUCHER = gql`
    query lastVoucher {
        vouchers(last: 1) {
            id
            ...VoucherFragment
        }
    }
    ${VOUCHER_FRAGMENT}
`;

export const CREATE_VOUCHER = gql`
    mutation($data: VoucherCreateInput!) {
        createVoucher(data: $data) {
            id
            ...VoucherFragment
        }
    }
    ${VOUCHER_FRAGMENT}
`;

export const UPDATE_VOUCHER = gql`
    mutation($data: VoucherUpdateInput!, $where: VoucherWhereUniqueInput!) {
        updateVoucher(data: $data, where: $where) {
            id
            ...VoucherFragment
        }
    }
    ${VOUCHER_FRAGMENT}
`;

export const DELETE_MANY_VOUCHERS = gql`
    mutation($where: VoucherWhereInput!) {
        deleteManyVouchers(where: $where) {
            count
        }
    }
`;

const options = { refetchQueries: [ { query: VOUCHERS } ] };
export const vouchersCompose = compose(
  graphql(VOUCHERS),
  graphql(EXCURSIONS, { name: 'loadExcursions' }),
  graphql(CREATE_VOUCHER, { name: 'create', options }),
  graphql(UPDATE_VOUCHER, { name: 'update', options }),
  graphql(DELETE_MANY_VOUCHERS, { name: 'deleteMany', options })
);
