import * as React from 'react';
import { colors } from '@atlaskit/theme';
import Form, { Field, FormFooter } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import Button from '@atlaskit/button';
import Banner from '@atlaskit/banner';
import axios from 'axios';

export default class Login extends React.Component<{}, { isError: boolean }> {
  state = { isError: false };

  render () {
    return (
      <div
        style={{
          background: colors.B400,
          height: '100%',
          display: 'grid',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            background: '#fff',
            borderRadius: '3px',
            boxShadow: 'rgba(23, 43, 77, 0.2) 0px 1px 1px, rgba(23, 43, 77, 0.25) 0px 0px 0.5px 0px',
            padding: '20px',
            width: '400px'
          }}
        >
          <Form
            onSubmit={({ username, password }) => {
              this.setState({ isError: false });
              return axios
                .post(`${location.origin}/auth`, {
                  user: `${username}|${password}`
                })
                .then(async response => {
                  if (response.data.token) {
                    localStorage.setItem('token', response.data.token);
                    location.replace('/dashboard');
                  } else {
                    this.setState({ isError: true });
                  }
                })
                .catch(() => this.setState({ isError: true }));
            }}
          >
            {({ formProps, submitting }) => (
              <form {...formProps}>
                <Field name="username" label="Логин" isRequired={true} defaultValue="">
                  {({ fieldProps }) => <TextField autoComplete="off" {...fieldProps} />}
                </Field>
                <Field name="password" label="Пароль" defaultValue="" isRequired={true}>
                  {({ fieldProps }) => <TextField type="password" {...fieldProps} />}
                </Field>
                {this.state.isError && (
                  <div style={{ marginTop: '20px' }}>
                    <Banner isOpen={this.state.isError} appearance="error">
                      Неверный пароль или логин
                    </Banner>
                  </div>
                )}
                <FormFooter>
                  <Button type="submit" appearance="primary" isLoading={submitting} isDisabled={submitting}>
                    Войти
                  </Button>
                </FormFooter>
              </form>
            )}
          </Form>
        </div>
      </div>
    );
  }
}
