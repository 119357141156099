import { IOrder, ORDERS } from './orders';
import { ID } from '.';
import { RouteComponentProps } from 'react-router';
import { DataProps, compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { ORDER_FRAGMENT } from './fragments';
import { IUser } from './users';

export const paymentType = {
  CASH: {
    ru: 'Наличные',
    en: 'Cash',
    it: 'Contanti',
  },
  INVOICE: {
    ru: 'Счет-фактура',
    en: 'Invoice',
    it: 'Fattura',
  },
  CARD: {
    ru: 'Карта',
    en: 'Card',
    it: 'Carta',
  },
};

export interface IPaymentModel {
  timestamp: string;
  price: number;
  paid: boolean | false;
  payer: IUser | null;
  payee: IUser | null;
  order: Pick<IOrder, 'id' | 'delivery' | 'description' | 'departure' | 'destination' | 'passengers'>;
  type: string;
}

export interface IPayment extends ID, IPaymentModel {
}

export interface IPaymentEditModel {
  timestamp: string;
  price: number;
  paid: boolean;
  type: string;
  payer?: { connect: { id: string } } | { disconnect: boolean } | null;
  payee?: { connect: { id: string } } | { disconnect: boolean } | null;
}

export interface IPaymentsProps extends RouteComponentProps, DataProps<{ orders: IOrder[] }> {
  updateOrderPayment (options: {
    variables: {
      where: { id: string };
      data: {
        payments: {
          update: {
            where: { id: string };
            data: IPaymentEditModel;
          };
        };
      };
    };
  }): Promise<{ data: { updateOrderPayment: IOrder } }>;

  deleteOrderPayments (options: {
    variables: {
      where: { id: string };
      data: { payments: { delete: Array<{ id: string }> }; };
    };
  }): Promise<{ data: { deleteOrderPayments: { count: number } } }>;
}

export const UPDATE_ORDER_PAYMENT = gql`
    mutation($data: OrderUpdateInput!, $where: OrderWhereUniqueInput!) {
        updateOrder(data: $data, where: $where) {
            ...OrderFragment
        }
    }

    ${ORDER_FRAGMENT}
`;

const options = { refetchQueries: [ { query: ORDERS } ] };
export const paymentsCompose = compose(
  graphql(ORDERS),
  graphql(UPDATE_ORDER_PAYMENT, { name: 'updateOrderPayment', options }),
  graphql(UPDATE_ORDER_PAYMENT, { name: 'deleteOrderPayments', options })
);
