import * as React from 'react';
import { clearTime, IState } from '../shared';
import { Default } from './Default';
import { paymentsCompose, IPaymentsProps, IPayment, paymentType } from '../shared/payments'
import PaymentsTable from '../components/PaymentsTable';
import PaymentModal from '../components/PaymentModal';
import { DatePicker } from '@atlaskit/datetime-picker';
import { UserSelect } from '../components/UserSelect';
import Select from '@atlaskit/select';
import * as format from 'date-fns/format';
import * as isBefore from 'date-fns/is_before';
import { User } from '../shared/users';
import { MoneySelect } from '../components/MoneySelect';
import * as startOfDay from 'date-fns/start_of_day';
import * as endOfDay from 'date-fns/end_of_day';
import * as endOfMonth from 'date-fns/end_of_month';
import * as startOfMonth from 'date-fns/start_of_month';
import * as isEqual from 'date-fns/is_equal';

const clearEdit: IPayment = {
  id: '',
  timestamp: clearTime,
  price: 0.00,
  paid: false,
  payer: null,
  payee: null,
  order: {
    id: '',
    description: '',
    departure: '',
    destination: '',
    passengers: null,
    delivery: ''
  },
  type: 'CASH'
};

class Payments extends React.Component<IPaymentsProps, IState<IPayment>> {
  state: IState<IPayment> = {
    filters: new Map()
      .set('dateStart', item => isBefore(startOfMonth(Date.now()), item.timestamp) || isEqual(startOfDay(Date.now()), item.delivery))
      .set('dateEnd', item => isBefore(item.timestamp, endOfMonth(Date.now())))
  };

  delete (payments: IPayment[]) {
    return Promise.all(
      payments
        .reduce(
          (prev, item) => {
            const fined = prev.findIndex(p => p.orderId === item.order.id);
            if (fined > -1) {
              prev[ fined ].items.push(item);
            } else {
              prev.push({
                orderId: item.order.id,
                items: [ item ]
              });
            }
            return prev;
          },
          [] as Array<{ orderId: string; items: IPayment[] }>
        )
        .map(group => {
          return this.props.deleteOrderPayments({
            variables: {
              data: { payments: { delete: group.items.map(item => ( { id: item.id } )) } },
              where: { id: group.orderId }
            }
          });
        })
    );
  }

  update (prev: IPayment | null, payment: IPayment) {
    return this.props.updateOrderPayment({
      variables: {
        data: {
          payments: {
            update: {
              where: { id: payment.id },
              data: {
                timestamp: payment.timestamp,
                price: payment.price,
                paid: payment.paid,
                type: payment.type,
                payer: payment.payer ? { connect: { id: payment.payer.id } } : prev && prev.payer ? { disconnect: true } : null,
                payee: payment.payee ? { connect: { id: payment.payee.id } } : prev && prev.payee ? { disconnect: true } : null
              }
            }
          }
        },
        where: { id: payment.order.id }
      }
    });
  }

  render () {
    const payments: IPayment[] = Array.from(this.state.filters.values()).reduce(
      (prev: IPayment[], item) => prev.filter(item),
      ( this.props.data.orders || [] ).reduce(
        (prev, order) => ( order.payments
            ? prev.concat(
              ...order.payments.map(payment => ( {
                  ...payment,
                  order: {
                    id: order.id,
                    description: order.description,
                    departure: order.departure,
                    destination: order.destination,
                    passengers: order.passengers,
                    delivery: order.delivery
                  }
                } )
              ))
            : prev
        ),
        [] as IPayment[]
      )
    );
    return (
      <Default
        title={'Pagamenti'}
        addable={false}
        clearEdit={clearEdit}
        defaultSortKey={'timestamp'}
        defaultSortOrder={'ASC'}
        items={payments}
        table={props => <PaymentsTable items={payments} isLoading={this.props.data.loading} {...props} />}
        modal={props => (
          <PaymentModal heading={`${props.isAddModalOpen ? 'Добавить' : 'Редактировать'} платеж`} {...props} />
        )}
        bottomBar={
          <div
            style={{
              display: 'grid',
              gridAutoColumns: 'minmax(150px, 200px)',
              gridAutoFlow: 'column',
              gridGap: '10px'
            }}
          >
            <DatePicker
              spacing="compact"
              placeholder="Дата от"
              dateFormat={'DD.MM.YY'}
              defaultValue={startOfMonth(Date.now())}
              onChange={date => {
                this.setState({
                  filters: this.state.filters.set('dateStart', item => date ? isBefore(startOfDay(date), item.timestamp) : true)
                });
              }}
            />
            <DatePicker
              spacing="compact"
              placeholder="Дата до"
              dateFormat={'DD.MM.YY'}
              defaultValue={endOfMonth(Date.now())}
              onChange={date => {
                this.setState({
                  filters: this.state.filters.set('dateEnd', item => date ? isBefore(item.timestamp, endOfDay(date)) : true)
                });
              }}
            />
            <Select
              options={[
                { value: true, label: 'Pagato' },
                { value: false, label: 'Non pagato' }
              ]}
              placeholder="Status"
              spacing="compact"
              onChange={selected => {
                this.setState({
                  filters: this.state.filters.set('status', item => selected ? item.paid === selected.value : true)
                });
              }}
              isClearable={true}
            />
            <UserSelect
              placeholder="Partner"
              selected={null}
              filter={item => User.hasAnyTypes(item, [ 'PARTNER', 'CLIENT', 'COMPANY' ])}
              spacing="compact"
              onSelect={user => {
                this.setState({
                  filters: this.state.filters.set('user', item => {
                    if (user === null) {
                      return true;
                    }
                    const payee = item.payee ? item.payee.id === user.id : false;
                    const payer = item.payer ? item.payer.id === user.id : false;
                    return payer || payee;
                  })
                });
              }}
            />
            <MoneySelect
              placeholder={'Tipo'}
              spacing="compact"
              onChange={selected => {
                this.setState({
                  filters: this.state.filters.set('type', item => selected ? item.type === selected.value : true)
                });
              }}/>
          </div>
        }
        onSave={(item: IPayment) => this.update(payments.find(payment => payment.id === item.id) || null, item)}
        onDelete={items => this.delete(payments.filter(payment => items.find(item => item.id === payment.id))) as Promise<any>}
        onSearch={(text: string) =>
          this.setState({
            filters: this.state.filters.set('search', item =>
              [
                format(item.timestamp, 'DD.MM.YYYY'),
                item.price,
                item.paid ? 'Pagato' : 'Non pagato',
                item.payee ? item.payee.name : '',
                item.payer ? item.payer.name : '',
                item.order ? item.order.description : '',
                paymentType[ item.type ].it,
                paymentType[ item.type ].en,
                paymentType[ item.type ].ru
              ]
                .join('')
                .toUpperCase()
                .replace(/ /g, '')
                .includes(text.toUpperCase())
            )
          })
        }
      />
    );
  }
}

export default paymentsCompose(Payments);
