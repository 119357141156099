import * as React from 'react';
import { Default } from './Default';
import { IState } from '../shared';
import { IVoucher, IVoucherComponentProps, vouchersCompose } from '../shared/voucher';
import VouchersTable from '../components/VouchersTable';
import { IExcursion } from '../shared/excursions';
import { UpdateVoucherModal } from '../components/UpdateVoucherModal';

const clearEdit: IVoucher = {
  id: '',
  number: 0,
  user: null,
  driver: null,
  url: null,
  lang: 'ru',
  company: 'RimTransfer',
  description: [],
  excursions: [],
  transfers: [],
  users: [],
  drivers: []
};

class Vouchers extends React.Component<IVoucherComponentProps, IState<IVoucher>> {
  state: IState<IVoucher> = { filters: new Map() };

  updateVoucher(voucher: IVoucher, previous?: IVoucher) {
    const currentExcursionsIds = voucher.excursions.map(exc => exc.id);
    const previousExcursionsIds = previous ? previous.excursions.map(exc => exc.id) : [];

    return this.props.update({
      variables: {
        data: {
          number: voucher.number,
          user: voucher.user
            ? { connect: { id: voucher.user.id } }
            : previous && previous.user
              ? { disconnect: true }
              : null,
          driver: voucher.driver
            ? { connect: { id: voucher.driver.id } }
            : previous && previous.driver
              ? { disconnect: true }
              : null,
          url: voucher.url,
          lang: voucher.lang,
          company: voucher.company,
          excursions: {
            connect: currentExcursionsIds.map(id => ({ id })),
            disconnect: previousExcursionsIds.filter(id => !currentExcursionsIds.includes(id))
              .map(id => ({ id }))
          },
          description: { set: voucher.description },
          transfers: voucher.transfers,
          users: { set: voucher.users },
          drivers: { set: voucher.drivers },
        },
        where: { id: voucher.id }
      }
    });
  }

  render() {
    const vouchers: IVoucher[] = Array.from(this.state.filters.values()).reduce(
      (prev: IVoucher[], item) => prev.filter(item), this.props.data.vouchers || []
    );

    const excursions: IExcursion[] = this.props.loadExcursions.excursions || [];

    return (
      <Default
        title={ 'Ваучера' }
        clearEdit={ clearEdit }
        defaultSortKey={ 'number' }
        items={ vouchers }
        table={ props => (
          <VouchersTable
            items={ vouchers }
            isLoading={ this.props.data.loading }
            { ...props }
          />
        ) }
        modal={ props => (
          <UpdateVoucherModal
            excursions={ excursions }
            { ...props }
          />
        ) }
        noClose={ true }
        onSave={ (item: IVoucher) => this.updateVoucher(item, vouchers.find(v => v.id === item.id)) }
        onDelete={ (items: IVoucher[]) =>
          this.props.deleteMany({ variables: { where: { id_in: items.map(item => item.id as string) } } })
        }
        onSearch={ (text: string) =>
          this.setState({
            filters: this.state.filters.set('search', item =>
              [
                item.number,
                item.description,
              ]
                .join('')
                .toUpperCase()
                .replace(/ /g, '')
                .includes(text.toUpperCase())
            )
          })
        }
      />
    );
  }
}

export default vouchersCompose(Vouchers);
