import * as React from 'react';
import Textfield from '@atlaskit/textfield';
import Modal from '@atlaskit/modal-dialog';
import { IModalProps } from '../shared';
import DownloadIcon from '@atlaskit/icon/glyph/download';
import SendIcon from '@atlaskit/icon/glyph/send';
import TextArea from '@atlaskit/textarea/dist/es5/components/TextArea';
import { Label } from '@atlaskit/field-base';
import { UserSelect } from './UserSelect';
import axios from 'axios';
import { useState } from 'react';
import { IAuthorization } from '../shared/authorization'

export const UpdateAuthorizationModal: React.FC<IModalProps<IAuthorization> > = (props) => {
  const [authorization, setAuthorization] = useState<IAuthorization>(props.item);
  const [downloading, setDownloading] = useState(false);
  const [sending, setSending] = useState(false);
  const [saving, setSaving] = useState(false);
  const onDownload = () => {
    setDownloading(true);

    return fetch(`${location.origin}/authorization/download`, {
      method: 'POST',
      body: JSON.stringify(authorization),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `authorization-${ authorization.number }.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .then(() => setDownloading(false));
  };
  const onSend = () => {
    setSending(true);
    return axios
      .post(`${location.origin}/authorization/send`, authorization)
      .then(() => setSending(false));
  };

  return (
    <Modal
      actions={ [
        {
          text: 'Сохранить', onClick: () => {
            setSaving(true);
            props
              .onSave(authorization)
              .then(() => setSaving(false));
          },
          isLoading: saving
        },
        {
          onClick: () => onDownload(),
          iconBefore: <DownloadIcon label={ 'Скачать' }/>,
          isLoading: downloading
        },
        {
          onClick: () => onSend(),
          iconBefore: <SendIcon label={ 'Отправить' }/>,
          isLoading: sending,
          isDisabled: !(authorization.user && authorization.user.email)
        },
        { text: 'Закрыть', onClick: () => props.onCancel(), autoFocus: true }
      ] }
      autoFocus={ false }
      onClose={ () => props.onCancel() }
      heading={ `Редактировать лист авторизации #${ authorization.number }` }
      width="x-large"
      scrollBehavior="outside"
    >
      <Label label={ 'Клиент' }/>
      <UserSelect placeholder={ 'Выберите клиента' }
                  onSelect={ user => user ? setAuthorization({ ...authorization, user }) : null }
                  filter={ user => authorization.users.includes(user.id) }
                  selected={ authorization.user }
      />
      <Label label={ 'Цена' }/>
      <Textfield
        placeholder='Введите цену'
        defaultValue={ authorization.amount }
        onChange={ (event) => setAuthorization({ ...authorization, amount: event.target.value }) }
      />
      <Label label={ 'Дата' }/>
      <Textfield
        placeholder='Введите дату'
        defaultValue={ authorization.date }
        onChange={ (event) => setAuthorization({ ...authorization, date: event.target.value }) }
      />
      <Label label={ 'Дополнительная информация' }/>
      <div style={ { marginBottom: '20px', minHeight: '180px' } }>
        <TextArea
          value={ authorization.description.join('\n') }
          onChange={ (e) => setAuthorization({ ...authorization, description: e.target.value.split('\n') }) }
          minimumRows={ 10 }
          maxHeight={ '180px' }
        />
      </div>
    </Modal>
  );
};
