import * as React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { Checkbox } from '@atlaskit/checkbox';
import Button, { ButtonGroup } from '@atlaskit/button';
import EditorEditIcon from '@atlaskit/icon/glyph/editor/edit';
import { Card, CardCounter, CardTitle, IDefaultTableProps, localePrice, utcDate } from '../shared';
import { IOrder, orderStatus, orderStatusAppearance, transferType } from '../shared/orders'
import Lozenge from '@atlaskit/lozenge';
import UndoIcon from '@atlaskit/icon/glyph/undo';
import * as moment from 'moment';
import AppAccessIcon from '@atlaskit/icon/glyph/app-access';
import { colors } from '@atlaskit/theme';
import NotificationAllIcon from '@atlaskit/icon/glyph/notification-all';
import Tooltip, { TooltipPrimitive } from '@atlaskit/tooltip';
import styled from 'styled-components';


interface IProps extends IDefaultTableProps<IOrder> {
  onUndo(item: IOrder): void;

  onDriverSend(item: IOrder): void;

  onClientSend(item: IOrder): void;
}

const InlineDialog = styled(TooltipPrimitive)`
  max-width: 100%;
  background: rgba(255, 255, 255,0.95);
  box-shadow: 0 0 1px rgba(9,30,66,0.31), 0 20px 32px -8px rgba(9,30,66,0.25);
  color: ${ colors.N900 };
  border-radius: 4px;
  padding: 4px 8px;
  margin-left: 10px;
`;

export default class OrdersTable extends React.PureComponent<IProps, { expanded: boolean }> {
  state = {
    expanded: true
  };

  render() {
    const income: { CASH: number, INVOICE: number, CARD: number } = this.props.items
      .reduce((prev, item) => ({
        CASH: prev.CASH + item.income.CASH,
        INVOICE: prev.INVOICE + item.income.INVOICE,
        CARD: prev.CARD + item.income.CARD,
      }), { CASH: 0, INVOICE: 0, CARD: 0 });

    return (
      <React.Fragment>
        <DynamicTable
          head={ {
            cells: [
              {
                content: (
                  <Checkbox
                    onChange={ () =>
                      !this.props.selected.length ? this.props.onSelectAll(this.props.items) : this.props.onUnSelectAll()
                    }
                    label={ null }
                    isIndeterminate={ this.props.selected.length !== this.props.items.length }
                    isChecked={ this.props.selected.length }
                  />
                ),
                width: '20px',
                shouldTruncate: false,
                isSortable: false
              },
              {
                key: 'delivery',
                content: 'Время подачи',
                shouldTruncate: !this.state.expanded,
                isSortable: true,
                width: '140px'
              },
              {
                key: 'voucher',
                content: 'Ваучер',
                shouldTruncate: !this.state.expanded,
                isSortable: true,
                width: '80px'
              },
              {
                key: 'description',
                content: 'Описание',
                shouldTruncate: !this.state.expanded,
                isSortable: true
              },
              {
                key: 'car',
                content: 'Водитель и Автомобиль',
                shouldTruncate: !this.state.expanded,
                isSortable: true,
                width: '250px'
              },
              {
                key: 'status',
                content: 'Статус',
                shouldTruncate: !this.state.expanded,
                isSortable: true,
                width: '90px'
              },
              {
                key: 'referer',
                content: 'Источник',
                shouldTruncate: !this.state.expanded,
                isSortable: true,
              },
              {
                key: 'provider',
                content: 'Исполнитель',
                shouldTruncate: !this.state.expanded,
                isSortable: true,
              },
              {
                key: 'income',
                content: 'Доход',
                width: '80px'
              },
              {
                width: '160px'
              }
            ]
          } }
          rows={ this.props.items.map(item => ({
            cells: [
              {
                content: (
                  <Checkbox
                    label={ null }
                    isChecked={ !!this.props.selected.find(select => select.id === item.id) }
                    onChange={ () => this.props.onSelect(item) }
                  />
                )
              },
              {
                key: item.delivery,
                content:
                  <Tooltip content={
                    <div style={ { display: 'grid' } }>
                      <span><b>Время подачи</b>: { utcDate(item.delivery).format('DD.MM.YYYY HH:mm') }</span>
                      <span><b>Статус</b>: { orderStatus[item.status] }</span>
                      { item.voucher ? <span><b>Номер ваучера</b>: { item.voucher }</span> : undefined }
                      { item.departure ? <span><b>Отправление</b>: { item.departure }</span> : undefined }
                      { item.destination ? <span><b>Назначение</b>: { item.destination }</span> : undefined }
                      { item.intermediate ? <span><b>Второй адрес</b>: { item.intermediate }</span> : undefined }
                      { item.passengers ? <span><b>Пассажиры</b>: { item.passengers }</span> : undefined }
                      { item.luggage ? <span><b>Багаж</b>: { item.luggage }</span> : undefined }
                      { item.flight ? <span><b>Рейс</b>: { item.flight }</span> : undefined }
                      { item.flightTime ? <span><b>Время рейса</b>: { item.flightTime }</span> : undefined }
                      { item.carClass ? <span><b>Класс авто</b>: { item.carClass }</span> : undefined }
                      { item.transferType && Object.keys(transferType).includes(item.transferType) ? <span><b>Тип трансфера</b>: { transferType[item.transferType].ru }</span> : undefined }
                      { item.sign ? <span><b>Табличка</b>: { item.sign }</span> : undefined }
                      { item.referer ? <span><b>Источник</b>: { item.referer.name }</span> : undefined }
                      { item.provider ? <span><b>Исполнитель</b>: { item.provider.name }</span> : undefined }
                      { item.driver ? <span><b>Водитель</b>: { item.driver.name }</span> : undefined }
                      { item.car ?
                        <span><b>Автомобиль</b>: { item.car.number + ' ' + item.car.model }</span> : undefined }
                      { item.client ? <span><b>Клиент</b>: { item.client.name }</span> : undefined }
                      { item.description ? <span><b>Описание</b>: { item.description }</span> : undefined }
                    </div>
                  } component={ InlineDialog }>
                    <span style={ { color: colors.N800, cursor: 'default' } }>
                      <b>{ utcDate(item.delivery).format('DD.MM.YYYY HH:mm') }</b>
                    </span>
                  </Tooltip>
              },
              {
                key: item.voucher || '',
                content: item.voucher ? `№ ${ item.voucher }/${ moment().format('YY') }` : ''
              },
              {
                key: `${ item.departure }${ item.intermediate }${ item.destination }${ item.passengers }`,
                content: `${ [item.departure, item.intermediate, item.destination].filter(Boolean).join(' => ') }, ${ item.passengers || '?' } чел.`,
              },
              {
                key: `${ item.driver ? item.driver.name : '' }${ item.car ? `${ item.car.model } ${ item.car.number }` : '' }${ item.carClass }`,
                content: <div style={ { display: 'grid' } }>
                  { item.driver ?
                    <span>Водитель: <span
                      style={ { color: colors.B300 } }><b>{ item.driver.name }</b></span></span> : null }
                  { item.car ? <span>Автомобиль: { item.car.model } { item.car.number }</span> : null }
                  { item.carClass ? <span>Класс: { item.carClass }</span> : null }
                </div>
              },
              {
                key: item.status,
                content: <Lozenge
                  appearance={ orderStatusAppearance[item.status] }>{ orderStatus[item.status] }</Lozenge>
              },
              {
                key: item.referer ? item.referer.id : '',
                content: item.referer
                  ? item.referer.types.includes('COMPANY')
                    ? <span style={ { color: colors.B300 } }><b>{ item.referer.name }</b></span>
                    : item.referer.name
                  : ''
              },
              {
                key: item.provider ? item.provider.id : '',
                content: item.provider
                  ? item.provider.types.includes('COMPANY')
                    ? <span style={ { color: colors.B300 } }><b>{ item.provider.name }</b></span>
                    : <span style={ { color: colors.R300 } }><b>{ item.provider.name }</b></span>
                  : ''
              },
              {
                key: item.income,
                content: <div style={ { display: 'grid' } }>
                  <span>Н: { localePrice(item.income.CASH) }</span>
                  <span>К: { localePrice(item.income.CARD) }</span>
                  <span>Ф: { localePrice(item.income.INVOICE) }</span>
                </div>
              },
              {
                content: (
                  <ButtonGroup>
                    <Button iconBefore={ <NotificationAllIcon label="Отправить заказчику"
                                                              primaryColor={ item.clientNotification ? colors.G300 : undefined }/> }
                            onClick={ () => this.props.onClientSend(item) }
                            isDisabled={ !(
                              (item.client && item.client.email)
                              || (item.provider && item.provider.email)
                              || (item.referer && item.referer.email)
                            ) }
                    />
                    <Button iconBefore={ <AppAccessIcon label="Отправить водителю"/> }
                            onClick={ () => this.props.onDriverSend(item) }
                            isDisabled={ !((item.driver && item.driver.email) || (item.provider && item.provider.email)) }
                    />
                    <Button iconBefore={ <UndoIcon label="Обратный трансфер"/> }
                            onClick={ () => this.props.onUndo(item) }
                    />
                    <Button iconBefore={ <EditorEditIcon label="Редактировать"/> }
                            onClick={ () => this.props.onEdit(item) }
                    />
                  </ButtonGroup>
                )
              }
            ],
            key: item.id
          })) }
          emptyView={ <h2>Список заказов пуст</h2> }
          rowsPerPage={ 10 }
          defaultPage={ 1 }
          loadingSpinnerSize="large"
          { ...this.props }
        />
        <div
          style={ {
            marginTop: '20px',
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, 300px)',
            gridGap: '20px'
          } }
        >
          <Card>
            <CardTitle>Итого, всего</CardTitle>
            <CardCounter>{ localePrice(income.CASH + income.CARD + income.INVOICE) }</CardCounter>
          </Card>
          <Card>
            <CardTitle>Итого, наличные</CardTitle>
            <CardCounter>{ localePrice(income.CASH) }</CardCounter>
          </Card>
          <Card>
            <CardTitle>Итого, карта</CardTitle>
            <CardCounter>{ localePrice(income.CARD) }</CardCounter>
          </Card>
          <Card>
            <CardTitle>Итого, фактура</CardTitle>
            <CardCounter>{ localePrice(income.INVOICE) }</CardCounter>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}
