import * as React from 'react';
import Select from '@atlaskit/select';
import { Query } from 'react-apollo';
import { CARS, ICar } from '../shared/cars';

interface IProps {
  placeholder: string;
  selected?: ICar | null;
  spacing?: string;
  isMulti?: boolean;
  menuPlacement?: string;

  onSelect (car: ICar | null): void;
}

export class CarSelect extends React.PureComponent<IProps, {}> {
  render () {
    return (
      <Query query={CARS}>
        {({ data, loading }: { data: { cars: ICar[] } | undefined; loading: boolean }) => {
          const cars = ( data && data.cars ) || [];
          return (
            <Select
              options={cars.map(car => ( {
                label: `${car.model} ${car.number}`,
                value: car.id
              } ))}
              isLoading={loading}
              loadingMessage={() => 'Загружаю...'}
              noOptionsMessage={() => 'Список пуст'}
              defaultValue={this.props.selected && {
                label: this.props.selected.model,
                value: this.props.selected.id
              } || null}
              onChange={(selected: { label: string; value: string } | null) =>
                this.props.onSelect(cars.find(car => selected ? car.id === selected.value : false) || null)
              }
              placeholder={this.props.placeholder}
              isClearable={true}
              {...this.props}
            />
          );
        }}
      </Query>
    );
  }
}
