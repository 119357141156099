import * as React from 'react';
import PartnerModal from '../components/PartnersModal';
import PartnersTable from '../components/PartnersTable';
import { Default } from './Default';
import { User, usersCompose, IUserComponentProps, clearUser, IUser, UserRoles } from '../shared/users';
import { IState } from '../shared';

class Partners extends React.Component<IUserComponentProps, IState<IUser>> {
  state: IState<IUser> = { filters: new Map() };

  render () {
    const partners: IUser[] = Array.from(this.state.filters.values()).reduce(
      (prev: IUser[], item) => prev.filter(item),
      ( this.props.data.users || [] )
        .filter(user => User.hasType(user, 'PARTNER'))
    );

    return (
      <Default
        title={'Партнеры'}
        addable={true}
        clearEdit={{ ...clearUser, types: [ 'PARTNER' ] }}
        defaultSortKey={'name'}
        items={partners}
        table={props => (
          <PartnersTable
            items={partners}
            isLoading={this.props.data.loading}
            {...props}
          />
        )}
        modal={props => (
          <PartnerModal heading={`${props.isAddModalOpen ? 'Добавить' : 'Редактировать'} патрнера`} {...props} />
        )}
        onSave={(item: IUser, isAddModalOpen: boolean) => isAddModalOpen
          ? this.props.create({ variables: { data: User.format(item) } })
          : this.props.update({ variables: { where: { id: item.id }, data: User.format(item) } })}
        onDelete={(items: IUser[]) =>
          this.props.deleteMany({ variables: { where: { id_in: items.map(item => item.id as string) } } })
        }
        onSearch={(text: string) =>
          this.setState({
            filters: this.state.filters.set('search', item =>
              [
                item.name,
                item.email,
                item.phone,
                item.description,
                item.roles.reduce((p, v) => p + UserRoles[ v ], '')
              ]
                .join('')
                .toUpperCase()
                .replace(/ /g, '')
                .includes(text.toUpperCase())
            )
          })
        }
      />
    );
  }
}

export default usersCompose(Partners);
