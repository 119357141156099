import * as React from 'react';
import Select from '@atlaskit/select';
import { paymentType } from '../shared/payments'


interface Item {
  label: string;
  value: string | null;
}

interface IProps {
  placeholder: string;
  selected?: Item | null;
  spacing?: string;
  isMulti?: boolean;

  onChange (item: Item | null): void;
}

export class MoneySelect extends React.PureComponent<IProps, {}> {

  render () {
    return (
      <Select
        options={Object.keys(paymentType).map(key => ( { label: paymentType[ key ].it, value: key } ))}
        maxMenuHeight={150}
        placeholder={this.props.placeholder}
        onChange={this.props.onChange}
        isClearable={true}
        {...this.props}
      />
    );
  }
}
