import * as React from 'react';
import { Grid, GridColumn } from '@atlaskit/page';
import FieldText from '@atlaskit/field-text';
import Modal from '@atlaskit/modal-dialog';
import { IModalProps } from '../shared';
import { excursionTypes, IExcursion } from '../shared/excursions';
import { Label } from '@atlaskit/field-base';
import { RadioGroup } from '@atlaskit/radio';

export default class ExcursionModal extends React.Component<IModalProps<IExcursion>, {}> {
  state: IExcursion = this.props.item;

  fieldText (key: string, label: string) {
    return (
      <FieldText
        label={label}
        shouldFitContainer={true}
        value={this.state[ key ] || ''}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({ [ key ]: event.target.value })}
      />
    );
  }

  render () {
    return (
      <Modal
        actions={[
          { text: 'Сохранить', onClick: () => this.props.onSave(this.state) },
          { text: 'Закрыть', onClick: () => this.props.onCancel(), autoFocus: true }
        ]}
        autoFocus={false}
        onClose={() => this.props.onCancel()}
        heading={this.props.heading}
        scrollBehavior="outside"
      >
        <Grid>
          <GridColumn medium={12}>{this.fieldText('title', 'Название')}</GridColumn>
          <GridColumn medium={12}>{this.fieldText('description', 'Описание')}</GridColumn>
          <GridColumn medium={12}>
            <Label label={'Тип'}/>
            <RadioGroup
              options={Object.keys(excursionTypes).map(type => ( {
                name: 'type',
                value: type,
                label: excursionTypes[ type ]
              } ))}
              value={this.state.type}
              onChange={event => this.setState({ type: event.target.value })}
            />
          </GridColumn>
        </Grid>
      </Modal>
    );
  }
}
