import { ID, IFunctionsProps } from './index';
import { RouteComponentProps } from 'react-router';
import { compose, DataProps, graphql } from 'react-apollo';
import gql from 'graphql-tag';

export const excursionTypes = {
  EXCURSION: 'Экскурсия',
  TOUR: 'Тур'
};

export interface IExcursionModel {
  title: string;
  description: string | null;
  type: string;
}

export interface IExcursion extends ID, IExcursionModel {
}


export interface IExcursionComponentProps extends RouteComponentProps,
  DataProps<{ excursions: IExcursion[] }>,
  IFunctionsProps<IExcursionModel, IExcursion> {}

export const EXCURSION_FRAGMENT = gql`
    fragment ExcursionFragment on Excursion {
        id
        title
        description
        type
    }
`;


export const EXCURSIONS = gql`
    query excursions {
        excursions {
            id
            title
            description
            type
        }
    }
`;

export const CREATE_EXCURSION = gql`
    mutation($data: ExcursionCreateInput!) {
        createExcursion(data: $data) {
            id
            title
            description
            type
        }
    }
`;

export const UPDATE_EXCURSION = gql`
    mutation($data: ExcursionUpdateInput!, $where: ExcursionWhereUniqueInput!) {
        updateExcursion(data: $data, where: $where) {
            id
            title
            description
            type
        }
    }
`;

export const DELETE_MANY_EXCURSIONS = gql`
    mutation($where: ExcursionWhereInput!) {
        deleteManyExcursions(where: $where) {
            count
        }
    }
`;

const options = { refetchQueries: [ { query: EXCURSIONS } ] };
export const excursionsCompose = compose(
  graphql(EXCURSIONS),
  graphql(CREATE_EXCURSION, { name: 'create', options }),
  graphql(UPDATE_EXCURSION, { name: 'update', options }),
  graphql(DELETE_MANY_EXCURSIONS, { name: 'deleteMany', options })
);
