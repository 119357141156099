import * as React from 'react';
import { Grid, GridColumn } from '@atlaskit/page';
import FieldText from '@atlaskit/field-text';
import Modal from '@atlaskit/modal-dialog';
import { IOrder } from '../shared/orders';
import axios from 'axios';
import { localePrice, utcDate } from '../shared';
import { IPayment } from '../shared/payments';
import TextArea from '@atlaskit/textarea';
import { Label } from '@atlaskit/field-base';
import { useState } from 'react';

interface IProps {
  item: IOrder;
  payment: IPayment | null;
  route: string | null;

  onCancel(): void;

  onSuccess(): void;
}

export const SendDriverModal: React.FC<IProps> = (props) => {
  const [order, setOrder] = useState<IOrder>(props.item);
  const [deliveryDate, setDeliveryDate] = useState<string>(utcDate(order.delivery).format('DD.MM.YYYY'));
  const [deliveryTime, setDeliveryTime] = useState<string>(utcDate(order.delivery).format('HH:mm'));
  const [description, setDescription] = useState(props.item.description);

  const [payment, setPayment] = useState({
    price: localePrice(props.payment ? props.payment.price : 0),
    type: props.payment
      ? {
        CARD: 'Carta',
        CASH: 'Contanti',
        INVOICE: 'Fattura'
      }[props.payment.type]
      : 'Contanti'
  });
  const [route, setRoute] = useState(props.route);
  const [sending, setSending] = useState(false);

  const onSend = () => {
    setSending(true);
    return axios
      .post(`${location.origin}/driver/send`, { ...order, deliveryDate, deliveryTime, description, payment, route })
      .then(() => setSending(false))
      .then(props.onSuccess)
      .catch(props.onCancel);
  };

  return (
    <Modal
      actions={ [
        {
          text: 'Отправить',
          onClick: () => onSend(),
          isLoading: sending,
          isDisabled: !(
            (order.driver && order.driver.email) ||
            (order.provider && order.provider.email)
          )
        },
        { text: 'Закрыть', onClick: () => props.onCancel(), autoFocus: true }
      ] }
      autoFocus={ false }
      onClose={ () => props.onCancel() }
      heading={ `Отправить водителю ${ order.driver ? order.driver.name : '' }` }
      scrollBehavior="outside"
    >
      <Grid>
        <GridColumn medium={ 12 }>
          <FieldText
            label={ 'Дата трансфера' }
            shouldFitContainer={ true }
            value={ deliveryDate }
            onChange={ (event: React.ChangeEvent<HTMLInputElement>) => setDeliveryDate(event.target.value) }
          />
        </GridColumn>
        <GridColumn medium={ 12 }>
          <FieldText
            label={ 'Время подачи' }
            shouldFitContainer={ true }
            value={ deliveryTime }
            onChange={ (event: React.ChangeEvent<HTMLInputElement>) => setDeliveryTime(event.target.value) }
          />
        </GridColumn>
        <GridColumn medium={ 12 }>
          <FieldText
            label={ 'Рейс' }
            shouldFitContainer={ true }
            value={ order.flight || '' }
            onChange={ (event: React.ChangeEvent<HTMLInputElement>) => setOrder({
              ...order,
              flight: event.target.value
            }) }
          />
        </GridColumn>
        <GridColumn medium={ 12 }>
          <FieldText
            label={ 'Время рейса' }
            shouldFitContainer={ true }
            value={ order.flightTime || '' }
            onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
              setOrder({ ...order, flightTime: event.target.value })
            }
          />
        </GridColumn>
        <GridColumn medium={ 12 }>
          <FieldText
            label={ 'Маршрут трансфера' }
            shouldFitContainer={ true }
            value={ route || '' }
            onChange={ (event: React.ChangeEvent<HTMLInputElement>) => setRoute(event.target.value) }
          />
        </GridColumn>
        <GridColumn medium={ 12 }>
          <FieldText
            label={ 'Пассажиры' }
            shouldFitContainer={ true }
            value={ (order.passengers || 0).toString() }
            onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
              setOrder({ ...order, passengers: parseInt(event.target.value, 10) || 0 })
            }
          />
        </GridColumn>
        <GridColumn medium={ 12 }>
          <FieldText
            label={ 'Багаж' }
            shouldFitContainer={ true }
            value={ order.luggage || '' }
            onChange={ (event: React.ChangeEvent<HTMLInputElement>) => setOrder({
              ...order,
              luggage: event.target.value
            }) }
          />
        </GridColumn>
        <GridColumn medium={ 12 }>
          <FieldText
            label={ 'Класс автомобиля' }
            shouldFitContainer={ true }
            value={ order.carClass || '' }
            onChange={ (event: React.ChangeEvent<HTMLInputElement>) => setOrder({
              ...order,
              carClass: event.target.value
            }) }
          />
        </GridColumn>
        <GridColumn medium={ 12 }>
          <FieldText
            label={ 'Табличка' }
            shouldFitContainer={ true }
            value={ order.sign || '' }
            onChange={ (event: React.ChangeEvent<HTMLInputElement>) => setOrder({
              ...order,
              sign: event.target.value
            }) }
          />
        </GridColumn>
        <GridColumn medium={ 12 }>
          <FieldText
            label={ 'Сумма' }
            shouldFitContainer={ true }
            value={ payment.price }
            onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
              setPayment({ ...payment, price: event.target.value })
            }
          />
        </GridColumn>
        <GridColumn medium={ 12 }>
          <FieldText
            label={ 'Тип платежа' }
            shouldFitContainer={ true }
            value={ payment.type }
            onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
              setPayment({ ...payment, type: event.target.value })
            }
          />
        </GridColumn>
        <GridColumn medium={ 12 }>
          <Label label={ 'Дополнительная информация' }/>
          <TextArea
            value={ description || '' }
            onChange={ e => setDescription(e.target.value) }
            minimumRows={ 10 }
            maxHeight={ '180px' }
          />
        </GridColumn>
      </Grid>
    </Modal>
  );
};
