import * as React from 'react'
import { Default } from './Default'
import { IState } from '../shared'
import { authorizationsCompose, IAuthorization, IAuthorizationComponentProps } from '../shared/authorization'
import * as moment from 'moment'
import AuthorizationTable from '../components/AuthorizationTable'
import { UpdateAuthorizationModal } from '../components/UpdateAuthorizationModal'

const clearEdit: IAuthorization = {
  id: '',
  number: 0,
  amount: '',
  description: [],
  date: moment().format('DD.MM.YYYY'),
  user: null,
  users: [],
}

class Authorizations extends React.Component<IAuthorizationComponentProps, IState<IAuthorization>> {
  state: IState<IAuthorization> = { filters: new Map() }

  updateAuthorization(authorization: IAuthorization, previous?: IAuthorization) {
    return this.props.update({
      variables: {
        data: {
          number: authorization.number,
          amount: authorization.amount,
          date: authorization.date,
          description: { set: authorization.description },
          user: authorization.user
            ? { connect: { id: authorization.user.id } }
            : previous && previous.user
              ? { disconnect: true }
              : null,
          users: {
            set: authorization.users,
          },
        },
        where: { id: authorization.id },
      },
    })
  }

  render() {
    const authorizations: IAuthorization[] = Array.from(this.state.filters.values()).reduce(
      (prev: IAuthorization[], item) => prev.filter(item), this.props.data.authorizations || [],
    )

    return (
      <Default
        title={ 'Листы авторизаций' }
        clearEdit={ clearEdit }
        defaultSortKey={ 'number' }
        items={ authorizations }
        table={ props => (
          <AuthorizationTable
            items={ authorizations }
            isLoading={ this.props.data.loading }
            { ...props }
          />
        ) }
        modal={ props => (
          <UpdateAuthorizationModal
            { ...props }
          />
        ) }
        noClose={ true }
        onSave={ (item: IAuthorization) => this.updateAuthorization(item, authorizations.find(v => v.id === item.id)) }
        onDelete={ (items: IAuthorization[]) =>
          this.props.deleteMany({ variables: { where: { id_in: items.map(item => item.id as string) } } })
        }
        onSearch={ (text: string) =>
          this.setState({
            filters: this.state.filters.set('search', item =>
              [
                item.number,
                item.description,
              ]
                .join('')
                .toUpperCase()
                .replace(/ /g, '')
                .includes(text.toUpperCase()),
            ),
          })
        }
      />
    )
  }
}

export default authorizationsCompose(Authorizations)
