import gql from 'graphql-tag';
import { compose, graphql, DataProps } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import { ID, IFunctionsProps } from '.';

export enum UserTypes {
  COMPANY = 'Компания',
  PARTNER = 'Партнер',
  EMPLOYEE = 'Сотрудник',
  CLIENT = 'Клиент'
}

export enum UserRoles {
  REFERER = 'Источник',
  PROVIDER = 'Исполнитель',
  CASH = 'Касса',
  DRIVER = 'Водитель',
  EXPENSE = 'Расходы'
}

export interface IUserModel {
  name: string;
  phone: string | null;
  email: string | null;
  description: string | null;
  types: string[];
  roles: string[];
}

export interface IUser extends ID, IUserModel {
}

export const clearUser = { id: '', name: '', phone: '', email: '', description: '', types: [], roles: [] };

export interface IUserEdit {
  name: string;
  phone: string | null;
  email: string | null;
  description: string | null;
  types: { set: string[] };
  roles: { set: string[] };
}

export interface IUserComponentProps extends RouteComponentProps,
  DataProps<{ users: IUser[] }>,
  IFunctionsProps<IUserEdit, IUser> {
}

export class User {

  static format = (user: IUser): IUserEdit => ( {
    name: user.name,
    phone: user.phone,
    email: user.email,
    description: user.description,
    types: { set: user.types },
    roles: { set: user.roles }
  } );

  static hasRole = (user: IUser, role: string): boolean => user.roles.includes(role);
  static hasAnyRole = (user: IUser, roles: string[]): boolean => !!user.roles.filter(role => roles.includes(role)).length;
  static hasOnlyRoles = (user: IUser, roles: string[]): boolean => user.roles.every(role => roles.includes(role));

  static hasType = (user: IUser, type: string): boolean => user.types.includes(type);
  static hasAnyTypes = (user: IUser, types: string[]): boolean => !!user.types.filter(type => types.includes(type)).length;
  static hasOnlyTypes = (user: IUser, types: string[]): boolean => user.types.every(type => types.includes(type));

}

export const USER_FRAGMENT = gql`
    fragment UserFragment on User {
        name
        phone
        email
        description
        types
        roles
    }
`;

export const USERS = gql`
    query {
        users {
            id
            ...UserFragment
        }
    }
    ${USER_FRAGMENT}
`;

export const CREATE_USER = gql`
    mutation($data: UserCreateInput!) {
        createUser(data: $data) {
            id
            ...UserFragment
        }
    }
    ${USER_FRAGMENT}
`;

export const UPDATE_USER = gql`
    mutation($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
        updateUser(data: $data, where: $where) {
            id
            ...UserFragment
        }
    }
    ${USER_FRAGMENT}
`;

export const DELETE_MANY_USERS = gql`
    mutation($where: UserWhereInput!) {
        deleteManyUsers(where: $where) {
            count
        }
    }
`;

const options = { refetchQueries: [ { query: USERS } ] };
export const usersCompose = compose(
  graphql(USERS),
  graphql(CREATE_USER, { name: 'create', options }),
  graphql(UPDATE_USER, { name: 'update', options }),
  graphql(DELETE_MANY_USERS, { name: 'deleteMany', options })
);
