import * as React from 'react';
import { Grid, GridColumn } from '@atlaskit/page';
import Modal from '@atlaskit/modal-dialog';
import { ICar } from '../shared/cars';
import { IModalProps } from '../shared';
import { DatePicker } from '@atlaskit/datetime-picker';
import { Label } from '@atlaskit/field-base';
import FieldText from '@atlaskit/field-text';

export default class CarModal extends React.Component<IModalProps<ICar>, ICar> {
  state: ICar = this.props.item;

  datePicker (key: string, label: string) {
    return (
      <React.Fragment>
        <Label htmlFor={`react-select-${key}--input`} label={label}/>
        <DatePicker
          id={key}
          value={this.state[ key ] || ''}
          onChange={value => this.setState({ [ key ]: value })}
          autoFocus={true}
          dateFormat={'DD.MM.YY'}
          placeholder={'Выберите дату'}
        />
      </React.Fragment>
    );
  }

  fieldText (key: string, label: string, type = 'text') {
    return (
      <FieldText
        label={label}
        shouldFitContainer={true}
        value={this.state[ key ] || ''}
        type={type}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          this.setState({
            [ key ]: type === 'number' && event.target.value ? parseInt(event.target.value, 10) : event.target.value
          })
        }
      />
    );
  }

  render () {
    return (
      <Modal
        actions={[
          { text: 'Сохранить', onClick: () => this.props.onSave(this.state) },
          { text: 'Закрыть', onClick: () => this.props.onCancel() }
        ]}
        autoFocus={false}
        onClose={() => this.props.onCancel()}
        heading={this.props.heading}
        scrollBehavior="outside"
      >
        <Grid>
          <GridColumn medium={6}>{this.fieldText('model', 'Введите модель')}</GridColumn>
          <GridColumn medium={6}>{this.fieldText('number', 'Введите номер')}</GridColumn>
          <GridColumn medium={6}>{this.datePicker('insurance_from', 'Страховка (от)')}</GridColumn>
          <GridColumn medium={6}>{this.datePicker('insurance_to', 'Страховка (до)')}</GridColumn>
          <GridColumn medium={6}>{this.datePicker('permit_from', 'Разрешение на въезд (от)')}</GridColumn>
          <GridColumn medium={6}>{this.datePicker('permit_to', 'Разрешение на въезд (до)')}</GridColumn>
          <GridColumn medium={6}>{this.datePicker('mot_from', 'ТехОсмотр (от)')}</GridColumn>
          <GridColumn medium={6}>{this.datePicker('mot_to', 'ТехОсмотр (до)')}</GridColumn>
          <GridColumn medium={6}>
            {this.fieldText('maintenance_mileage', 'Техобслуживание (километраж)', 'number')}
          </GridColumn>
          <GridColumn medium={6}>{this.datePicker('maintenance_from', 'Техобслуживание (от)')}</GridColumn>
          <GridColumn medium={6}>{this.datePicker('maintenance_to', 'Техобслуживание (до)')}</GridColumn>
          <GridColumn medium={12}>{this.fieldText('maintenance_comment', 'Введите комментарий')}</GridColumn>
        </Grid>
      </Modal>
    );
  }
}
