import { ID, IFunctionsProps } from './index';
import { RouteComponentProps } from 'react-router';
import { DataProps, compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { IUser, USER_FRAGMENT } from './users';

export const expenseTypes = {
  INVOICE: 'Фактура',
  CASH: 'Наличные',
  CARD :'Карта'
};

export interface IExpenseModel {
  timestamp: string;
  price: number;
  type: string;
  description: string | null;
  employee: IUser | null;
}

export interface IExpenseEditModel {
  timestamp: string;
  description: string | null;
  price: number;
  type: string;
  employee: { connect: { id: string } };
}

export interface IExpense extends IExpenseModel, ID {
  [x: string]: any;
}

export interface IExpensesProps
  extends RouteComponentProps,
    DataProps<{ expenses: IExpense[] }>,
    IFunctionsProps<IExpenseEditModel, ID> {}

export const EXPENSES = gql`
  query {
    expenses {
      id
      timestamp
      description
      price
      type
      employee {
        id
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const CREATE_EXPENSE = gql`
  mutation($data: ExpenseCreateInput!) {
    createExpense(data: $data) {
      id
    }
  }
`;

export const UPDATE_EXPENSE = gql`
  mutation($data: ExpenseUpdateInput!, $where: ExpenseWhereUniqueInput!) {
    updateExpense(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_MANY_EXPENSES = gql`
  mutation($where: ExpenseWhereInput!) {
    deleteManyExpenses(where: $where) {
      count
    }
  }
`;

const options = { refetchQueries: [{ query: EXPENSES }] };
export const expensesCompose = compose(
  graphql(EXPENSES),
  graphql(CREATE_EXPENSE, { name: 'create', options }),
  graphql(UPDATE_EXPENSE, { name: 'update', options }),
  graphql(DELETE_MANY_EXPENSES, { name: 'deleteMany', options })
);
