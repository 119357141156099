import * as React from 'react'
import { useState } from 'react'
import axios from 'axios'
import Textfield from '@atlaskit/textfield'
import Modal from '@atlaskit/modal-dialog'
import DownloadIcon from '@atlaskit/icon/glyph/download'
import SendIcon from '@atlaskit/icon/glyph/send'
import DynamicTable from '@atlaskit/dynamic-table'
import { Checkbox } from '@atlaskit/checkbox'
import Lozenge from '@atlaskit/lozenge'
import TextArea from '@atlaskit/textarea/dist/es5/components/TextArea'
import { Label } from '@atlaskit/field-base'
import Select from '@atlaskit/select'
import { excursionTypes, IExcursion } from '../shared/excursions'
import { IModalProps } from '../shared'
import { IVoucher } from '../shared/voucher'
import { UserSelect } from './UserSelect'
import { companies } from '../shared/companies'

interface IProps extends IModalProps<IVoucher> {
  excursions: IExcursion[];
}

export const UpdateVoucherModal: React.FC<IProps> = (props) => {
  const [voucher, setVoucher] = useState<IVoucher>(props.item)
  const [description, setDescription] = useState<string>(props.item.description.join('\n'))
  const [selected, setSelected] = useState<IExcursion[]>(props.item.excursions)
  const [downloading, setDownloading] = useState(false)
  const [sending, setSending] = useState(false)
  const [saving, setSaving] = useState(false)
  const onDownload = () => {
    setDownloading(true)

    return fetch(`${ location.origin }/voucher/download`, {
      method: 'POST',
      body: JSON.stringify({
        ...voucher,
        excursions: selected,
        description: description.split('\n'),
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `voucher-${ voucher.number }.pdf`
        document.body.appendChild(a)
        a.click()
        a.remove()
      })
      .then(() => setDownloading(false))
  }
  const onSend = () => {
    setSending(true)
    return axios
      .post(`${ location.origin }/voucher/send`, {
        ...voucher,
        excursions: selected,
        description: description.split('\n'),
      })
      .then(() => setSending(false))
  }

  return (
    <Modal
      actions={ [
        {
          text: 'Сохранить', onClick: () => {
            setSaving(true)
            props.onSave({
              ...voucher,
              excursions: selected,
              description: description.split('\n'),
            }).then(() => setSaving(false))
          },
          isLoading: saving,
        },
        {
          onClick: () => onDownload(),
          iconBefore: <DownloadIcon label={ 'Скачать' }/>,
          isLoading: downloading,
        },
        {
          onClick: () => onSend(),
          iconBefore: <SendIcon label={ 'Отправить' }/>,
          isLoading: sending,
          isDisabled: !(voucher.user && voucher.user.email),
        },
        { text: 'Закрыть', onClick: () => props.onCancel(), autoFocus: true },
      ] }
      autoFocus={ false }
      onClose={ () => props.onCancel() }
      heading={ `Редактировать ваучер #${ voucher.number } [${ (voucher.lang || 'ru').toUpperCase() }]` }
      width="x-large"
      scrollBehavior="outside"
    >
      <Select
        options={ [{ value: 'ru', label: 'RU' }, { value: 'en', label: 'EN' }] }
        maxMenuHeight={ 200 }
        placeholder="Язык"
        onChange={
          (item: { value: string } | null) => setVoucher({ ...voucher, lang: item ? item.value : 'ru' })
        }
        value={ { label: voucher.lang ? voucher.lang.toUpperCase() : 'RU', value: voucher.lang || 'ru' } }
        defaultValue={ { label: 'RU', value: 'ru' } }
      />
      <Label label={ 'Заказчик' }/>
      <UserSelect placeholder={ 'Выберите заказчика' }
                  onSelect={ user => setVoucher({ ...voucher, user }) }
                  filter={ user => voucher.users.includes(user.id) }
                  selected={ voucher.user }
      />

      <Label label={ 'Адрес web сайта' }/>
      <Textfield
        label={ 'Адрес web сайта' }
        placeholder='Введите адрес сайта'
        value={ voucher.url || '' }
        onChange={ (event) => setVoucher({ ...voucher, url: event.target.value }) }
      />
      <Label label={ 'Компания' }/>
      <Select
        options={
          Object.keys(companies).map(key => ({
            value: key,
            label: companies[key].name,
          }))
        }
        maxMenuHeight={ 200 }
        placeholder="Выберите компанию"
        onChange={
          (item: { value: string } | null) => {
            setVoucher({
              ...voucher,
              company: item ? item.value : voucher.company,
              url: item ? companies[item.value].url : voucher.url,
            })
          }
        }
        value={ voucher.company ? { label: companies[voucher.company].name, value: voucher.company } : null }
        defaultValue={ {
          label: companies.RimTransfer,
          value: 'RimTransfer',
        } }
      />
      <Label label={ 'Трансферы' }/>
      <div style={ { display: 'grid', gridGap: '20px', gridTemplateColumns: '1fr' } }>
        {
          voucher.transfers.map((transfer, index) => {
            return <div style={ {
              borderRadius: ' 3px',
              color: ' #42526E',
              padding: ' 16px 32px',
              boxShadow: '0 0 1px rgba(9,30,66,0.31)',
              display: 'grid',
              gridGap: '10px',
              gridTemplateColumns: 'auto 1fr',
              alignItems: 'center',
            } } key={ transfer.id }>
              <span>Дата трансфера:</span>
              <Textfield
                placeholder='Дата подачи авто'
                isCompact={ true }
                defaultValue={ transfer.delivery }
                onChange={ (event) => {
                  const transfersCopy = [...voucher.transfers]
                  transfersCopy[index].delivery = event.target.value
                  setVoucher({ ...voucher, transfers: transfersCopy })
                } }
              />

              <span>Тип трансфера:</span>
              <Textfield
                placeholder='Введите тип трансфера'
                isCompact={ true }
                defaultValue={ transfer.transferType }
                onChange={ (event) => {
                  const transfersCopy = [...voucher.transfers]
                  transfersCopy[index].transferType = event.target.value
                  setVoucher({ ...voucher, transfers: transfersCopy })
                } }
              />

              <span>Рейс:</span>
              <Textfield
                placeholder='Введите номер рейса'
                isCompact={ true }
                defaultValue={ transfer.flight }
                onChange={ (event) => {
                  const transfersCopy = [...voucher.transfers]
                  transfersCopy[index].flight = event.target.value
                  setVoucher({ ...voucher, transfers: transfersCopy })
                } }
              />

              <span>Время рейса:</span>
              <Textfield
                placeholder='Введите время рейса'
                isCompact={ true }
                defaultValue={ transfer.flightTime }
                onChange={ (event) => {
                  const transfersCopy = [...voucher.transfers]
                  transfersCopy[index].flightTime = event.target.value
                  setVoucher({ ...voucher, transfers: transfersCopy })
                } }
              />

              <span>Маршрут трансфера:</span>
              <Textfield
                placeholder='Откуда ⇒ Куда'
                isCompact={ true }
                defaultValue={ transfer.route }
                onChange={ (event) => {
                  const transfersCopy = [...voucher.transfers]
                  transfersCopy[index].route = event.target.value
                  setVoucher({ ...voucher, transfers: transfersCopy })
                } }
              />

              <span>Пассажиры:</span>
              <Textfield
                placeholder='Количество пассажиров'
                isCompact={ true }
                defaultValue={ transfer.passengers }
                onChange={ (event) => {
                  const transfersCopy = [...voucher.transfers]
                  transfersCopy[index].passengers = event.target.value
                  setVoucher({ ...voucher, transfers: transfersCopy })
                } }
              />

              <span>Багаж:</span>
              <Textfield
                placeholder='Количество багажа'
                isCompact={ true }
                defaultValue={ transfer.luggage }
                onChange={ (event) => {
                  const transfersCopy = [...voucher.transfers]
                  transfersCopy[index].luggage = event.target.value
                  setVoucher({ ...voucher, transfers: transfersCopy })
                } }
              />


              <span>Автомобиль:</span>
              <Textfield
                placeholder='Введите марку автомобиля'
                isCompact={ true }
                defaultValue={ transfer.car }
                onChange={ (event) => {
                  const transfersCopy = [...voucher.transfers]
                  transfersCopy[index].car = event.target.value
                  setVoucher({ ...voucher, transfers: transfersCopy })
                } }
              />


              <span>Цена Трансфера:</span>
              <Textfield
                placeholder='Введите цену'
                isCompact={ true }
                defaultValue={ transfer.paymentPrice }
                onChange={ (event) => {
                  const transfersCopy = [...voucher.transfers]
                  transfersCopy[index].paymentPrice = event.target.value
                  setVoucher({ ...voucher, transfers: transfersCopy })
                } }
              />


              <span>Способы оплаты:</span>
              <Textfield
                placeholder='Введите способ оплаты'
                isCompact={ true }
                defaultValue={ transfer.paymentType }
                onChange={ (event) => {
                  const transfersCopy = [...voucher.transfers]
                  transfersCopy[index].paymentType = event.target.value
                  setVoucher({ ...voucher, transfers: transfersCopy })
                } }
              />


              <span>Водитель встречает с табличкой:</span>
              <Textfield
                placeholder='Введите текст таблички'
                isCompact={ true }
                defaultValue={ transfer.sign }
                onChange={ (event) => {
                  const transfersCopy = [...voucher.transfers]
                  transfersCopy[index].sign = event.target.value
                  setVoucher({ ...voucher, transfers: transfersCopy })
                } }
              />

              <span>Дополнительно:</span>
              <TextArea
                minimumRows={ 2 }
                value={ voucher.transfers[index].description }
                onChange={ (event) => {
                  const transfersCopy = [...voucher.transfers]
                  transfersCopy[index].description = event.target.value
                  setVoucher({ ...voucher, transfers: transfersCopy })
                } }
              />
            </div>
          })
        }
      </div>
      <Label label={ 'Экскурсии' }/>
      <DynamicTable
        head={ {
          cells: [
            {
              content: (
                <Checkbox
                  onChange={ () => !selected.length
                    ? setSelected(props.excursions)
                    : setSelected([])
                  }
                  label={ null }
                  isIndeterminate={ selected.length !== props.excursions.length }
                  isChecked={ selected.length }
                />
              ),
              width: '20px',
              shouldTruncate: false,
              isSortable: false,
            },
            {
              key: 'type',
              content: 'Тип',
              shouldTruncate: true,
              isSortable: true,
              width: '120px',
            },
            {
              key: 'title',
              content: 'Наименование',
              shouldTruncate: false,
              isSortable: true,
            },
          ],
        } }
        rows={ props.excursions.map(item => ({
          cells: [
            {
              content: (
                <Checkbox
                  label={ null }
                  isChecked={ !!selected.find(select => select.id === item.id) }
                  onChange={ () => setSelected(selected.find(select => select.id === item.id)
                    ? selected.filter(select => select.id !== item.id)
                    : [...selected, item]) }
                />
              ),
            },
            {
              key: item.type,
              content: <Lozenge appearance={ 'new' }>{ excursionTypes[item.type] }</Lozenge>,
            },
            {
              key: [item.title, item.description].join('').replace(/ /g, ''),
              content: `${ item.title }${ item.description ? ' - ' + item.description : '' }`,
            },
          ],
          key: item.id,
        })) }
        emptyView={ <h2>Список экскурсий пуст</h2> }
        defaultPage={ 1 }
        loadingSpinnerSize="large"
        isFixedSize={ true }
      />
      <Label label={ 'Дополнительная информация' }/>
      <div style={ { marginBottom: '20px', minHeight: '180px' } }>
        <TextArea
          value={ description }
          onChange={ (e) => {
            setDescription(e.target.value)
          } }
          minimumRows={ 10 }
          maxHeight={ '180px' }
        />
      </div>
    </Modal>
  )
}
