import * as React from 'react';
import PageHeader from '@atlaskit/page-header';
import FieldText from '@atlaskit/field-text';
import TextArea from '@atlaskit/textarea';
import { Label } from '@atlaskit/field-base';
import Button from '@atlaskit/button';
import axios from 'axios';

export default class Settings extends React.Component<{}, {
  VoucherNumber: number;
  AuthorizationNumber: number;
  ProformNumber: number;
  VoucherUrl_RU: string;
  VoucherUrl_EN: string;
  VoucherDescription_RU: string;
  VoucherDescription_EN: string;
  isLoading: boolean;
  isSaving: boolean;
}> {
  state = {
    VoucherNumber: 0,
    AuthorizationNumber: 0,
    ProformNumber: 0,
    VoucherUrl_RU: '',
    VoucherUrl_EN: '',
    VoucherDescription_RU: '',
    VoucherDescription_EN: '',
    isLoading: true,
    isSaving: false
  };

  componentDidMount (): void {
    axios.post(`${location.origin}/settings`)
      .then(response => {
        this.setState({ ...response.data, isLoading: false });
      });
  }

  onSave () {
    this.setState({ isSaving: true });
    axios.put(`${location.origin}/settings`, {
        VoucherDescription_RU: this.state.VoucherDescription_RU,
        VoucherDescription_EN: this.state.VoucherDescription_EN,
        VoucherNumber: this.state.VoucherNumber,
        VoucherUrl_RU: this.state.VoucherUrl_RU,
        VoucherUrl_EN: this.state.VoucherUrl_EN,
        AuthorizationNumber: this.state.AuthorizationNumber,
        ProformNumber: this.state.ProformNumber,
      })
      .then(response => this.setState({ ...response.data, isSaving: false }));
  }

  render () {
    return ( <>
      <PageHeader>Настройки</PageHeader>
      <div>
        <FieldText
          label={'Количество записей в таблице'}
          shouldFitContainer={true}
          type={'number'}
          value={localStorage.getItem('rowsPerPage') || '10'}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => localStorage.setItem('rowsPerPage', parseInt(event.target.value, 10) ? event.target.value : '10')}
        />
      </div>
      {!this.state.isLoading && <>
        <h3>Ваучер</h3>
        <FieldText
          label={'Номер последнего ваучера'}
          type={'number'}
          shouldFitContainer={true}
          value={(this.state.VoucherNumber || 0).toString(10) || ''}
          onChange={(event: any) => this.setState({ VoucherNumber: parseInt(event.target.value, 10) })}
        />
        <FieldText
          label={'URL ваучера по умолчанию [RU]'}
          shouldFitContainer={true}
          value={this.state.VoucherUrl_RU || ''}
          onChange={(event: any) => this.setState({ VoucherUrl_RU: event.target.value })}
        />
        <FieldText
          label={'URL ваучера по умолчанию [EN]'}
          shouldFitContainer={true}
          value={this.state.VoucherUrl_EN || ''}
          onChange={(event: any) => this.setState({ VoucherUrl_EN: event.target.value })}
        />
        <Label label={'Описание ваучера по умолчанию [RU]'}/>
        <TextArea
          value={this.state.VoucherDescription_RU || ''}
          onChange={event => this.setState({ VoucherDescription_RU: event.target.value })}
          minimumRows={10}
          // maxHeight={'180px'}
        />
        <Label label={'Описание ваучера по умолчанию [EN]'}/>
        <TextArea
          value={this.state.VoucherDescription_EN || ''}
          onChange={event => this.setState({ VoucherDescription_EN: event.target.value })}
          minimumRows={10}
          // maxHeight={'180px'}
        />
        <h3>Лист Авторизации</h3>
        <FieldText
          label={'Номер последнего листа авторизации'}
          type={'number'}
          shouldFitContainer={true}
          value={(this.state.AuthorizationNumber || 0).toString(10) || ''}
          onChange={(event: any) => this.setState({ AuthorizationNumber: parseInt(event.target.value, 10) })}
        />
        <h3>Проформа</h3>
        <FieldText
          label={'Номер последней проформы'}
          type={'number'}
          shouldFitContainer={true}
          value={(this.state.ProformNumber || 0).toString(10) || ''}
          onChange={(event: any) => this.setState({ ProformNumber: parseInt(event.target.value, 10) })}
        />
        <br/>
        <Button appearance="primary" isLoading={this.state.isSaving} onClick={() => this.onSave()}>Сохранить</Button>
      </>}
    </> );
  }
}
