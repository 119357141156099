import * as React from 'react';
import { Default } from './Default';
import { clearField, IState } from '../shared';
import { excursionsCompose, excursionTypes, IExcursion, IExcursionComponentProps } from '../shared/excursions';
import ExcursionsTable from '../components/ExcursionsTable';
import ExcursionModal from '../components/ExcursionModal';

const clearEdit: IExcursion = {
  id: '',
  title: '',
  description: null,
  type: 'EXCURSION',
};

class Excursions extends React.Component<IExcursionComponentProps, IState<IExcursion>> {
  state: IState<IExcursion> = { filters: new Map() };

  render () {
    const excursions: IExcursion[] = Array.from(this.state.filters.values()).reduce(
      (prev: IExcursion[], item) => prev.filter(item), this.props.data.excursions || []
    );

    return (
      <Default
        title={'Экскурсии'}
        addable={true}
        clearEdit={clearEdit}
        defaultSortKey={'type'}
        items={excursions}
        table={props => (
          <ExcursionsTable
            items={excursions}
            isLoading={this.props.data.loading}
            {...props}
          />
        )}
        modal={props => (
          <ExcursionModal heading={`${props.isAddModalOpen ? 'Добавить' : 'Редактировать'} экскурсию`} {...props} />
        )}
        onSave={(item: IExcursion, isAddModalOpen: boolean) =>
          isAddModalOpen
            ? this.props.create({ variables: { data: clearField(item) } })
            : this.props.update({ variables: { where: { id: item.id }, data: clearField(item) } })
        }
        onDelete={(items: IExcursion[]) =>
          this.props.deleteMany({ variables: { where: { id_in: items.map(item => item.id as string) } } })
        }
        onSearch={(text: string) =>
          this.setState({
            filters: this.state.filters.set('search', item =>
              [
                item.title,
                item.description,
                excursionTypes[item.type]
              ]
                .join('')
                .toUpperCase()
                .replace(/ /g, '')
                .includes(text.toUpperCase())
            )
          })
        }
      />
    );
  }
}

export default excursionsCompose(Excursions);
