import * as React from 'react';
import Modal from '@atlaskit/modal-dialog';

export default class DeleteModal extends React.Component<{ count: number, onDelete (): void, onCancel (): void }, {}> {
  render () {
    return (
      <Modal
        actions={[
          { text: 'Удалить', onClick: () => this.props.onDelete() },
          { text: 'Закрыть', onClick: () => this.props.onCancel(), autoFocus: true }
        ]}
        autoFocus={false}
        appearance={'danger'}
        width="small"
        onClose={() => this.props.onCancel()}
        heading={`Подтвердите удаление (${this.props.count})`}
      />
    );
  }
}
