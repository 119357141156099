import gql from 'graphql-tag';
import { USER_FRAGMENT } from './users';

export const ORDER_FRAGMENT = gql`
    fragment OrderFragment on Order {
        id
        timestamp
        delivery
        status

        description

        departure
        intermediate
        destination
        
        passengers
        luggage
        flight
        carClass
        transferType
        flightTime
        sign
        clientNotification

        voucher
        referer {
            id
            ...UserFragment
        }
        provider {
            id
            ...UserFragment
        }
        client {
            id
            ...UserFragment
        }
        driver {
            id
            ...UserFragment
        }
        car {
            id
            model
            number
        }
        payments {
            id
            timestamp
            price
            paid
            type
            payer {
                id
                ...UserFragment
            }
            payee {
                id
                ...UserFragment
            }
        }
        tid
        cid
        seo
    }
    ${USER_FRAGMENT}
`;
