import * as React from 'react'
import { clearTime, defaultState, IDefaultState, localePrice, utcDate } from '../shared'
import Button, { ButtonGroup } from '@atlaskit/button'
import PageHeader from '@atlaskit/page-header'
import TextField from '@atlaskit/textfield'
import DropList from '@atlaskit/droplist'
import Item from '@atlaskit/item'
import { ModalTransition } from '@atlaskit/modal-dialog'
import { DatePicker } from '@atlaskit/datetime-picker'
import Select from '@atlaskit/select'
import Lozenge from '@atlaskit/lozenge'
import * as startOfDay from 'date-fns/start_of_day'
import * as endOfDay from 'date-fns/end_of_day'
import MoreIcon from '@atlaskit/icon/glyph/more'
import axios from 'axios'
import * as moment from 'moment'
import { UserSelect } from '../components/UserSelect'
import { IUser, User } from '../shared/users'
import { CarSelect } from '../components/CarSelect'
import { ICar } from '../shared/cars'
import { IVoucher } from '../shared/voucher'
import { IExcursion } from '../shared/excursions'
import { CreateVoucherModal } from '../components/CreateVoucherModal'
import DeleteModal from '../components/DeleteModal'
import ClientModal from '../components/ClientModal'
import PaymentModal from '../components/PaymentModal'
import { IPayment, paymentType } from '../shared/payments'
import { SendDriverModal } from '../components/SendDriverModal'
import { SendClientModal } from '../components/SendClientModal'
import { CreateAuthorizationModal } from '../components/CreateAuthorizationModal'
import { CreateProformModal } from '../components/CreateProformModal'
import OrdersTable from '../components/OrdersTable'
import { OrderModal } from '../components/OrderModal'
import { IProform } from '../shared/proform'
import { IAuthorization } from '../shared/authorization'
import { IOrder, IOrderProps, ordersCompose, orderStatus, orderStatusAppearance, transferType } from '../shared/orders'

const clearEdit: IOrder = {
  id: '',
  timestamp: clearTime,
  delivery: clearTime,
  status: 'REQUEST',
  description: '',
  departure: null,
  intermediate: null,
  destination: null,
  passengers: null,
  luggage: null,
  flight: null,
  flightTime: null,
  carClass: null,
  transferType: '',
  sign: null,
  voucher: null,
  referer: null,
  provider: null,
  driver: null,
  car: null,
  client: null,
  payments: null,
  clientNotification: false,
  income: {
    INVOICE: 0,
    CASH: 0,
    CARD: 0,
  },
  tid: null,
  cid: null,
  seo: null,
}
const clearPaymentEdit: IPayment = {
  id: '',
  timestamp: clearTime,
  price: 0.0,
  paid: false,
  payer: null,
  payee: null,
  order: clearEdit,
  type: 'CASH',
}
const updatePayments = (previous: Pick<IOrder, 'payments'> | null, current: Pick<IOrder, 'payments'>) => {
  const currentPayments = current.payments || []
  const previousPayments = (previous && previous.payments) || []

  const formatted = currentPayments.reduce(
    (p: { create: any[]; update: any[]; delete: any[] }, payment) => {
      const previousPayment = previousPayments.find(item => item.id === payment.id)

      const formattedPayment = {
        timestamp: payment.timestamp,
        price: payment.price,
        paid: payment.paid,
        type: payment.type,
        payer: payment.payer
          ? { connect: { id: payment.payer.id } }
          : previousPayment && previousPayment.payer
            ? { disconnect: true }
            : null,
        payee: payment.payee
          ? { connect: { id: payment.payee.id } }
          : previousPayment && previousPayment.payee
            ? { disconnect: true }
            : null,
      }

      if (payment.id) {
        p.update.push({ where: { id: payment.id }, data: formattedPayment })
      } else {
        p.create.push(formattedPayment)
      }
      return { ...p }
    },
    { create: [], update: [], delete: [] },
  )
  previousPayments.forEach(prev => {
    if (!currentPayments.find(cur => cur.id === prev.id)) {
      formatted.delete.push({ id: prev.id })
    }
  })
  return formatted
}

interface OrderFilter {
  id?: string,
  delivery_gte?: Date,
  delivery_lte?: Date,
  referer?: { id: string },
  provider?: { id: string },
  driver?: { id: string },
  car?: { id: string },
  status?: any,
}

interface IState extends IDefaultState<IOrder> {
  lang: 'ru' | 'en';
  isVoucherModalOpen: boolean;

  isCreateClientModal: boolean;
  isCreateRefererModal: boolean;
  isCreateProviderModal: boolean;

  isCreateProformModal: boolean;
  isCreateAuthorizationModal: boolean;

  isCreatePaymentModal: boolean;
  isEditPaymentModal: boolean;
  isSendDriverModal: boolean;
  isSendClientModal: boolean;
  editPayment: IPayment | null;
  editPaymentIndex: number;
  search: (value: IOrder, index: number) => boolean
  where: OrderFilter,
  referer?: IUser
  provider?: IUser
  driver?: IUser
}

class Orders extends React.Component<IOrderProps, IState> {
  state: IState = {
    ...defaultState,
    lang: 'ru',
    edit: clearEdit,
    defaultSortKey: 'delivery',
    defaultSortOrder: 'ASC',
    isVoucherModalOpen: false,
    isCreateClientModal: false,
    isCreateRefererModal: false,
    isCreateProviderModal: false,
    isCreatePaymentModal: false,
    isEditPaymentModal: false,
    isSendDriverModal: false,
    isSendClientModal: false,
    isCreateProformModal: false,
    isCreateAuthorizationModal: false,
    editPayment: clearPaymentEdit,
    editPaymentIndex: 0,
    search: () => true,
    where: this.props.data.variables.where,
  }

  async clientFormat(client: IUser | null) {
    if (!client) {
      return null
    }
    const { name, phone, email, description, types, roles } = client

    if (client.id === 'new') {
      return this.props
        .createUser({
          variables: {
            data: {
              name,
              phone,
              email,
              description,
              types: { set: types },
              roles: { set: roles },
            },
          },
        })
        .then(result => ({ connect: { id: result.data.createUser.id } }))
        .catch(() => null)
    } else {
      return { connect: { id: client.id } }
    }
  }

  async addSave(item: IOrder) {
    return this.props.create({
        variables: {
          data: {
            timestamp: moment().toISOString(),
            delivery: item.delivery,
            status: item.status,
            voucher: item.voucher,
            description: item.description,
            departure: item.departure,
            destination: item.destination,
            intermediate: item.intermediate,
            passengers: item.passengers,
            luggage: item.luggage,
            flight: item.flight,
            flightTime: item.flightTime,
            carClass: item.carClass,
            transferType: item.transferType,
            sign: item.sign,
            referer: item.referer ? { connect: { id: item.referer.id } } : null,
            provider: item.provider ? { connect: { id: item.provider.id } } : null,
            driver: item.driver ? { connect: { id: item.driver.id } } : null,
            car: item.car ? { connect: { id: item.car.id } } : null,
            client: await this.clientFormat(item.client),
            clientNotification: item.clientNotification,
            payments: item.payments
              ? {
                create: item.payments.map(payment => ({
                  timestamp: payment.timestamp,
                  price: payment.price,
                  paid: payment.paid,
                  type: payment.type,
                  payer: payment.payer ? { connect: { id: payment.payer.id } } : null,
                  payee: payment.payee ? { connect: { id: payment.payee.id } } : null,
                })),
              }
              : null,
          },
        },
      })
      .then(() => this.refetchOrders(this.state.where))
  }

  async editSave(item: IOrder, previous: IOrder | null) {
    if (
      item.tid &&
      item.cid &&
      previous &&
      item.status !== previous.status &&
      (item.status === 'CONFIRMED' || item.status === 'ASSIGNED')
    ) {
      await axios.post(
        `${location.origin}/ga/send`,
        {
          v: '1',
          tid: item.tid,
          cid: item.cid,
          t: 'event',
          ec: 'order',
          ea: 'status',
          el: item.status.toLowerCase(),
          ds: 'crm',
          dr: item.seo && item.seo['utm_referer'],
          cn: item.seo && item.seo['utm_campaign'],
          cs: item.seo && item.seo['utm_source'],
          cm: item.seo && item.seo['utm_medium'],
          ck: item.seo && item.seo['utm_term'],
          cc: item.seo && item.seo['utm_content'],
          z: Date.now()
        })
        .then(({status}) => console.log(`GA => order.status.${item.status.toLowerCase()}`, item.tid, item.cid))
        .catch(console.error)
    }

    return this.props.update({
        variables: {
          where: { id: item.id },
          data: {
            timestamp: item.timestamp,
            delivery: item.delivery,
            status: item.status,
            voucher: item.voucher,
            description: item.description,
            departure: item.departure,
            destination: item.destination,
            intermediate: item.intermediate,
            passengers: item.passengers,
            luggage: item.luggage,
            flight: item.flight,
            flightTime: item.flightTime,
            carClass: item.carClass,
            transferType: item.transferType,
            sign: item.sign,
            clientNotification: item.clientNotification,
            referer: item.referer
              ? { connect: { id: item.referer.id } }
              : previous && previous.referer
                ? { disconnect: true }
                : null,
            provider: item.provider
              ? { connect: { id: item.provider.id } }
              : previous && previous.provider
                ? { disconnect: true }
                : null,
            driver: item.driver
              ? { connect: { id: item.driver.id } }
              : previous && previous.driver
                ? { disconnect: true }
                : null,
            car: item.car ? { connect: { id: item.car.id } } : previous && previous.car ? { disconnect: true } : null,
            client: item.client
              ? { connect: { id: item.client.id } }
              : previous && previous.client
                ? { disconnect: true }
                : null,
            payments: item.payments ? updatePayments(previous, item) : null,
          },
        },
      })
      .then(() => this.refetchOrders(this.state.where))
  }

  addVoucher(voucher: IVoucher) {
    return axios.put(`${ location.origin }/settings`, { VoucherNumber: voucher.number }).then(() =>
      this.props.createVoucher({
        variables: {
          data: {
            number: voucher.number,
            transfers: voucher.transfers,
            user: voucher.user ? { connect: { id: voucher.user.id } } : null,
            driver: voucher.driver ? { connect: { id: voucher.driver.id } } : null,
            url: voucher.url,
            lang: voucher.lang,
            company: voucher.company,
            excursions: { connect: voucher.excursions.map(({ id }) => ({ id })) },
            description: { set: voucher.description },
            users: { set: voucher.users },
            drivers: { set: voucher.drivers },
          },
        },
      }),
    )
  }

  updateVoucher(voucher: IVoucher) {
    return this.props.updateVoucher({
      variables: {
        data: {
          number: voucher.number,
          user: voucher.user ? { connect: { id: voucher.user.id } } : { disconnect: true },
          driver: voucher.driver ? { connect: { id: voucher.driver.id } } : { disconnect: true },
          url: voucher.url,
          company: voucher.company,
          lang: voucher.lang,
          excursions: {
            connect: voucher.excursions.map(({ id }) => ({ id })),
            disconnect: (this.props.loadExcursions.excursions || [])
              .filter(item1 => !voucher.excursions.find(item2 => item1.id === item2.id))
              .map(({ id }) => ({ id })),
          },
          description: { set: voucher.description },
          transfers: voucher.transfers,
          users: { set: voucher.users },
          drivers: { set: voucher.drivers },
        },
        where: { id: voucher.id },
      },
    })
  }

  createAuthorization(authorization: IAuthorization) {
    return axios.put(`${ location.origin }/settings`, { AuthorizationNumber: authorization.number }).then(() =>
      this.props.createAuthorization({
        variables: {
          data: {
            number: authorization.number,
            description: { set: authorization.description },
            amount: authorization.amount,
            date: authorization.date,
            user: authorization.user ? { connect: { id: authorization.user.id } } : null,
            users: { set: authorization.users },
          },
        },
      }),
    )
  }

  updateAuthorization(authorization: IAuthorization) {
    return this.props.updateAuthorization({
      variables: {
        where: {
          id: authorization.id,
        },
        data: {
          number: authorization.number,
          description: { set: authorization.description },
          amount: authorization.amount,
          date: authorization.date,
          user: authorization.user
            ? { connect: { id: authorization.user.id } }
            : { disconnect: true },
          users: { set: authorization.users },
        },
      },
    })
  }

  createProform(proform: IProform) {
    return axios.put(`${ location.origin }/settings`, { ProformNumber: proform.number }).then(() =>
      this.props.createProform({
        variables: {
          data: {
            number: proform.number,
            transfers: proform.transfers,
            company: proform.company,
            address: proform.address,
            country: proform.country,
            lang: proform.lang,
            telephone: proform.telephone,
            user: proform.user ? { connect: { id: proform.user.id } } : null,
            users: { set: proform.users },
          },
        },
      }),
    )
  }

  updateProform(proform: IProform) {
    return this.props.updateProform({
      variables: {
        where: {
          id: proform.id,
        },
        data: {
          number: proform.number,
          transfers: proform.transfers,
          company: proform.company,
          address: proform.address,
          country: proform.country,
          lang: proform.lang,
          telephone: proform.telephone,
          user: proform.user
            ? { connect: { id: proform.user.id } }
            : { disconnect: true },
          users: { set: proform.users },
        },
      },
    })
  }

  closeModals = () =>
    this.setState({
      isAddModalOpen: false,
      isVoucherModalOpen: false,
      isCreateAuthorizationModal: false,
      isCreateProformModal: false,
      isEditModalOpen: false,
      isSendDriverModal: false,
      isDeleteModalOpen: false,
    })

  refetchOrders = (where: OrderFilter) => {
    return this.props.data.refetch({ where })
  }

  render() {
    const orders: IOrder[] = (this.props.data.orders || [])
      .map(order => {
        order.income = (order.payments || []).reduce(
          (prev, payment) => {
            if (payment.payee && payment.payee.types.includes('COMPANY')) {
              prev.CASH += payment.type === 'CASH' ? payment.price : 0
              prev.CARD += payment.type === 'CARD' ? payment.price : 0
              prev.INVOICE += payment.type === 'INVOICE' ? payment.price : 0
            }
            if (payment.payer && payment.payer.types.includes('COMPANY')) {
              prev.CASH -= payment.type === 'CASH' ? payment.price : 0
              prev.CARD -= payment.type === 'CARD' ? payment.price : 0
              prev.INVOICE -= payment.type === 'INVOICE' ? payment.price : 0
            }
            return prev
          },
          { CASH: 0, INVOICE: 0, CARD: 0 },
        )
        return order
      })
      .filter(this.state.search)

    const excursions: IExcursion[] = this.props.loadExcursions.excursions || []

    return (
      <>
        <PageHeader
          actions={
            <ButtonGroup>
              <TextField
                isCompact={ true }
                width="medium"
                placeholder="Поиск"
                onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
                  event.persist()
                  this.setState({
                    search: (item: IOrder) => [
                      utcDate(item.delivery).format('DD.MM.YYYY HH:mm'),
                      orderStatus[item.status],

                      item.voucher ? `№${ item.voucher }/${ moment(Date.now(), 'YY') }` : '',
                      item.referer ? item.referer.name : '',
                      item.provider ? item.provider.name : '',

                      item.driver ? item.driver.name : '',
                      item.car ? `${ item.car.model || '' } ${ item.car.number || '' }` : '',
                      item.transferType,

                      item.client ? item.client.name : '',

                      item.description,

                      item.departure,
                      item.destination,
                      item.passengers,
                      item.luggage,
                      item.flight,
                      item.flightTime,
                      item.carClass,
                      item.sign,
                      item.income ? item.income.CASH : 0,
                      item.income ? item.income.CARD : 0,
                      item.income ? item.income.INVOICE : 0,
                      '⇒',
                      'чел',
                    ]
                      .join('')
                      .toUpperCase()
                      .replace(/ /g, '')
                      .includes(event.target.value.replace(/ /g, '').toUpperCase()),
                  })
                } }
              />
              <Button
                appearance="primary"
                onClick={ () => this.setState({ isAddModalOpen: true, edit: { ...clearEdit } }) }
              >
                Добавить
              </Button>
              <DropList
                appearance="default"
                position="bottom right"
                onOpenChange={ () => this.setState({ isActionOpen: false }) }
                isOpen={ this.state.isActionOpen }
                onClick={ () => this.setState({ isActionOpen: true }) }
                trigger={ <Button iconBefore={ <MoreIcon label="Еще"/> }/> }
              >
                <Item
                  isDisabled={ !this.state.selected.length }
                  onClick={ () => {
                    this.setState({ isVoucherModalOpen: true, lang: 'ru' })
                  } }
                >
                  { 'Создать ваучер [RU]' + (this.state.selected.length > 1 ? ` (${ this.state.selected.length })` : '') }
                </Item>
                <Item
                  isDisabled={ !this.state.selected.length }
                  onClick={ () => {
                    this.setState({ isVoucherModalOpen: true, lang: 'en' })
                  } }
                >
                  { 'Создать ваучер [EN]' + (this.state.selected.length > 1 ? ` (${ this.state.selected.length })` : '') }
                </Item>
                <Item
                  isDisabled={ !this.state.selected.length }
                  onClick={ () => {
                    this.setState({ isCreateProformModal: true, lang: 'ru' })
                  } }
                >
                  { 'Создать проформу [RU]' + (this.state.selected.length > 1 ? ` (${ this.state.selected.length })` : '') }
                </Item>
                <Item
                  isDisabled={ !this.state.selected.length }
                  onClick={ () => {
                    this.setState({ isCreateProformModal: true, lang: 'en' })
                  } }
                >
                  { 'Создать проформу [EN]' + (this.state.selected.length > 1 ? ` (${ this.state.selected.length })` : '') }
                </Item>
                <Item
                  isDisabled={ !this.state.selected.length }
                  onClick={ () => {
                    this.setState({ isCreateAuthorizationModal: true })
                  } }
                >
                  { 'Создать авторизацию' + (this.state.selected.length > 1 ? ` (${ this.state.selected.length })` : '') }
                </Item>
                <Item
                  isDisabled={ !this.state.selected.length }
                  onClick={ () => {
                    this.setState({ isDeleteModalOpen: true })
                  } }
                >
                  { 'Удалить' + (this.state.selected.length > 1 ? ` (${ this.state.selected.length })` : '') }
                </Item>
              </DropList>
            </ButtonGroup>
          }
          bottomBar={
            <div style={ { display: 'flex' } }>
              <div
                style={ {
                  display: 'grid',
                  gridAutoColumns: 'minmax(150px, 200px)',
                  gridAutoFlow: 'column',
                  gridGap: '10px',
                } }
              >
                <DatePicker
                  spacing="compact"
                  placeholder="Дата подачи от"
                  dateFormat={ 'DD.MM.YY' }
                  defaultValue={ this.state.where.delivery_gte }
                  onChange={ date => {
                    const where = {
                      ...this.state.where,
                      delivery_gte: date ? startOfDay(date) : startOfDay(0),
                    }
                    this.refetchOrders(where).then(() => this.setState({ where }))
                  } }
                />
                <DatePicker
                  spacing="compact"
                  placeholder="Дата подачи до"
                  dateFormat={ 'DD.MM.YY' }
                  defaultValue={ this.state.where.delivery_lte }
                  onChange={ date => {
                    const where = {
                      ...this.state.where,
                      delivery_lte: date ? endOfDay(date) : endOfDay(new Date(2050, 11, 31)),
                    }
                    this.refetchOrders(where).then(() => this.setState({ where }))
                  } }
                />
                <UserSelect
                  placeholder="Источник"
                  selected={ this.state.referer }
                  filter={ item => User.hasType(item, 'PARTNER') && User.hasAnyRole(item, ['REFERER', 'PROVIDER']) }
                  spacing="compact"
                  onSelect={ user => {
                    const where = {
                      ...this.state.where,
                      referer: user ? {
                        id: user.id,
                      } : undefined,
                    }
                    this.refetchOrders(where)
                      .then(() => this.setState({
                        where: where,
                        referer: user || undefined,
                      }))
                  } }
                />
                <UserSelect
                  placeholder="Исполнитель"
                  selected={ this.state.provider }
                  filter={ item => User.hasType(item, 'PARTNER') && User.hasAnyRole(item, ['REFERER', 'PROVIDER']) }
                  spacing="compact"
                  onSelect={ user => {
                    const where = {
                      ...this.state.where,
                      provider: user ? {
                        id: user.id,
                      } : undefined,
                    }

                    this.refetchOrders(where)
                      .then(() => this.setState({
                        where: where,
                        provider: user || undefined,
                      }))
                  } }
                />
                <UserSelect
                  placeholder="Водитель"
                  selected={ this.state.driver }
                  filter={ item => User.hasType(item, 'EMPLOYEE') && User.hasRole(item, 'DRIVER') }
                  spacing="compact"
                  onSelect={ user => {
                    const where = {
                      ...this.state.where,
                      driver: user ? {
                        id: user.id,
                      } : undefined,
                    }
                    this.refetchOrders(where)
                      .then(() => this.setState({
                        where: where,
                        driver: user || undefined,
                      }))
                  } }
                />
                <CarSelect
                  spacing="compact"
                  placeholder="Автомобиль"
                  onSelect={ (selected: ICar | null) => {
                    const where = {
                      ...this.state.where,
                      car: selected ? {
                        id: selected.id,
                      } : undefined,
                    }

                    this.refetchOrders(where)
                      .then(() => this.setState({ where }))
                  } }
                />
                <Select
                  options={ Object.keys(orderStatus).map(key => ({
                    value: key,
                    label: <Lozenge appearance={ orderStatusAppearance[key] }>{ orderStatus[key] }</Lozenge>,
                  })) }
                  placeholder="Статус"
                  spacing="compact"
                  onChange={ selected => {
                    const where = {
                      ...this.state.where,
                      status: selected ? selected.value : undefined,
                    }
                    this.refetchOrders(where)
                      .then(() => this.setState({ where }))
                  } }
                  isClearable={ true }
                />
              </div>
            </div>
          }
        >
          Заказы
        </PageHeader>
        <OrdersTable
          items={ orders }
          isLoading={ this.props.data.loading }
          { ...{
            isSelectMode: this.state.isSelectMode,
            selected: this.state.selected.filter(select => orders.find(item => item.id === select.id)),
            defaultPage: this.state.defaultPage,
            defaultSortKey: this.state.defaultSortKey,
            defaultSortOrder: this.state.defaultSortOrder,
            page: this.state.defaultPage || 1,
            sortKey: this.state.defaultSortKey,
            sortOrder: this.state.defaultSortOrder,
            rowsPerPage: localStorage.getItem('rowsPerPage') || 10,
            onEdit: (item: IOrder) => this.setState({ edit: item, isEditModalOpen: true }),
            onSelect: (item: IOrder) =>
              this.setState(prev => ({
                selected: prev.selected.find(select => select.id === item.id)
                  ? prev.selected.filter(select => select.id !== item.id)
                  : [...prev.selected, item],
              })),
            onUndo: (item: IOrder) =>
              this.setState({
                edit: {
                  ...clearEdit,
                  departure: item.destination,
                  destination: item.departure,
                  flight: item.flight,
                  referer: item.referer,
                  client: item.client,
                  passengers: item.passengers,
                  luggage: item.luggage,
                  carClass: item.carClass,
                  description: item.description,
                  sign: item.sign,
                },
                isAddModalOpen: true,
              }),
            onDriverSend: (item: IOrder) =>
              this.setState({
                edit: item,
                isSendDriverModal: true,
              }),
            onClientSend: (item: IOrder) =>
              this.setState({
                edit: item,
                isSendClientModal: true,
              }),
            onSelectAll: (items: IOrder[]) => this.setState({ selected: items }),
            onUnSelectAll: () => this.setState({ selected: [] }),
            onSetPage: page => this.setState({ defaultPage: page }),
            onSort: ({ key, sortOrder }) => this.setState({ defaultSortKey: key, defaultSortOrder: sortOrder }),
          } }
        />
        <ModalTransition>
          { (this.state.isAddModalOpen || this.state.isEditModalOpen) && (
            <OrderModal
              heading={ `${ this.state.isAddModalOpen ? 'Добавить' : 'Редактировать' } заказ` }
              { ...{
                item: this.state.edit,
                isAddModalOpen: this.state.isAddModalOpen,
                onSave: (item: IOrder) => {
                  if (this.state.isAddModalOpen) {
                    return this.addSave({ ...item, payments: this.state.edit.payments }).then(() => {
                      this.setState({
                        edit: { ...this.state.edit, payments: [] },
                        editPayment: null,
                        editPaymentIndex: 0,
                      })
                      this.closeModals()
                    })
                  } else {
                    return this.editSave(
                      {
                        ...item,
                        payments: this.state.edit.payments,
                      },
                      orders.find(order => order.id === item.id) || null,
                    ).then(() => {
                      this.setState({
                        edit: { ...this.state.edit, payments: [] },
                        editPayment: null,
                        editPaymentIndex: 0,
                      })
                      this.closeModals()
                    })
                  }
                },
                onCancel: () => this.closeModals(),
                onCreateClient: () => this.setState({ isCreateClientModal: true }),
                onCreateReferer: () => this.setState({ isCreateRefererModal: true }),
                onCreateProvider: () => this.setState({ isCreateProviderModal: true }),
                onCreatePayment: () =>
                  this.setState({
                    isCreatePaymentModal: true,
                    editPayment: { ...clearPaymentEdit, order: this.state.edit },
                  }),
                onEditPayment: (item, index) =>
                  this.setState({
                    isEditPaymentModal: true,
                    editPayment: { ...item, order: this.state.edit },
                    editPaymentIndex: index,
                  }),
                onDriverSend: (item: IOrder) =>
                  this.setState({
                    edit: item,
                    isSendDriverModal: true,
                  }),
                onSelectClient: client => this.setState({ edit: { ...this.state.edit, client } }),
                onSelectReferer: referer => this.setState({ edit: { ...this.state.edit, referer } }),
                onSelectProvider: provider => this.setState({ edit: { ...this.state.edit, provider } }),
              } }
            />
          ) }
          { this.state.isVoucherModalOpen && (
            <CreateVoucherModal
              orders={ this.state.selected.map(order => order.id) }
              users={ this.state.selected.reduce((prev: IUser[], order) => {
                if (order.client && !prev.find(item => (order.client ? item.id === order.client.id : true))) {
                  prev.push(order.client)
                }
                if (order.referer && !prev.find(item => (order.referer ? item.id === order.referer.id : true))) {
                  prev.push(order.referer)
                }
                if (order.provider && !prev.find(item => (order.provider ? item.id === order.provider.id : true))) {
                  prev.push(order.provider)
                }
                return prev
              }, []) }
              drivers={ this.state.selected.reduce((prev: IUser[], order) => {
                if (order.driver && !prev.find(item => (order.driver ? item.id === order.driver.id : true))) {
                  prev.push(order.driver)
                }
                if (order.provider && !prev.find(item => (order.provider ? item.id === order.provider.id : true))) {
                  prev.push(order.provider)
                }
                return prev
              }, []) }
              transfers={ this
                .state
                .selected
                .sort((order1, order2) => utcDate(order1.delivery).isBefore(utcDate(order2.delivery)) ? -1 : 1)
                .map(order => {
                  const payments = order.payments && order.payments.length ? order.payments : null
                  const payment = payments ? payments.sort((a, b) => (a >= b ? (a !== b ? 0 : 1) : -1))[0] : null
                  return {
                    id: order.id,
                    delivery: order.delivery
                      ? `${ this.state.lang === 'ru' ? 'Дата' : 'Date' } ${ utcDate(order.delivery).format('DD.MM.YYYY') }. ${ this.state.lang === 'ru' ? 'Время подачи' : 'Transfer time' } ${ utcDate(
                        order.delivery,
                      ).format('HH:mm') }`
                      : '',
                    transferType: order.transferType ? transferType[order.transferType][this.state.lang] : '',
                    flight: order.flight || '',
                    flightTime: order.flightTime || '',
                    route: [order.departure, order.intermediate, order.destination].filter(Boolean).join(' => '),
                    passengers: `${ order.passengers || '' } ${ this.state.lang === 'ru' ? 'чел.' : 'pax' }`,
                    luggage: order.luggage || '',
                    car: order.carClass || '',
                    sign: order.sign || '',
                    paymentType: payment ? paymentType[payment.type][this.state.lang] : '',
                    paymentPrice: payment ? `${ localePrice(payment.price || 0) }` : '',
                    description: order.description || '',
                  }
                }) }
              lang={ this.state.lang }
              excursions={ excursions }
              onSave={ async (voucher, updatedOrders) => {
                await this.props.updateOrderVoucher({
                  variables: {
                    ids: updatedOrders,
                    voucher: voucher.number.toString(10),
                  },
                })
                await this.refetchOrders(this.state.where)
                return this.addVoucher(voucher)
              } }
              onUpdate={ voucher => this.updateVoucher(voucher) }
              onCancel={ () => this.closeModals() }
            />
          ) }
          { this.state.isDeleteModalOpen && (
            <DeleteModal
              onCancel={ () => this.closeModals() }
              onDelete={ () =>
                this.props
                  .deleteMany({ variables: { where: { id_in: this.state.selected.map(item => item.id as string) } } })
                  .then(() => this.setState({ isDeleteModalOpen: false, selected: [] }))
                  .then(() => this.refetchOrders(this.state.where))
              }
              count={ this.state.selected.length }
            />
          ) }

          { this.state.isCreateClientModal && (
            <ClientModal
              item={ {
                id: '',
                name: '',
                email: '',
                phone: '',
                description: '',
                types: ['CLIENT'],
                roles: [],
              } }
              heading={ 'Добавить клиента' }
              isAddModalOpen={ true }
              onSave={ client =>
                this.props.createUser({ variables: { data: User.format(client) } }).then(response => {
                  this.setState({
                    isCreateClientModal: false,
                    edit: { ...this.state.edit, client: response.data.createUser },
                  })
                })
              }
              onCancel={ () => this.setState({ isCreateClientModal: false }) }
            />
          ) }
          { this.state.isCreateRefererModal && (
            <ClientModal
              item={ {
                id: '',
                name: '',
                email: '',
                phone: '',
                description: '',
                types: ['PARTNER'],
                roles: ['REFERER'],
              } }
              heading={ 'Добавить источник' }
              isAddModalOpen={ true }
              onSave={ referer =>
                this.props.createUser({ variables: { data: User.format(referer) } }).then(response => {
                  this.setState({
                    isCreateRefererModal: false,
                    edit: { ...this.state.edit, referer: response.data.createUser },
                  })
                })
              }
              onCancel={ () => this.setState({ isCreateRefererModal: false }) }
            />
          ) }
          { this.state.isCreateProviderModal && (
            <ClientModal
              item={ {
                id: '',
                name: '',
                email: '',
                phone: '',
                description: '',
                types: ['PARTNER'],
                roles: ['PROVIDER'],
              } }
              heading={ 'Добавить исполнителя' }
              isAddModalOpen={ true }
              onSave={ provider =>
                this.props.createUser({ variables: { data: User.format(provider) } }).then(response => {
                  this.setState({
                    isCreateProviderModal: false,
                    edit: { ...this.state.edit, provider: response.data.createUser },
                  })
                })
              }
              onCancel={ () => this.setState({ isCreateProviderModal: false }) }
            />
          ) }

          { (this.state.isCreatePaymentModal || this.state.isEditPaymentModal) && (
            <PaymentModal
              item={ {
                ...(this.state.editPayment || clearPaymentEdit),
                order: this.state.edit,
                timestamp: this.state.edit.delivery,
              } }
              heading={ `${ this.state.isCreatePaymentModal ? 'Добавить' : 'Редактировать' } платеж` }
              isAddModalOpen={ this.state.isCreatePaymentModal }
              onSave={ payment => {
                const prevPayments = [...(this.state.edit.payments || [])]

                if (this.state.isCreatePaymentModal) {
                  prevPayments.push(payment)
                }

                if (this.state.isEditPaymentModal) {
                  prevPayments[this.state.editPaymentIndex] = payment
                }

                return Promise.resolve(() => console.log(prevPayments)).then(() =>
                  this.setState({
                    edit: { ...this.state.edit, payments: prevPayments },
                    isCreatePaymentModal: false,
                    isEditPaymentModal: false,
                    editPayment: clearPaymentEdit,
                    editPaymentIndex: 0,
                  }),
                )
              } }
              canDelete={ this.state.isEditPaymentModal }
              onDelete={ () => {
                const prevPayments = [...(this.state.edit.payments || [])]
                prevPayments.splice(this.state.editPaymentIndex, 1)

                this.setState({
                  edit: { ...this.state.edit, payments: prevPayments },
                  isCreatePaymentModal: false,
                  isEditPaymentModal: false,
                  editPayment: clearPaymentEdit,
                  editPaymentIndex: 0,
                })
              } }
              onCancel={ () =>
                this.setState({
                  isCreatePaymentModal: false,
                  isEditPaymentModal: false,
                  editPayment: { ...clearPaymentEdit },
                  editPaymentIndex: 0,
                })
              }
              usersFilter={ (item: IUser) => (this.state.edit.client ? this.state.edit.client.id === item.id : true) }
            />
          ) }

          { this.state.isSendDriverModal && (
            <SendDriverModal
              item={ {
                ...this.state.edit,
                flightTime:
                  this.state.edit.flightTime && moment(this.state.edit.flightTime, 'HH:mm').isValid()
                    ? moment(this.state.edit.flightTime, 'HH:mm').format('HH:mm')
                    : '',
              } }
              payment={
                this.state.edit.payments && this.state.edit.payments.length
                  ? this.state.edit.payments.sort((a, b) => (a >= b ? (a !== b ? 0 : 1) : -1))[0]
                  : null
              }
              route={ [this.state.edit.departure, this.state.edit.intermediate, this.state.edit.destination]
                .filter(Boolean)
                .join(' => ') }
              onCancel={ () => this.setState({ isSendDriverModal: false }) }
              onSuccess={ () => {
                this.editSave({
                    ...this.state.edit,
                    status: 'ASSIGNED',
                  }, orders.find(order => order.id === this.state.edit.id) || null)
                  .then(() => this.setState({ isSendDriverModal: false }))
              } }
            />
          ) }
          { this.state.isSendClientModal && (
            <SendClientModal
              item={ {
                ...this.state.edit,
                flightTime:
                  this.state.edit.flightTime && moment(this.state.edit.flightTime, 'HH:mm').isValid()
                    ? moment(this.state.edit.flightTime, 'HH:mm').format('HH:mm')
                    : '',
              } }
              payment={
                this.state.edit.payments
                  ? this.state.edit.payments.sort((a, b) => (a >= b ? (a !== b ? 0 : 1) : -1))[0]
                  : null
              }
              route={ [this.state.edit.departure, this.state.edit.intermediate, this.state.edit.destination]
                .filter(Boolean)
                .join(' => ') }
              onCancel={ () => this.setState({ isSendClientModal: false }) }
              onSuccess={ () => {
                if (this.state.edit.clientNotification) {
                  return this.setState({ isSendClientModal: false })
                }
                return this.editSave({
                    ...this.state.edit,
                    clientNotification: true,
                  }, orders.find(order => order.id === this.state.edit.id) || null)
                  .then(() => this.setState({ isSendClientModal: false }))
              } }
            />
          ) }
          { this.state.isCreateAuthorizationModal && (
            <CreateAuthorizationModal
              description={
                this.state.selected
                  .sort((item1, item2) => {
                    return moment(item1.delivery).isAfter(item2.delivery) ? 1 : -1
                  })
                  .map(item => {
                    return `${ utcDate(item.delivery).format('DD.MM.YYYY') } Transfer: ${ item.departure } - ${ item.destination }`
                  })
              }
              payments={
                this.state.selected.reduce<IPayment[]>((prev, order) => {
                    if (order.payments && order.payments.length) {
                      prev.push(order.payments.sort((a, b) => (a.price <= b.price ? (a.price !== b.price ? 0 : 1) : -1))[0])
                    }
                    return prev
                  }, [])
                  .sort((a, b) => moment(a.timestamp).isAfter(b.timestamp) ? 1 : -1)
              }
              users={ this.state.selected.reduce((prev: IUser[], order) => {
                if (order.client && !prev.find(item => (order.client ? item.id === order.client.id : true))) {
                  prev.push(order.client)
                }
                if (order.referer && !prev.find(item => (order.referer ? item.id === order.referer.id : true))) {
                  prev.push(order.referer)
                }
                if (order.provider && !prev.find(item => (order.provider ? item.id === order.provider.id : true))) {
                  prev.push(order.provider)
                }
                return prev
              }, []) }
              onCancel={ () => this.setState({ isCreateAuthorizationModal: false }) }
              onSave={ authorization =>
                this
                  .createAuthorization(authorization)
                  .then(response => {
                    this.setState({ isCreateAuthorizationModal: false })
                    return response
                  })
              }
              onUpdate={ (authorization) => this
                .updateAuthorization(authorization)
                .then(response => {
                  this.setState({ isCreateAuthorizationModal: false })
                  return response
                }) }
            />
          ) }
          { this.state.isCreateProformModal && (
            <CreateProformModal
              users={ this.state.selected.reduce((prev: IUser[], order) => {
                if (order.client && !prev.find(item => (order.client ? item.id === order.client.id : true))) {
                  prev.push(order.client)
                }
                if (order.referer && !prev.find(item => (order.referer ? item.id === order.referer.id : true))) {
                  prev.push(order.referer)
                }
                if (order.provider && !prev.find(item => (order.provider ? item.id === order.provider.id : true))) {
                  prev.push(order.provider)
                }
                return prev
              }, []) }
              lang={ this.state.lang }
              transfers={
                this.state.selected
                  .sort((order1, order2) => utcDate(order1.delivery).isBefore(utcDate(order2.delivery)) ? -1 : 1)
                  .map(order => {
                    return {
                      date: utcDate(order.delivery).format('DD.MM.YYYY'),
                      price: order.payments && order.payments.length
                        ? order.payments.sort((a, b) => (a >= b ? (a !== b ? 0 : 1) : -1))[0].price
                        : 0,
                      description: [
                        order.flight ? `${ this.state.lang === 'ru' ? 'Рейс' : 'Flight' } ${ order.flight }; ${ order.transferType ? transferType[order.transferType][this.state.lang] : '' } ${ this.state.lang === 'ru' ? 'в' : 'at' } ${ order.flightTime || '' }` : '',
                        [order.departure, order.intermediate, order.destination].filter(Boolean).join(' => '),
                        order.passengers ? `${ order.passengers } ${ this.state.lang === 'ru' ? 'пассажира' : 'passengers' }` : '',
                        order.luggage ? `${ order.luggage } ${ this.state.lang === 'ru' ? 'чемодана' : 'luggage items' }` : '',
                      ].filter(v => !!v),
                      transferType: order.transferType ? transferType[order.transferType][this.state.lang] : null,
                    }
                  })
              }
              onCancel={ () => this.setState({ isCreateProformModal: false }) }
              onSave={ proform =>
                this
                  .createProform(proform)
                  .then(response => {
                    this.setState({ isCreateProformModal: false })
                    return response
                  })
              }
              onUpdate={ proform => this
                .updateProform(proform)
                .then(response => {
                  this.setState({ isCreateProformModal: false })
                  return response
                }) }
            />
          ) }
        </ModalTransition>
      </>
    )
  }
}

export default ordersCompose(Orders)
