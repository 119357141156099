import * as React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { Checkbox } from '@atlaskit/checkbox';
import Button from '@atlaskit/button';
import EditorEditIcon from '@atlaskit/icon/glyph/editor/edit';
import Lozenge from '@atlaskit/lozenge';
import { IDefaultTableProps, LozengeGroup } from '../shared';
import { UserRoles, IUser } from '../shared/users';

export default class EmployeesTable extends React.PureComponent<IDefaultTableProps<IUser>> {

  render () {
    return (
      <DynamicTable
        head={{
          cells: [
            {
              content: (
                <Checkbox
                  onChange={() =>
                    !this.props.selected.length ? this.props.onSelectAll(this.props.items) : this.props.onUnSelectAll()
                  }
                  label={null}
                  isIndeterminate={this.props.selected.length !== this.props.items.length}
                  isChecked={this.props.selected.length}
                />
              ),
              width: '20px',
              shouldTruncate: false,
              isSortable: false
            },
            {
              key: 'name',
              content: 'Имя',
              shouldTruncate: true,
              isSortable: true,
            },
            {
              key: 'role',
              content: 'Роль',
              shouldTruncate: true,
              isSortable: true,
            },
            {
              width: '40px'
            }
          ]
        }}
        rows={this.props.items.map(item => ( {
          cells: [
            {
              content: (
                <Checkbox
                  label={null}
                  isChecked={!!this.props.selected.find(select => select.id === item.id)}
                  onChange={() => this.props.onSelect(item)}
                />
              )
            },
            {
              key: item.name.replace(/ /g, ''),
              content: item.name
            },
            {
              key: item.roles,
              content: (
                <LozengeGroup>
                  {item.roles.map(role => (
                    <Lozenge appearance={'new'} key={role}>
                      {UserRoles[ role ]}
                    </Lozenge>
                  ))}
                </LozengeGroup>
              )
            },
            {
              content: (
                <Button
                  iconBefore={<EditorEditIcon label="Редактировать"/>}
                  onClick={() => this.props.onEdit(item)}
                />
              )
            }
          ],
          key: item.id
        } ))}
        emptyView={<h2>Список сотрудников пуст</h2>}
        rowsPerPage={localStorage.getItem('rowsPerPage' || 10)}
        defaultPage={1}
        loadingSpinnerSize="large"
        {...this.props}
      />
    );
  }
}
