import * as React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { Checkbox } from '@atlaskit/checkbox';
import Button from '@atlaskit/button';
import EditorEditIcon from '@atlaskit/icon/glyph/editor/edit';
import { Card, CardCounter, CardTitle, IDefaultTableProps, localePrice, utcDate } from '../shared';
import { IPayment, paymentType } from '../shared/payments';
import { DateLozenge } from '@atlaskit/date';
import Lozenge from '@atlaskit/lozenge';
import { colors } from '@atlaskit/theme'

export default class PaymentsTable extends React.PureComponent<IDefaultTableProps<IPayment>, { expanded: boolean }> {
  state = {
    expanded: false
  };

  render () {
    let totalCash = 0;
    let totalFacture = 0;
    let totalCard = 0;

    this.props.items.map(item => {
      if (item.payee && item.payee.types.includes('COMPANY')) {
        totalFacture += item.type === 'INVOICE' ? item.price : 0;
        totalCash += item.type === 'CASH' ? item.price : 0;
        totalCard += item.type === 'CARD' ? item.price : 0;
      }
      if (item.payer && item.payer.types.includes('COMPANY')) {
        totalFacture -= item.type === 'INVOICE' ? item.price : 0;
        totalCash -= item.type === 'CASH' ? item.price : 0;
        totalCard -= item.type === 'CARD' ? item.price : 0;
      }
    });

    return (
      <React.Fragment>
        <DynamicTable
          head={{
            cells: [
              {
                content: (
                  <Checkbox
                    onChange={() =>
                      !this.props.selected.length
                        ? this.props.onSelectAll(this.props.items)
                        : this.props.onUnSelectAll()
                    }
                    label={null}
                    isIndeterminate={this.props.selected.length !== this.props.items.length}
                    isChecked={this.props.selected.length}
                  />
                ),
                width: '20px',
                shouldTruncate: false,
                isSortable: false
              },
              {
                key: 'timestamp',
                content: 'Дата',
                shouldTruncate: true,
                isSortable: true,
                width: '80px'
              },
              {
                key: 'delivery',
                content: 'Дата заказа',
                shouldTruncate: true,
                isSortable: true,
                width: '80px'
              },
              {
                key: 'price',
                content: 'Сумма',
                shouldTruncate: true,
                isSortable: true,
                width: '80px'
              },
              {
                key: 'type',
                content: 'Tipo',
                width: '80px'
              },
              {
                key: 'paid',
                content: 'Status',
                shouldTruncate: true,
                isSortable: true,
                width: '80px'
              },
              {
                key: 'payer',
                content: 'Плательщик',
                shouldTruncate: true,
                isSortable: true,
                width: '150px'
              },
              {
                key: 'payee',
                content: 'Получатель',
                shouldTruncate: true,
                isSortable: true,
                width: '150px'
              },
              {
                key: 'order.description',
                content: 'Описание заказа',
                shouldTruncate: false,
                isSortable: true
              },
              {
                width: '40px'
              }
            ]
          }}
          rows={this.props.items.map(item => ( {
            cells: [
              {
                content: (
                  <Checkbox
                    label={null}
                    isChecked={!!this.props.selected.find(select => select.id === item.id)}
                    onChange={() => this.props.onSelect(item)}
                  />
                )
              },
              {
                key: item.timestamp,
                content: <DateLozenge>{utcDate(item.timestamp).format('DD.MM.YYYY')}</DateLozenge>
              },
              {
                key: item.order.delivery,
                content: <DateLozenge>{utcDate(item.order.delivery).format('DD.MM.YYYY')}</DateLozenge>
              },
              {
                key: item.price,
                content: localePrice(item.price)
              },
              {
                key: item.type,
                content: paymentType[ item.type ].it
              },
              {
                key: item.paid,
                content: <Lozenge appearance={item.paid ? 'success' : 'removed'}>
                  {item.paid ? 'Pagato' : 'Non pagato'}
                </Lozenge>
              },
              {
                key: item.payer ? item.payer.name.replace(/ /g, '') : '',
                content: item.payer ? item.payer.name : ''
              },
              {
                key: item.payee ? item.payee.name.replace(/ /g, '') : '',
                content: item.payee ? item.payee.name : ''
              },
              {
                key: item.order ? `${item.order.departure}${item.order.destination}${item.order.passengers}` : null,
                content: item.order ? `${item.order.departure} ⇒ ${item.order.destination}, ${item.order.passengers} чел.` : null,
              },
              {
                content: (
                  <Button
                    iconBefore={<EditorEditIcon label="Редактировать"/>}
                    onClick={() => this.props.onEdit(item)}
                  />
                )
              }
            ],
            key: item.id
          } ))}
          emptyView={<h2>Список платежей пуст</h2>}
          rowsPerPage={localStorage.getItem('rowsPerPage') || 10}
          defaultPage={1}
          loadingSpinnerSize="large"
          // isFixedSize={true}
          {...this.props}
        />
        <div
          style={{
            marginTop: '20px',
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, 300px)',
            gridGap: '20px'
          }}
        >
          <Card>
            <CardTitle>Totale</CardTitle>
            <CardCounter><b style={{color: colors.R400 }}>{localePrice(totalCash + totalCard + totalFacture)}</b></CardCounter>
          </Card>
          <Card>
            <CardTitle>Totale contanti</CardTitle>
            <CardCounter>{localePrice(totalCash)}</CardCounter>
          </Card>
          <Card>
            <CardTitle>Totale Carta</CardTitle>
            <CardCounter>{localePrice(totalCard)}</CardCounter>
          </Card>
          <Card>
            <CardTitle>Totale Fattura</CardTitle>
            <CardCounter>{localePrice(totalFacture)}</CardCounter>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}
