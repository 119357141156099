import * as React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { withRouter, RouteComponentProps, NavLink } from 'react-router-dom';

const Wrapper = styled.div`
  background-color: ${colors.N20};
  box-sizing: border-box;
  padding: 8px;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  grid-gap: 8px;
  width: 100%;
`;

const Link = styled(NavLink)`
  padding: 4px 8px;
  color: ${colors.N400}
`;

const Split = styled.span`
  width: 1px;
  background: ${colors.N40};
`;

class Navigation extends React.Component<RouteComponentProps, {}> {
  navigate = (path: string) => this.props.history.push(path);

  render () {
    return (
      <Wrapper>
        {
          [
            { path: '/', key: 'Dashboard', text: 'Dashboard' },
            null,
            { path: '/orders', key: 'Orders', text: 'Заказы' },
            { path: '/payments', key: 'Payments', text: 'Pagamenti' },
            { path: '/expenses', key: 'Expenses', text: 'Расходы' },
            null,
            { path: '/vouchers', key: 'Voucher', text: 'Ваучера' },
            { path: '/authorizations', key: 'Authorizations', text: 'Авторизации' },
            { path: '/proforms', key: 'Proforms', text: 'Проформы' },
            null,
            { path: '/clients', key: 'Clients', text: 'Клиенты' },
            { path: '/partners', key: 'Partners', text: 'Партнеры' },
            { path: '/employees', key: 'Employees', text: 'Сотрудники' },
            null,
            { path: '/cars', key: 'Cars', text: 'Авто' },
            { path: '/excursions', key: 'Excursions', text: 'Экскурсии' },
            null,
            { path: '/settings', key: 'Settings', text: 'Настройки' }
          ].map((route, index) => {
            if (route) {
              return <Link
                to={route.path}
                key={route.key}
                activeStyle={{
                  backgroundColor: colors.N30,
                  borderRadius: '3px',
                  color: colors.N800
                }}
                exact={route.path === '/'}
              >{route.text}</Link>;
            } else {
              return <Split key={index}/>;
            }
          })
        }
      </Wrapper>
    );
  }
}

export default withRouter(Navigation);
