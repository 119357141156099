import * as React from 'react';
import { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import { IExpense } from '../shared/expenses';
import Select from '@atlaskit/select';
import { DatePicker } from '@atlaskit/datetime-picker';
import * as startOfMonth from 'date-fns/start_of_month';
import * as endOfMonth from 'date-fns/end_of_month';
import * as isBefore from 'date-fns/is_before';
import * as startOfDay from 'date-fns/start_of_day';
import * as endOfDay from 'date-fns/end_of_day';
import { UserSelect } from '../components/UserSelect';
import { IOrder } from '../shared/orders';
import { DataProps, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Card, CardCounter, CardTitle, localePrice } from '../shared';
import { MoneySelect } from '../components/MoneySelect';
import { RouteComponentProps } from 'react-router';
import { IPayment } from '../shared/payments';

interface IState {
  ordersFilterMap: Map<string, (value: IOrder, index: number) => boolean>;
  expensesFilterMap: Map<string, (value: IExpense, index: number) => boolean>;
  paymentsFilterMap: Map<string, (value: IPayment, index: number) => boolean>;
}

const QUERY = gql`query {
    orders {
        id
        referer {
            id
            name
            types
        }
        provider {
            id
            name
            types
        }
        driver {
            id
            name
        }
        payments {
            id
            timestamp
            price
            type
            paid
            payer {
                id
                name
                types
            }
            payee {
                id
                name
                types
            }
        }
    }
    expenses {
        id
        timestamp
        price
        type
        employee {
            id
            name
            types
            roles
        }
    }
}`;

export interface IDashboardProps extends RouteComponentProps, DataProps<{ orders: IOrder[], expenses: IExpense[] }> {
}

export class Dashboard extends React.Component<IDashboardProps, IState> {
  state: IState = {
    ordersFilterMap: new Map(),
    expensesFilterMap: new Map()
      .set('dateStart', item => isBefore(startOfMonth(Date.now()), item.timestamp))
      .set('dateEnd', item => isBefore(item.timestamp, endOfMonth(Date.now()))),
    paymentsFilterMap: new Map()
      .set('dateStart', item => isBefore(startOfMonth(Date.now()), item.timestamp))
      .set('dateEnd', item => isBefore(item.timestamp, endOfMonth(Date.now())))
  };

  render (): React.ReactNode {
    const income = {
      CASH: 0,
      CARD: 0,
      INVOICE: 0,
    };
    const outcome = {
      CASH: 0,
      CARD: 0,
      INVOICE: 0,
    };
    const feecome = {
      CASH: 0,
      CARD: 0,
      INVOICE: 0,
    };

    Array
      .from(this.state.ordersFilterMap.values())
      .reduce((prev: IOrder[], item) => prev.filter(item), this.props.data.orders || [])
      .forEach(order => {

        let feeInCash = 0;
        const tempFeeCome = {
          CASH: 0,
          CARD: 0,
          INVOICE: 0,
        };

        Array
          .from(this.state.paymentsFilterMap.values())
          .reduce((prev: IPayment[], item) => prev.filter(item), order.payments || [])
          .forEach(payment => {

            if (!order.provider || !order.provider.types.includes('COMPANY')) {
              if (payment.payee && payment.payee.types.includes('COMPANY')) {
                tempFeeCome[ payment.type ] += payment.price || 0;
              }
              if (payment.payer && payment.payer.types.includes('COMPANY')) {
                tempFeeCome[ payment.type ] -= payment.price || 0;
              }
            }

            if (payment.payee && payment.payee.types.includes('COMPANY')) {
              feeInCash += payment.type === 'CASH' ? payment.price : 0;
              income[ payment.type ] += payment.price || 0;
            }
            if (payment.payer && payment.payer.types.includes('COMPANY')) {
              outcome[ payment.type ] += payment.price || 0;
            }
          });

        if (feeInCash > 0) {
          feecome.CASH += tempFeeCome.CASH;
          feecome.CASH += tempFeeCome.CARD <= 0 ? tempFeeCome.CARD : 0;
          feecome.CASH += tempFeeCome.INVOICE <= 0 ? tempFeeCome.INVOICE : 0;
        }

      });

    Array
      .from(this.state.expensesFilterMap.values())
      .reduce((prev: IExpense[], item) => prev.filter(item), this.props.data.expenses || [])
      .forEach(expense => outcome[ expense.type ] += expense.price || 0);

    return (
      <Grid layout="fluid">
        <GridColumn medium={12}>
          <PageHeader
            bottomBar={
              <div
                style={{
                  display: 'grid',
                  gridAutoColumns: 'minmax(150px, 200px)',
                  gridAutoFlow: 'column',
                  gridGap: '10px'
                }}
              >
                <DatePicker
                  spacing="compact"
                  placeholder="Дата от"
                  dateFormat={'DD.MM.YY'}
                  defaultValue={startOfMonth(Date.now())}
                  onChange={date => this.setState({
                    paymentsFilterMap: this.state.paymentsFilterMap
                      .set('dateStart', payment => date ? isBefore(startOfDay(date), endOfDay(payment.timestamp)) : true),
                    expensesFilterMap: this.state.expensesFilterMap
                      .set('dateStart', expense => date ? isBefore(startOfDay(date), endOfDay(expense.timestamp)) : true)
                  })}
                  isClearable={true}
                />
                <DatePicker
                  spacing="compact"
                  placeholder="Дата до"
                  dateFormat={'DD.MM.YY'}
                  defaultValue={endOfMonth(Date.now())}
                  onChange={date => this.setState({
                    paymentsFilterMap: this.state.paymentsFilterMap
                      .set('dateEnd', payment => date ? isBefore(startOfDay(payment.timestamp), endOfDay(date)) : true),
                    expensesFilterMap: this.state.expensesFilterMap
                      .set('dateEnd', expense => date ? isBefore(startOfDay(expense.timestamp), endOfDay(date)) : true)
                  })}
                  isClearable={true}
                />
                <UserSelect
                  placeholder="Водитель"
                  filter={item => item.types.includes('EMPLOYEE') && item.roles.includes('DRIVER')}
                  spacing="compact"
                  onSelect={user => this.setState({
                    ordersFilterMap: this.state.ordersFilterMap
                      .set('driverMiddleware', order => user ? order.driver ? order.driver.id === user.id : false : true)
                  })}
                />
                <MoneySelect
                  placeholder="Тип"
                  spacing="compact"
                  onChange={item => this.setState({
                    paymentsFilterMap: this.state.paymentsFilterMap
                      .set('type', payment => item ? item.value === payment.type : true),
                    expensesFilterMap: this.state.expensesFilterMap
                      .set('type', expense => item ? item.value === expense.type : true)
                  })}
                />
                <Select
                  options={[
                    { value: true, label: 'Оплачен' },
                    { value: false, label: 'Не оплачен' }
                  ]}
                  placeholder="Статус"
                  spacing="compact"
                  onChange={item => this.setState({
                    paymentsFilterMap: this.state.paymentsFilterMap
                      .set('status', payment => item ? item.value === payment.paid : true)
                  })}
                  isClearable={true}
                />
              </div>
            }
          >
            Статистика
          </PageHeader>
        </GridColumn>
        <GridColumn medium={12}>
          <div
            style={{
              marginTop: '20px',
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, 300px)',
              // gridAutoFlow: 'column',
              gridGap: '20px'
            }}
          >
            <Card>
              <CardTitle>Доходы, всего</CardTitle>
              <CardCounter>{localePrice(income.CASH + income.CARD + income.INVOICE)}</CardCounter>
            </Card>
            <Card>
              <CardTitle>Доходы, наличные</CardTitle>
              <CardCounter>{localePrice(income.CASH)}</CardCounter>
            </Card>
            <Card>
              <CardTitle>Доходы, карта</CardTitle>
              <CardCounter>{localePrice(income.CARD)}</CardCounter>
            </Card>
            <Card>
              <CardTitle>Доходы, фактура</CardTitle>
              <CardCounter>{localePrice(income.INVOICE)}</CardCounter>
            </Card>
            <Card>
              <CardTitle>Доходы по комиссии, наличные</CardTitle>
              <CardCounter>{localePrice(feecome.CASH)}</CardCounter>
            </Card>
          </div>
          <div
            style={{
              marginTop: '20px',
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, 300px)',
              // gridAutoFlow: 'column',
              gridGap: '20px'
            }}
          >
            <Card>
              <CardTitle>Расходы, всего</CardTitle>
              <CardCounter>{localePrice(outcome.CASH + outcome.CARD + outcome.INVOICE)}</CardCounter>
            </Card>
            <Card>
              <CardTitle>Расходы, наличные</CardTitle>
              <CardCounter>{localePrice(outcome.CASH)}</CardCounter>
            </Card>
            <Card>
              <CardTitle>Расходы, карта</CardTitle>
              <CardCounter>{localePrice(outcome.CARD)}</CardCounter>
            </Card>
            <Card>
              <CardTitle>Расходы, фактура</CardTitle>
              <CardCounter>{localePrice(outcome.INVOICE)}</CardCounter>
            </Card>
          </div>
        </GridColumn>
      </Grid>
    );
  }
}

export default graphql(QUERY)(Dashboard);
