import styled from 'styled-components';
import './users';
import * as moment from 'moment';

export const Wrapper = styled.div`
  min-width: 1366px;
`;

export const LozengeGroup = styled.div`{
  display: grid;
  grid-gap: 0.5rem;
  grid-auto-flow: column;
  justify-content: start;
`;

export const clearTime = moment(Date.now()).utcOffset(2).toISOString();

export const utcDate = (date: string | Date) => moment(date).utcOffset(2);

export const clearField = <R> (obj: R): Exclude<R, 'id' | '__typename'> => {
  return Object.assign({},
    ...Object.keys(obj)
      .filter(key => [ 'id', '__typename' ].indexOf(key) === -1)
      .map(key => ( { [ key ]: obj[ key ] } ))
  );
};

export interface ID {
  id: string;
}

export interface IFunctionsProps<M, I extends { id: string }> {
  create (options: { variables: { data: M; where?: any } }): Promise<{ data: { create: I } }>;

  update (options: { variables: { data: M; where: Pick<I, 'id'> } }): Promise<{ data: { update: I } }>;

  deleteMany (options: { variables: { where: { id_in: string[] } } }): Promise<{ data: { deleteMany: { count: number } } }>;
}

export interface IDefaultState<T> {
  isEditModalOpen: boolean;
  isAddModalOpen: boolean;
  isDeleteModalOpen: boolean;

  isActionOpen: boolean;
  isSelectMode: boolean;
  edit: T;
  defaultSortKey: string | null;
  defaultSortOrder: string;
  defaultPage: number;
  selected: T[];
}

export const defaultState = {
  isEditModalOpen: false,
  isAddModalOpen: false,
  isDeleteModalOpen: false,

  isActionOpen: false,
  isSelectMode: false,
  defaultSortOrder: 'DESC',
  defaultPage: 1,
  selected: []
};

export interface IDefaultTableProps<T> {
  items: T[];
  isSelectMode: boolean;
  selected: T[];
  isLoading: boolean;

  onEdit (item: T): void;

  onSelect (item: T): void;

  onSelectAll (items: T[]): void;

  onUnSelectAll (): void;
}

export interface IModalProps<T> {
  item: T;
  heading: string;
  isAddModalOpen: boolean;

  onSave (item: T): Promise<any>;

  onCancel (): void;
}

export interface IState<T> {
  filters: Map<string, (value: T, index: number) => boolean>;
}

export const Card = styled.div`
    background-color: #FFFFFF;
    border-radius: 3px;
    box-sizing: border-box;
    box-shadow: 0 0 1px rgba(9,30,66,0.31), 0 20px 32px -8px rgba(9,30,66,0.25);
    color: #42526E;
    padding: 16px 32px;
    width: 100%;
`;

export const CardTitle = styled.span`
    color: #42526E;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const CardCounter = styled.h1`
  margin-top: 12px;
`;

export const localePrice = (price: number) => ( price || 0 ).toLocaleString('en-GB', {
  style: 'currency',
  currency: 'EUR'
});

export const localeTime = (value: number) => ( value || 0 ).toLocaleString('en-US', {
  minimumIntegerDigits: 2,
  useGrouping: false
});