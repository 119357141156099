import * as React from 'react';
import { clearField, IState } from '../shared';
import { Default } from './Default';
import { ICar, ICarsProps, carsCompose } from '../shared/cars';
import CarModal from '../components/CarModal';
import CarsTable from '../components/CarsTable';
import * as format from 'date-fns/format';

const clearEdit: ICar = {
  id: '',
  model: '',
  number: '',
  insurance_from: null,
  insurance_to: null,
  permit_from: null,
  permit_to: null,
  maintenance_from: null,
  maintenance_to: null,
  maintenance_mileage: null,
  maintenance_comment: null,
  mot_from: null,
  mot_to: null
};

class Cars extends React.Component<ICarsProps, IState<ICar>> {
  state: IState<ICar> = { filters: new Map() };

  addSave (item: ICar) {
    return this.props.create({ variables: { data: clearField<ICar>(item) } });
  }

  editSave (item: ICar) {
    return this.props.update({ variables: { where: { id: item.id }, data: clearField<ICar>(item) } });
  }

  render () {
    const cars: ICar[] = Array.from(this.state.filters.values()).reduce(
      (prev: ICar[], item) => prev.filter(item),
      this.props.data.cars || []
    );
    return (
      <Default
        title={'Автомобили'}
        addable={true}
        clearEdit={clearEdit}
        defaultSortKey={'model'}
        items={cars}
        table={props => (
          <CarsTable
            items={cars}
            isLoading={this.props.data.loading}
            {...props}
          />
        )}
        modal={props => (
          <CarModal heading={`${props.isAddModalOpen ? 'Добавить' : 'Редактировать'} автомобиль`} {...props} />
        )}
        onSave={(item: ICar, isAddModalOpen: boolean) => {
          return isAddModalOpen ? this.addSave(item) :  this.editSave(item)
        }}
        onDelete={items =>
          this.props.deleteMany({ variables: { where: { id_in: items.map(item => item.id as string) } } })
        }
        onSearch={(text: string) =>
          this.setState({
            filters: this.state.filters.set('search', item =>
              [
                item.number,
                item.model,
                format(item.insurance_from || '', 'DD.MM.YYYY'),
                format(item.insurance_to || '', 'DD.MM.YYYY'),
                format(item.permit_from || '', 'DD.MM.YYYY'),
                format(item.permit_to || '', 'DD.MM.YYYY'),
                format(item.maintenance_from || '', 'DD.MM.YYYY'),
                format(item.maintenance_to || '', 'DD.MM.YYYY'),
                item.maintenance_mileage,
                item.maintenance_comment,
                format(item.mot_from || '', 'DD.MM.YYYY'),
                format(item.mot_to || '', 'DD.MM.YYYY'),
              ]
                .join('')
                .toUpperCase()
                .replace(/ /g, '')
                .includes(text.toUpperCase())
            )
          })
        }
      />
    );
  }
}

export default carsCompose(Cars);
