import * as React from 'react';
import { clearTime, IState } from '../shared';
import { Default } from './Default';
import { IExpensesProps, IExpense, expensesCompose, expenseTypes } from '../shared/expenses';
import ExpenseModal from '../components/ExpenseModal';
import ExpensesTable from '../components/ExpensesTable';
import * as format from 'date-fns/format';
import { DatePicker } from '@atlaskit/datetime-picker';
import { UserSelect } from '../components/UserSelect';
import * as isBefore from 'date-fns/is_before';
import { MoneySelect } from '../components/MoneySelect';
import * as startOfDay from 'date-fns/start_of_day';
import * as endOfDay from 'date-fns/end_of_day';
import * as startOfMonth from 'date-fns/start_of_month';
import * as endOfMonth from 'date-fns/end_of_month';
import * as isEqual from 'date-fns/is_equal';

const clearEdit: IExpense = {
  id: '',
  timestamp: clearTime,
  description: '',
  employee: null,
  price: 0,
  type: 'CASH'
};


class Expenses extends React.Component<IExpensesProps, IState<IExpense>> {
  state: IState<IExpense> = {
    filters: new Map()
      .set('dateStart', item => isBefore(startOfMonth(Date.now()), item.timestamp) || isEqual(startOfDay(Date.now()), item.delivery))
      .set('dateEnd', item => isBefore(item.timestamp, endOfMonth(Date.now())))
  };

  addSave (item: IExpense) {
    if (item.employee) {
      return this.props.create({
        variables: {
          data: {
            timestamp: item.timestamp,
            price: item.price,
            type: item.type,
            description: item.description,
            employee: { connect: { id: item.employee.id } }
          }
        }
      });
    }
    return Promise.resolve({ data: null });
  }

  editSave (item: IExpense) {
    if (item.employee) {
      return this.props.update({
        variables: {
          where: { id: item.id },
          data: {
            timestamp: item.timestamp,
            price: item.price,
            type: item.type,
            description: item.description,
            employee: { connect: { id: item.employee.id } }
          }
        }
      });
    }
    return Promise.resolve({ data: null });
  }

  render () {
    const expenses: IExpense[] = Array.from(this.state.filters.values()).reduce(
      (prev: IExpense[], item) => prev.filter(item),
      this.props.data.expenses || []
    );
    return (
      <Default
        title="Расходы"
        addable={true}
        clearEdit={clearEdit}
        defaultSortKey={'timestamp'}
        items={expenses}
        table={props => (
          <ExpensesTable
            items={expenses}
            isLoading={this.props.data.loading}
            {...props}
          />
        )}
        modal={props => (
          <ExpenseModal heading={`${props.isAddModalOpen ? 'Добавить' : 'Редактировать'} расход`} {...props} />
        )}
        bottomBar={
          <div
            style={{
              display: 'grid',
              gridAutoColumns: 'minmax(150px, 200px)',
              gridAutoFlow: 'column',
              gridGap: '10px'
            }}
          >
            <DatePicker
              spacing="compact"
              placeholder="Дата от"
              dateFormat={'DD.MM.YY'}
              defaultValue={startOfMonth(Date.now())}
              onChange={date => {
                this.setState({
                  filters: this.state.filters.set('dateStart', item => date ? isBefore(startOfDay(date), endOfDay(item.timestamp)) : true)
                });
              }}
            />
            <DatePicker
              spacing="compact"
              placeholder="Дата до"
              dateFormat={'DD.MM.YY'}
              defaultValue={endOfMonth(Date.now())}
              onChange={date => {
                this.setState({
                  filters: this.state.filters.set('dateEnd', item => date ? isBefore(startOfDay(item.timestamp), endOfDay(date)) : true)
                });
              }}
            />
            <UserSelect
              placeholder="Сотрудник"
              filter={
                item =>
                  item.types.includes('COMPANY') ||
                  ( item.types.includes('EMPLOYEE') && item.roles.includes('EXPENSE') )
              }
              spacing="compact"
              onSelect={employee => {
                this.setState({
                  filters: this.state.filters.set('employee', item =>
                    employee ? item.employee ? item.employee.id === employee.id : false : true
                  )
                });
              }}
            />
            <MoneySelect
              placeholder="Тип"
              spacing="compact"
              onChange={type => {
                this.setState({
                  filters: this.state.filters.set('type', item => type ? item.type === type.value : true)
                });
              }}
            />
          </div>
        }
        onSave={(item: IExpense, isAddModalOpen: boolean) => isAddModalOpen ? this.addSave(item) : this.editSave(item)}
        onDelete={items =>
          this.props.deleteMany({ variables: { where: { id_in: items.map(item => item.id as string) } } })
        }
        onSearch={(text: string) =>
          this.setState({
            filters: this.state.filters.set('search', item =>
              [
                format(item.timestamp, 'DD.MM.YYYY'),
                item.price,
                expenseTypes[ item.type ],
                item.description,
                item.employee ? item.employee.name : ''
              ]
                .join('')
                .toUpperCase()
                .replace(/ /g, '')
                .includes(text.toUpperCase())
            )
          })
        }
      />
    );
  }
}

export default expensesCompose(Expenses);
