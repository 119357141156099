import * as React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { Checkbox } from '@atlaskit/checkbox';
import Button from '@atlaskit/button';
import EditorEditIcon from '@atlaskit/icon/glyph/editor/edit';
import { IDefaultTableProps } from '../shared';
import { excursionTypes, IExcursion } from '../shared/excursions';
import Lozenge from '@atlaskit/lozenge';

export default class ExcursionsTable extends React.PureComponent<IDefaultTableProps<IExcursion>> {
  render () {
    return (
      <DynamicTable
        head={{
          cells: [
            {
              content: (
                <Checkbox
                  onChange={() =>
                    !this.props.selected.length ? this.props.onSelectAll(this.props.items) : this.props.onUnSelectAll()
                  }
                  label={null}
                  isIndeterminate={this.props.selected.length !== this.props.items.length}
                  isChecked={this.props.selected.length}
                />
              ),
              width: '20px',
              shouldTruncate: false,
              isSortable: false
            },
            {
              key: 'type',
              content: 'Тип',
              shouldTruncate: true,
              isSortable: true,
              width: '100px'
            },
            {
              key: 'title',
              content: 'Наименование',
              shouldTruncate: false,
              isSortable: true
            },
            {
              width: '40px'
            }
          ]
        }}
        rows={this.props.items.map(item => ( {
          cells: [
            {
              content: (
                <Checkbox
                  label={null}
                  isChecked={!!this.props.selected.find(select => select.id === item.id)}
                  onChange={() => this.props.onSelect(item)}
                />
              )
            },
            {
              key: item.type,
              content: <Lozenge appearance={'new'}>{excursionTypes[ item.type ]}</Lozenge>
            },
            {
              key: [item.title, item.description].join('').replace(/ /g, ''),
              content: `${item.title}${item.description ? ' - ' + item.description : ''}`
            },
            {
              content: (
                <Button iconBefore={<EditorEditIcon label="Редактировать"/>} onClick={() => this.props.onEdit(item)}/>
              )
            }
          ],
          key: item.id
        } ))}
        emptyView={<h2>Список экскурсий пуст</h2>}
        defaultPage={1}
        loadingSpinnerSize="large"
        {...this.props}
      />
    );
  }
}
