import * as React from 'react';
import { Grid, GridColumn } from '@atlaskit/page';
import FieldText from '@atlaskit/field-text';
import Modal from '@atlaskit/modal-dialog';
import { IModalProps } from '../shared';
import { Label } from '@atlaskit/field-base';
import { Checkbox } from '@atlaskit/checkbox';
import { IPayment, paymentType } from '../shared/payments';
import { DatePicker } from '@atlaskit/datetime-picker';
import { RadioGroup } from '@atlaskit/radio';
import { UserSelect } from './UserSelect';
import { IUser, User } from '../shared/users';
import EditorRemoveIcon from '@atlaskit/icon/glyph/editor/remove';

interface IProps extends IModalProps<IPayment> {
  canDelete?: boolean;
  usersFilter?: (user: IUser) => boolean;

  onDelete? (item): void;
}

export default class PaymentModal extends React.Component<IProps, IPayment> {
  state: IPayment = this.props.item;

  render () {
    return (
      <Modal
        actions={[
          this.props.canDelete ? {
            iconBefore: <EditorRemoveIcon label={'Удалить'}/>,
            appearance: 'danger',
            onClick: () => this.props.onDelete ? this.props.onDelete(this.state) : null
          } : null,
          { text: 'Сохранить', onClick: () => this.props.onSave(this.state), appearance: 'primary', },
          { text: 'Закрыть', onClick: () => this.props.onCancel() , autoFocus: true}
        ].filter(Boolean)}
        autoFocus={false}
        onClose={() => this.props.onCancel()}
        heading={this.props.heading}
        scrollBehavior="outside"
      >
        <Grid>
          <GridColumn medium={6}>
            <Label label={'Плательщик'}/>
            <UserSelect
              onSelect={user => this.setState({ payer: user })}
              placeholder="Выберите плательщика"
              selected={this.state.payer}
              filter={item => (this.props.usersFilter && this.props.usersFilter(item) ) || User.hasAnyTypes(item, [ 'COMPANY', 'PARTNER' ])}
            />
          </GridColumn>
          <GridColumn medium={6}>
            <Label label={'Получатель'}/>
            <UserSelect
              onSelect={user => this.setState({ payee: user })}
              placeholder="Выберите получателя"
              selected={this.state.payee}
              filter={item => User.hasAnyTypes(item, [ 'CLIENT', 'COMPANY', 'PARTNER' ])}
            />
          </GridColumn>
          <GridColumn medium={6}>
            <Label label={'Дата'}/>
            <DatePicker
              value={this.state.timestamp}
              onChange={value => this.setState({ timestamp: value })}
              autoFocus={true}
              dateFormat={'DD.MM.YY'}
              placeholder={'Введите дату'}
            />
          </GridColumn>
          <GridColumn medium={6}>
            <FieldText
              required={true}
              shouldFitContainer={true}
              label="Cумма"
              type="number"
              value={this.state.price ? this.state.price.toString(10) : ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                this.setState({ price: parseFloat(event.target.value) })
              }
            />
          </GridColumn>
          <GridColumn medium={6}>
            <Label label={'Тип'}/>
            <RadioGroup
              options={Object.keys(paymentType).map(type => ( {
                name: 'type',
                value: type,
                label: paymentType[ type ].it
              } ))}
              value={this.state.type}
              onChange={event => this.setState({ type: event.target.value })}
            />
          </GridColumn>
          <GridColumn medium={6}>
            <Label label={'Статус'}/>
            <Checkbox
              label="Оплачен"
              value={this.state.paid}
              defaultChecked={this.state.paid}
              onChange={() => this.setState({ paid: !this.state.paid })}
            />
          </GridColumn>
        </Grid>
      </Modal>
    );
  }
}
