import * as React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'

import Modal from '@atlaskit/modal-dialog'
import DownloadIcon from '@atlaskit/icon/glyph/download'
import TextArea from '@atlaskit/textarea/dist/es5/components/TextArea'
import { Label } from '@atlaskit/field-base'
import Textfield from '@atlaskit/textfield'
import { IProform } from '../shared/proform'
import { IUser } from '../shared/users'
import SendIcon from '@atlaskit/icon/glyph/send'
import { UserSelect } from './UserSelect'

interface IProps {
  transfers: {
    date: string;
    price: number;
    description: string[];
    transferType: string | null;
  }[];

  users: IUser[];
  transferType?: string;
  lang: 'ru' | 'en';

  onCancel(): void;

  onSave(item: IProform): Promise<{ data: { createProform: IProform } }>;

  onUpdate(item: IProform): Promise<{ data: { updateProform: IProform } }>;
}

export const CreateProformModal: React.FC<IProps> = (props) => {
  const [proform, setProform] = useState<IProform>({
    id: '',
    number: 1,
    transfers: props.transfers,
    company: '',
    lang: props.lang || 'ru',
    address: '',
    country: '',
    telephone: '',
    user: props.users[0],
    users: props.users.map(u => u.id),
  })
  const [downloading, setDownloading] = useState(false)
  const [sending, setSending] = useState(false)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    axios.post(`${ location.origin }/settings`)
      .then(response => {
        setProform({ ...proform, number: response.data.ProformNumber + 1 })
      })
  }, [])

  const onDownload = () => {
    setDownloading(true)

    return fetch(`${ location.origin }/proform/download`, {
      method: 'POST',
      body: JSON.stringify(proform),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `proform-${ proform.number }.pdf`
        document.body.appendChild(a)
        a.click()
        a.remove()
      })
      .then(() => setDownloading(false))
  }
  const onSend = () => {
    setSending(true)

    return axios
      .post(`${ location.origin }/proform/send`, proform)
      .then(() => setSending(false))
  }

  return <Modal
    actions={ [
      {
        text: 'Сохранить',
        onClick: () => {
          setSaving(true)

          if (proform.id) {
            props.onUpdate(proform)
              .then(() => setSaving(false))
          } else {
            props.onSave(proform)
              .then(response => {
                setProform({
                  ...proform,
                  id: response.data.createProform.id,
                  number: response.data.createProform.number,
                })
                setSaving(false)
              })
          }
        },
        isLoading: saving,
      },
      {
        onClick: () => onDownload(),
        iconBefore: <DownloadIcon label={ 'Скачать' }/>,
        isLoading: downloading,
        isDisabled: !proform.id,
      },
      {
        onClick: () => onSend(),
        iconBefore: <SendIcon label={ 'Отправить' }/>,
        isLoading: sending,
        isDisabled: !proform.id || !(proform.user && proform.user.email),
      },
      { text: 'Закрыть', onClick: () => props.onCancel() },
    ] }
    autoFocus={ false }
    onClose={ () => props.onCancel() }
    heading={ `${ proform.id ? 'Редактировать' : 'Создать' } проформу ${ proform.number ? `#${ proform.number }` : '' } [${proform.lang.toUpperCase()}]` }
    width="x-large"
    scrollBehavior="outside"
  >
    <Label label={ 'Заказчик' }/>
    <UserSelect
      placeholder={ 'Выберите закачика' }
      onSelect={ user => setProform({ ...proform, user }) }
      filter={ user => proform.users.includes(user.id) }
      selected={ proform.user }
    />
    <Label label={ 'Компания' }/>
    <Textfield
      placeholder='Введите название компании'
      defaultValue={ proform.company }
      onChange={ (event) => setProform({ ...proform, company: event.target.value }) }
    />
    <Label label={ 'Адрес' }/>
    <Textfield
      placeholder='Введите адрес'
      defaultValue={ proform.address }
      onChange={ (event) => setProform({ ...proform, address: event.target.value }) }
    />
    <Label label={ 'Страна' }/>
    <Textfield
      placeholder='Введите страну'
      defaultValue={ proform.country }
      onChange={ (event) => setProform({ ...proform, country: event.target.value }) }
    />
    <Label label={ 'Телефон' }/>
    <Textfield
      placeholder='Введите номер телефона'
      defaultValue={ proform.telephone }
      onChange={ (event) => setProform({ ...proform, telephone: event.target.value }) }
    />

    <Label label={ 'Трансферы' }/>
    <div style={ { display: 'grid', gridGap: '20px', gridTemplateColumns: '1fr' } }>
      {
        proform.transfers.map((transfer, index) => {
          return <div style={ {
            borderRadius: ' 3px',
            color: ' #42526E',
            padding: ' 16px 32px',
            boxShadow: '0 0 1px rgba(9,30,66,0.31)',
            display: 'grid',
            gridGap: '10px',
            gridTemplateColumns: 'auto 1fr',
            alignItems: 'center',
          } } key={ index }>
            <span>Дата трансфера:</span>
            <Textfield
              placeholder='Введите дату'
              isCompact={ true }
              defaultValue={ transfer.date }
              onChange={ (event) => {
                const transfersCopy = [...proform.transfers]
                transfersCopy[index].date = event.target.value
                setProform({ ...proform, transfers: transfersCopy })
              } }
            />
            <span>Цена трансфера:</span>
            <Textfield
              placeholder='Введите цену'
              isCompact={ true }
              type="number"
              defaultValue={ transfer.price }
              onChange={ (event) => {
                const transfersCopy = [...proform.transfers]
                transfersCopy[index].price = event.target.value
                setProform({ ...proform, transfers: transfersCopy })
              } }
            />
            <span>Описание:</span>
            <div>
              {
                transfer.transferType && <span style={ { color: ' #7A869A' } }>{ transfer.transferType }</span>
              }
              <TextArea
                minimumRows={ 2 }
                value={ proform.transfers[index].description.join('\n') }
                onChange={ (event) => {
                  const transfersCopy = [...proform.transfers]
                  transfersCopy[index].description = event.target.value.split('\n')
                  setProform({ ...proform, transfers: transfersCopy })
                } }
              />
            </div>
          </div>
        })
      }
    </div>
  </Modal>
}
