import * as React from 'react'
import Button, { ButtonGroup } from '@atlaskit/button'
import { Grid, GridColumn } from '@atlaskit/page'
import InlineDialog from '@atlaskit/inline-dialog';
import FieldText from '@atlaskit/field-text'
import Modal, { ModalHeader, ModalTitle } from '@atlaskit/modal-dialog'
import { IModalProps, localePrice, localeTime, utcDate } from '../shared'
import { DatePicker } from '@atlaskit/datetime-picker'
import { Label } from '@atlaskit/field-base'
import {
  IOrder,
  orderStatus,
  orderStatusAppearance,
  orderStatusButtonAppearance,
  orderTheme,
  transferType,
} from '../shared/orders'
import { themeNamespace } from '@atlaskit/button'
import TextArea from '@atlaskit/textarea'
import { ICar } from '../shared/cars'
import Select from '@atlaskit/select'
import DropdownMenu, { DropdownItem } from '@atlaskit/dropdown-menu'
import Lozenge from '@atlaskit/lozenge'
import { ThemeProvider } from 'styled-components'
import { Date } from '@atlaskit/date'
import { IPayment, paymentType } from '../shared/payments'
import { UserSelect } from './UserSelect'
import { IUser, User } from '../shared/users'
import { colors } from '@atlaskit/theme'
import { CarSelect } from './CarSelect'
import * as moment from 'moment'

import PresenceActiveIcon from '@atlaskit/icon/glyph/presence-active'
import EditorEditIcon from '@atlaskit/icon/glyph/editor/edit'
import EditorInfoIcon from '@atlaskit/icon/glyph/editor/info'
import DynamicTable from '@atlaskit/dynamic-table'
import { useEffect, useState } from 'react'

interface IProps extends IModalProps<IOrder> {
  onCreateClient(): void;

  onCreateReferer(): void;

  onCreateProvider(): void;

  onSelectClient(user: IUser): void;

  onSelectReferer(user: IUser): void;

  onSelectProvider(user: IUser): void;

  onCreatePayment(): void;

  onEditPayment(item: IPayment, index: number): void;

  onDriverSend(item: IOrder): void;
}

export const OrderModal: React.FC<IProps> = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [order, setOrder] = useState(props.item)
  const [description, setDescription] = useState(props.item.description)
  const [client, setClient] = useState(props.item.client)
  const [referer, setReferer] = useState(props.item.referer)
  const [provider, setProvider] = useState(props.item.provider)
  const [delivery, setDelivery] = useState(props.item.delivery)

  useEffect(() => {
    setClient(props.item.client)
  }, [props.item.client])

  useEffect(() => {
    setReferer(props.item.referer)
  }, [props.item.referer])

  useEffect(() => {
    setProvider(props.item.provider)
  }, [props.item.provider])

  return (
    <Modal
      actions={ [
        {
          text: 'Сохранить',
          onClick: () => props.onSave({
            ...order,
            description,
            client,
            referer,
            provider,
            delivery,
          }),
        },
        { text: 'Закрыть', onClick: () => props.onCancel(), autoFocus: true },
      ] }
      autoFocus={ false }
      onClose={ () => props.onCancel() }
      components={ {
        Header: () => (
          <ModalHeader>
            <ModalTitle>{ props.heading }</ModalTitle>
            <ButtonGroup>
              {
                (order.tid || order.cid || order.seo) &&
                <InlineDialog
                  onClose={() => setDialogOpen(false)}
                  content={<div style={ { display: 'grid' } }>
                    <span><b>tid</b>: { order.tid }</span>
                    <span><b>cid</b>: { order.cid }</span>
                    {
                      order.seo && Object.keys(order.seo).map(key => {
                        // @ts-ignore
                        return <span key={key}><b>{key}</b>: { decodeURI(order.seo[key]) }</span>
                      })
                    }
                  </div>}
                  isOpen={dialogOpen}
                  placement={'left-start'}
                >
                  <Button
                    appearance={'link'}
                    isSelected={dialogOpen}
                    iconBefore={
                      <EditorInfoIcon label="SEO" onClick={ () => setDialogOpen(!dialogOpen) }/>
                    }
                  />
                </InlineDialog>
              }
              <ThemeProvider theme={ { [themeNamespace]: orderTheme } }>
                <DropdownMenu
                  trigger={ orderStatus[order.status] }
                  triggerType="button"
                  triggerButtonProps={ { appearance: orderStatusButtonAppearance[order.status] } }
                  shouldFlip={ false }
                  position="bottom right"
                  onItemActivated={ console.log }
                >
                  { Object.keys(orderStatus).map(status => (
                    <DropdownItem onClick={ () => setOrder({ ...order, status }) } key={ status }>
                      <Lozenge appearance={ orderStatusAppearance[status] }>{ orderStatus[status] }</Lozenge>
                    </DropdownItem>
                  )) }
                </DropdownMenu>
              </ThemeProvider>
            </ButtonGroup>
          </ModalHeader>
        ),
      } }
      scrollBehavior="outside"
      width="x-large"
    >
      <Grid>
        <GridColumn medium={ 8 }>
          <div style={ { display: 'grid', gridGap: '10px', gridTemplateColumns: '2fr 3fr 2fr 3fr' } }>
            <div>
              <Label label={ 'Дата подачи' }/>
              <DatePicker
                value={ utcDate(delivery).format('YYYY-MM-DD') }
                onChange={ value => {
                  setDelivery(utcDate(delivery)
                    .set({
                      year: moment(value).year(),
                      month: moment(value).month(),
                      date: moment(value).date(),
                    })
                    .toISOString())
                } }
                autoFocus={ true }
                dateFormat={ 'DD.MM.YY' }
              />
            </div>
            <div>
              <Label label={ 'Время подачи' }/>
              <div style={ { display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '10px' } }>
                <Select
                  options={ Array.from(Array(24), (v, k) => ({ label: localeTime(k), value: k })) }
                  defaultValue={ {
                    label: utcDate(delivery).format('HH'),
                    value: utcDate(delivery).hour(),
                  } }
                  onChange={ (item: { value: number } | null) => {
                    setDelivery(utcDate(delivery).hour(item ? item.value : 0).toISOString())
                  } }
                  placeholder={ 'HH' }
                />
                <Select
                  options={ Array.from(Array(12), (v, k) => ({ label: localeTime(k * 5), value: k * 5 })) }
                  defaultValue={ {
                    label: utcDate(delivery).format('mm'),
                    value: utcDate(delivery).minute(),
                  } }
                  onChange={ (item: { value: number } | null) => {
                    setDelivery(utcDate(delivery).minute(item ? item.value : 0).toISOString())
                  } }
                  placeholder={ 'mm' }
                />
              </div>
            </div>
            <FieldText
              label={ 'Рейс' }
              shouldFitContainer={ true }
              value={ order.flight || '' }
              onChange={ (event: React.ChangeEvent<HTMLInputElement>) => setOrder({
                ...order,
                flight: event.target.value,
              }) }
            />
            <div>
              <Label label={ 'Время рейса' }/>
              <div style={ { display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '10px' } }>
                <Select
                  options={ Array.from(Array(24), (v, k) => ({ label: localeTime(k), value: k })) }
                  defaultValue={
                    order.flightTime && order.flightTime.split(':')[0].length > 0
                      ? {
                        label: order.flightTime.split(':')[0],
                        value: parseInt(order.flightTime.split(':')[0], 10),
                      }
                      : null
                  }
                  onChange={ (item: { value: number } | null) => {
                    setOrder({
                      ...order,
                      flightTime: order.flightTime
                        ? `${ item ? item.value : '' }:${ order.flightTime.split(':')[1] }`
                        : `${ item ? item.value : '' }:`,
                    })
                  } }
                  isClearable={ true }
                  placeholder={ 'ч.' }
                />
                <Select
                  options={ Array.from(Array(12), (v, k) => ({ label: localeTime(k * 5), value: k * 5 })) }
                  defaultValue={
                    order.flightTime && order.flightTime.split(':')[1].length > 0
                      ? {
                        label: order.flightTime.split(':')[1],
                        value: parseInt(order.flightTime.split(':')[1], 10),
                      }
                      : null
                  }
                  onChange={ (item: { value: number } | null) =>
                    setOrder({
                      ...order,
                      flightTime: order.flightTime
                        ? `${ order.flightTime.split(':')[0] }:${ item ? item.value : '' }`
                        : `:${ item ? item.value : '' }`,
                    })
                  }
                  isClearable={ true }
                  placeholder={ 'м.' }
                />
              </div>
            </div>
          </div>
          <Grid>
            <GridColumn medium={ 4 }>
              <FieldText
                label={ 'Откуда' }
                shouldFitContainer={ true }
                value={ order.departure || '' }
                onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
                  setOrder({ ...order, departure: event.target.value })
                }
              />
            </GridColumn>
            <GridColumn medium={ 4 }>
              <FieldText
                label={ 'Куда' }
                shouldFitContainer={ true }
                value={ order.destination || '' }
                onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
                  setOrder({ ...order, destination: event.target.value })
                }
              />
            </GridColumn>
            <GridColumn medium={ 8 }>
              <FieldText
                label={ 'Второй адрес' }
                shouldFitContainer={ true }
                value={ order.intermediate || '' }
                onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
                  setOrder({ ...order, intermediate: event.target.value })
                }
              />
            </GridColumn>
            <GridColumn medium={ 2 }>
              <FieldText
                label={ 'Количество человек' }
                shouldFitContainer={ true }
                value={ order.passengers ? order.passengers.toString(10) : '' }
                type={ 'number' }
                onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
                  setOrder({ ...order, passengers: parseInt(event.target.value, 10) })
                }
              />
            </GridColumn>
            <GridColumn medium={ 3 }>
              <FieldText
                label={ 'Количество багажа' }
                shouldFitContainer={ true }
                value={ order.luggage || '' }
                onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
                  setOrder({ ...order, luggage: event.target.value })
                }
              />
            </GridColumn>
            <GridColumn medium={ 3 }>
              <Label label="Класс автомобиля"/>
              <Select
                options={ [
                  'Mercedes class Е',
                  'Mercedes class Е 2019',
                  'Mercedes Viano',
                  'Mercedes class V',
                  'Mercedes class S Long',
                  'Mercedes Sprinter',
                ].map(key => ({ label: key, value: key })) }
                maxMenuHeight={ 150 }
                placeholder={ 'Выберите класс' }
                defaultValue={ order.carClass ? {
                  label: order.carClass,
                  value: order.carClass,
                } : null }
                onChange={ item => setOrder({ ...order, carClass: item ? item.value : null }) }
                isClearable={ true }
              />
            </GridColumn>

            <GridColumn medium={ 12 }>
              <FieldText
                label={ 'Встречать с табличкой' }
                shouldFitContainer={ true }
                value={ order.sign || '' }
                onChange={ (event: React.ChangeEvent<HTMLInputElement>) => setOrder({
                  ...order,
                  sign: event.target.value,
                }) }
              />
            </GridColumn>

            <GridColumn medium={ 12 }>
              {
                <React.Fragment>
                  <Label label="Описание"/>
                  <TextArea
                    value={ description || '' }
                    onChange={ (e) => setDescription(e.target.value) }
                    minimumRows={ 7 }
                    spellCheck={ false }
                    maxHeight={ '130px' }
                  />
                </React.Fragment>
              }
            </GridColumn>
            <GridColumn medium={ 12 }>
              <Label
                label={
                  <>Pagamenti (<span style={ { color: colors.B300 } }
                                     onClick={ props.onCreatePayment }>создать</span>)</>
                }
              />
              <DynamicTable
                head={ {
                  cells: [
                    {
                      width: '20px',
                    },
                    {
                      key: 'timestamp',
                      content: 'Дата',
                      shouldTruncate: true,
                      width: '80px',
                    },
                    {
                      key: 'price',
                      content: 'Сумма',
                      shouldTruncate: true,
                      width: '60px',
                    },
                    {
                      key: 'type',
                      content: 'Tipo',
                      width: '60px',
                    },
                    {
                      key: 'payer',
                      content: 'Плательщик',
                      shouldTruncate: true,
                    },
                    {
                      key: 'payee',
                      content: 'Получатель',
                      shouldTruncate: true,
                    },
                    {
                      width: '40px',
                    },
                  ],
                } }
                rows={ (props.item.payments || []).map((item, index) => ({
                  cells: [
                    {
                      key: item.paid,
                      content: (
                        <PresenceActiveIcon
                          label={ 'Pagato' }
                          size="medium"
                          primaryColor={ item.paid ? colors.G300 : colors.N100 }
                        />
                      ),
                    },
                    {
                      key: item.timestamp,
                      content: <Date value={ item.timestamp } format="DD.MM.YYYY"/>,
                    },
                    {
                      key: item.price,
                      content: localePrice(item.price),
                    },
                    {
                      key: item.type,
                      content: paymentType[item.type].it,
                    },
                    {
                      key: item.payer ? item.payer.name.replace(/ /g, '') : '',
                      content: item.payer ? item.payer.name : '',
                    },
                    {
                      key: item.payee ? item.payee.name.replace(/ /g, '') : '',
                      content: item.payee ? item.payee.name : '',
                    },
                    {
                      content: (
                        <Button
                          iconBefore={
                            <EditorEditIcon label="Редактировать" onClick={ () => props.onEditPayment(item, index) }/>
                          }
                        />
                      ),
                    },
                  ],
                  key: item.id,
                })) }
                emptyView={ <h4>Список платежей пуст</h4> }
                rowsPerPage={ 5 }
                defaultPage={ 1 }
                loadingSpinnerSize="large"
              />
            </GridColumn>
          </Grid>
        </GridColumn>
        <GridColumn medium={ 4 }>
          <Grid>
            <GridColumn medium={ 12 }>
              <Label label={ 'Тип трансфера' }/>
              <Select
                options={
                  Object.keys(transferType)
                    .map(type => ({
                      label: transferType[type].ru,
                      value: type,
                    }))
                }
                defaultValue={
                  order.transferType && Object.keys(transferType).includes(order.transferType)
                    ? { label: transferType[order.transferType].ru, value: order.transferType }
                    : null
                }
                onChange={
                  (selected: { value: string } | null) => setOrder({
                    ...order,
                    transferType: selected ? selected.value : null,
                  })
                }
                placeholder={ 'Выберите тип трансфера' }
                isClearable={ true }
              />
            </GridColumn>
            <GridColumn medium={ 12 }>
              <FieldText
                shouldFitContainer={ true }
                label={ '№ Ваучера' }
                value={ order.voucher ? order.voucher : '' }
                onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
                  setOrder({ ...order, voucher: event.target.value })
                }
              />
            </GridColumn>
            <GridColumn medium={ 12 }>
              <Label
                label={
                  <>Источник (<span style={ { color: colors.B300 } }
                                    onClick={ props.onCreateReferer }>создать</span>)</>
                }
              />
              <UserSelect
                onSelect={ props.onSelectReferer }
                placeholder="Выберите партнера"
                selected={ props.item.referer }
                defaultValue={ props.item.referer }
                filter={ item => User.hasAnyTypes(item, ['PARTNER', 'COMPANY']) && User.hasRole(item, 'REFERER') }
              />
            </GridColumn>
            <GridColumn medium={ 12 }>
              <Label
                label={
                  <>Исполнитель (<span style={ { color: colors.B300 } }
                                       onClick={ props.onCreateProvider }>создать</span>)</>
                }
              />
              <UserSelect
                onSelect={ props.onSelectProvider }
                placeholder="Выберите партнера"
                selected={ props.item.provider }
                defaultValue={ props.item.provider }
                filter={ item => User.hasAnyTypes(item, ['PARTNER', 'COMPANY']) && User.hasRole(item, 'PROVIDER') }
              />
            </GridColumn>
            <GridColumn medium={ 12 }>
              <Label
                label={
                  <>Клиент (<span style={ { color: colors.B300 } } onClick={ props.onCreateClient }>создать</span>)</>
                }
              />
              <UserSelect
                onSelect={ props.onSelectClient }
                placeholder="Выберите клиента"
                selected={ props.item.client }
                defaultValue={ props.item.client }
                filter={ item => User.hasType(item, 'CLIENT') }
                maxMenuHeight={ 150 }
              />
            </GridColumn>
            <GridColumn medium={ 12 }>
              <Label label={ 'Автомобиль' }/>
              <CarSelect
                placeholder="Автомобиль"
                onSelect={ (car: ICar | null) => setOrder({ ...order, car }) }
                selected={ order.car }
                menuPlacement={ 'top' }
              />
            </GridColumn>
            <GridColumn medium={ 12 }>
              <Label label={
                order.id ?
                  <>
                    Водитель (<span style={ { color: colors.B300 } }
                                    onClick={ () => props.onDriverSend(order) }>отправить</span>)
                  </>
                  : 'Водитель'
              }/>
              <UserSelect
                onSelect={ user => setOrder({ ...order, driver: user }) }
                placeholder="Выберите водителя"
                selected={ order.driver }
                filter={ item => User.hasType(item, 'EMPLOYEE') && User.hasRole(item, 'DRIVER') }
                menuPlacement={ 'top' }
              />
            </GridColumn>
          </Grid>
        </GridColumn>
      </Grid>
    </Modal>
  )
}
