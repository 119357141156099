import Navigation from './Navigation';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { Switch, Route } from 'react-router-dom';
import Orders from './views/Orders';
import Expenses from './views/Expenses';
import Cars from './views/Cars';
import Partners from './views/Partners';
import Clients from './views/Clients';
import Settings from './views/Settings';
import Dashboard from './views/Dashboard';
import Employees from './views/Employees';
import Payments from './views/Payments';
import Voucher from './views/Vouchers';
import * as React from 'react';
import Excursions from './views/Excursions';
import Authorizations from './views/Authorizations'
import Proforms from './views/Proforms'

class App extends React.Component<{}, {}> {
  public render () {
    return (
      <Page>
        <Navigation/>
        <Grid layout="fluid">
          <GridColumn medium={12}>
            <Switch>
              <Route path="/orders" component={Orders}/>
              <Route path="/expenses" component={Expenses}/>
              <Route path="/payments" component={Payments}/>
              <Route path="/vouchers" component={Voucher}/>
              <Route path="/authorizations" component={Authorizations}/>
              <Route path="/proforms" component={Proforms}/>
              <Route path="/clients" component={Clients}/>
              <Route path="/partners" component={Partners}/>
              <Route path="/employees" component={Employees}/>
              <Route path="/cars" component={Cars}/>
              <Route path="/excursions" component={Excursions}/>
              <Route path="/settings" component={Settings}/>
              <Route component={Dashboard}/>
            </Switch>
          </GridColumn>
        </Grid>
        <br/>
      </Page>
    );
  }
}

export default App;
