import * as React from 'react';
import { useState } from 'react';
import axios from 'axios';
import Textfield from '@atlaskit/textfield';
import Modal from '@atlaskit/modal-dialog';
import DownloadIcon from '@atlaskit/icon/glyph/download';
import SendIcon from '@atlaskit/icon/glyph/send';
import TextArea from '@atlaskit/textarea/dist/es5/components/TextArea';
import { Label } from '@atlaskit/field-base';
import Select from '@atlaskit/select'
import { UserSelect } from './UserSelect';
import { IModalProps } from '../shared';
import { IProform } from '../shared/proform'

export const UpdateProformModal: React.FC<IModalProps<IProform> > = (props) => {
  const [proform, setProform] = useState<IProform>(props.item);
  const [downloading, setDownloading] = useState(false);
  const [sending, setSending] = useState(false);
  const [saving, setSaving] = useState(false);
  const onDownload = () => {
    setDownloading(true);

    return fetch(`${location.origin}/proform/download`, {
      method: 'POST',
      body: JSON.stringify(proform),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `proform-${ proform.number }.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .then(() => setDownloading(false));
  };
  const onSend = () => {
    setSending(true);
    return axios
      .post(`${location.origin}/proform/send`, proform)
      .then(() => setSending(false));
  };

  return (
    <Modal
      actions={ [
        {
          text: 'Сохранить', onClick: () => {
            setSaving(true);
            props
              .onSave(proform)
              .then(() => setSaving(false));
          },
          isLoading: saving
        },
        {
          onClick: () => onDownload(),
          iconBefore: <DownloadIcon label={ 'Скачать' }/>,
          isLoading: downloading
        },
        {
          onClick: () => onSend(),
          iconBefore: <SendIcon label={ 'Отправить' }/>,
          isLoading: sending,
          isDisabled: !(proform.user && proform.user.email)
        },
        { text: 'Закрыть', onClick: () => props.onCancel(), autoFocus: true }
      ] }
      autoFocus={ false }
      onClose={ () => props.onCancel() }
      heading={ `Редактировать проформу #${ proform.number }` }
      width="x-large"
      scrollBehavior="outside"
    >
      <Select
        options={ [{ value: 'ru', label: 'RU' }, { value: 'en', label: 'EN' }] }
        maxMenuHeight={ 200 }
        placeholder="Язык"
        onChange={
          (item: { value: string } | null) => setProform({ ...proform, lang: item ? item.value : 'ru' })
        }
        value={ { label: proform.lang ? proform.lang.toUpperCase() : 'RU', value: proform.lang || 'ru' } }
        defaultValue={ { label: 'RU', value: 'ru' } }
      />
      <Label label={ 'Заказчик' }/>
      <UserSelect
        placeholder={ 'Выберите закачика' }
        onSelect={ user => setProform({ ...proform, user }) }
        filter={ user => proform.users.includes(user.id) }
        selected={ proform.user }
      />
      <Label label={ 'Компания' }/>
      <Textfield
        placeholder='Введите название компании'
        defaultValue={ proform.company }
        onChange={ (event) => setProform({ ...proform, company: event.target.value }) }
      />
      <Label label={ 'Адрес' }/>
      <Textfield
        placeholder='Введите адрес'
        defaultValue={ proform.address }
        onChange={ (event) => setProform({ ...proform, address: event.target.value }) }
      />
      <Label label={ 'Страна' }/>
      <Textfield
        placeholder='Введите страну'
        defaultValue={ proform.country }
        onChange={ (event) => setProform({ ...proform, country: event.target.value }) }
      />
      <Label label={ 'Телефон' }/>
      <Textfield
        placeholder='Введите номер телефона'
        defaultValue={ proform.telephone }
        onChange={ (event) => setProform({ ...proform, telephone: event.target.value }) }
      />

      <Label label={ 'Трансферы' }/>
      <div style={ { display: 'grid', gridGap: '20px', gridTemplateColumns: '1fr' } }>
        {
          proform.transfers.map((transfer, index) => {
            return <div style={ {
              borderRadius: ' 3px',
              color: ' #42526E',
              padding: ' 16px 32px',
              boxShadow: '0 0 1px rgba(9,30,66,0.31)',
              display: 'grid',
              gridGap: '10px',
              gridTemplateColumns: 'auto 1fr',
              alignItems: 'center',
            } } key={ index }>
              <span>Дата трансфера:</span>
              <Textfield
                placeholder='Введите дату'
                isCompact={ true }
                defaultValue={ transfer.date }
                onChange={ (event) => {
                  const transfersCopy = [...proform.transfers]
                  transfersCopy[index].date = event.target.value
                  setProform({ ...proform, transfers: transfersCopy })
                } }
              />
              <span>Цена трансфера:</span>
              <Textfield
                placeholder='Введите цену'
                isCompact={ true }
                type="number"
                defaultValue={ transfer.price }
                onChange={ (event) => {
                  const transfersCopy = [...proform.transfers]
                  transfersCopy[index].price = event.target.value
                  setProform({ ...proform, transfers: transfersCopy })
                } }
              />
              <span>Описание:</span>
              <div>
                {
                  transfer.transferType && <span style={ { color: ' #7A869A' } }>{ transfer.transferType }</span>
                }
                <TextArea
                  minimumRows={ 2 }
                  value={ proform.transfers[index].description.join('\n') }
                  onChange={ (event) => {
                    const transfersCopy = [...proform.transfers]
                    transfersCopy[index].description = event.target.value.split('\n')
                    setProform({ ...proform, transfers: transfersCopy })
                  } }
                />
              </div>
            </div>
          })
        }
      </div>
    </Modal>
  );
};
