import * as React from 'react';
import { Grid, GridColumn } from '@atlaskit/page';
import FieldText from '@atlaskit/field-text';
import Modal from '@atlaskit/modal-dialog';
import { IModalProps } from '../shared';
import { Label } from '@atlaskit/field-base';
import { Checkbox } from '@atlaskit/checkbox';
import { IUser, UserRoles } from '../shared/users';

export default class EmployeeModal extends React.Component<IModalProps<IUser>, {}> {
  state: IUser = this.props.item;

  fieldText (key: string, label: string) {
    return (
      <FieldText
        label={label}
        shouldFitContainer={true}
        value={this.state[ key ] || ''}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({ [ key ]: event.target.value })}
      />
    );
  }


  render () {
    return (
      <Modal
        actions={[
          { text: 'Сохранить', onClick: () => this.props.onSave(this.state) },
          { text: 'Закрыть', onClick: () => this.props.onCancel(), autoFocus: true}
        ]}
        autoFocus={false}
        onClose={() => this.props.onCancel()}
        heading={this.props.heading}
      >
        <Grid>
          <GridColumn medium={12}>{this.fieldText('name', 'Имя')}</GridColumn>
          <GridColumn medium={12}>{this.fieldText('phone', 'Tелефон')}</GridColumn>
          <GridColumn medium={12}>{this.fieldText('email', 'Email')}</GridColumn>
          <GridColumn medium={12}>
            <Label label={'Роль'}/>
            {Object.keys(UserRoles)
              .filter(key => ![ 'REFERER', 'PROVIDER' ].includes(key))
              .map(role => (
                <Checkbox
                  label={UserRoles[ role ]}
                  value={role}
                  key={role}
                  defaultChecked={this.state.roles.includes(role)}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({
                      roles: this.state.roles.includes(event.target.value)
                        ? this.state.roles.filter(item => item !== event.target.value)
                        : [ ...this.state.roles, event.target.value ]
                    });
                  }}
                />
              ))}
          </GridColumn>
        </Grid>
      </Modal>
    );
  }
}
