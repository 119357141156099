import * as React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { Checkbox } from '@atlaskit/checkbox';
import Button from '@atlaskit/button';
import EditorEditIcon from '@atlaskit/icon/glyph/editor/edit';
import { expenseTypes, IExpense } from '../shared/expenses';
import Lozenge from '@atlaskit/lozenge';
import { Date } from '@atlaskit/date';
import { Card, CardCounter, CardTitle, IDefaultTableProps, localePrice } from '../shared';

export default class ExpensesTable extends React.PureComponent<IDefaultTableProps<IExpense>, {}> {
  state = { expanded: false };

  render () {
    let totalCash = 0;
    let totalFacture = 0;
    let totalCard = 0;


    this.props.items.map(item => {
      if (item.type === 'INVOICE') {
        totalFacture += item.price;
      } else if (item.type === 'CASH') {
        totalCash += item.price;
      } else {
        totalCard += item.price;
      }
    });

    return (
      <React.Fragment>
        <DynamicTable
          head={{
            cells: [
              {
                content: (
                  <Checkbox
                    onChange={() =>
                      !this.props.selected.length ? this.props.onSelectAll(this.props.items) : this.props.onUnSelectAll()
                    }
                    label={null}
                    isIndeterminate={this.props.selected.length !== this.props.items.length}
                    isChecked={this.props.selected.length}
                  />
                ),
                width: '20px',
                shouldTruncate: false,
                isSortable: false
              },
              {
                key: 'timestamp',
                content: 'Дата',
                shouldTruncate: true,
                isSortable: true,
                width: '80px'
              },
              {
                key: 'type',
                content: 'Тип',
                shouldTruncate: true,
                isSortable: true,
                width: '80px'
              },
              {
                key: 'employee',
                content: 'Сотрудник',
                shouldTruncate: true,
                isSortable: true,
                width: '150px'
              },
              {
                key: 'price',
                content: 'Сумма',
                shouldTruncate: true,
                isSortable: true,
                width: '80px'
              },
              {
                key: 'description',
                content: 'Описание',
                shouldTruncate: !this.state.expanded,
                isSortable: true,
              },
              {
                width: '40px'
              }
            ]
          }}
          rows={this.props.items.map(item => ( {
            cells: [
              {
                content: (
                  <Checkbox
                    label={null}
                    isChecked={!!this.props.selected.find(select => select.id === item.id)}
                    onChange={() => this.props.onSelect(item)}
                  />
                )
              },
              {
                key: item.timestamp,
                content: <Date value={item.timestamp} format="DD.MM.YYYY"/>
              },
              {
                key: item.type,
                content: <Lozenge appearance={'new'}>{expenseTypes[ item.type ]}</Lozenge>
              },
              {
                key: item.employee,
                content: item.employee ? item.employee.name : ''
              },
              {
                key: item.price,
                content: localePrice(item.price)
              },
              {
                key: item.description,
                content: item.description,
                onClick: () => this.setState({ expanded: !this.state.expanded }),
              },
              {
                content: (
                  <Button
                    iconBefore={<EditorEditIcon label="Редактировать"/>}
                    onClick={() => this.props.onEdit(item)}
                  />
                )
              }
            ],
            key: item.id
          } ))}
          emptyView={<h2>Список расходов пуст</h2>}
          rowsPerPage={localStorage.getItem('rowsPerPage') || 10}
          loadingSpinnerSize="large"
          {...this.props}
        />
        <div
          style={{
            marginTop: '20px',
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, 300px)',
            gridGap: '20px'
          }}
        >
          <Card>
            <CardTitle>Итого, всего</CardTitle>
            <CardCounter>{localePrice(totalCash + totalCard + totalFacture)}</CardCounter>
          </Card>
          <Card>
            <CardTitle>Итого, наличные</CardTitle>
            <CardCounter>{localePrice(totalCash)}</CardCounter>
          </Card>
          <Card>
            <CardTitle>Итого, карта</CardTitle>
            <CardCounter>{localePrice(totalCard)}</CardCounter>
          </Card>
          <Card>
            <CardTitle>Итого, фактура</CardTitle>
            <CardCounter>{localePrice(totalFacture)}</CardCounter>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}
