import * as React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { Checkbox } from '@atlaskit/checkbox';
import Button from '@atlaskit/button';
import EditorEditIcon from '@atlaskit/icon/glyph/editor/edit';
import { IDefaultTableProps } from '../shared';
import { IVoucher } from '../shared/voucher';

export default class VouchersTable extends React.PureComponent<IDefaultTableProps<IVoucher>> {
  render () {
    return (
      <DynamicTable
        head={{
          cells: [
            {
              content: (
                <Checkbox
                  onChange={() =>
                    !this.props.selected.length ? this.props.onSelectAll(this.props.items) : this.props.onUnSelectAll()
                  }
                  label={null}
                  isIndeterminate={this.props.selected.length !== this.props.items.length}
                  isChecked={this.props.selected.length}
                />
              ),
              width: '20px',
              shouldTruncate: false,
              isSortable: false
            },
            {
              key: 'number',
              content: 'Номер',
              shouldTruncate: true,
              isSortable: true,
              width: '80px'
            },
            {
              key: 'user',
              content: 'Заказчик',
              shouldTruncate: true,
              isSortable: true
            },
            {
              width: '40px'
            }
          ]
        }}
        rows={this.props.items.map(item => ( {
          cells: [
            {
              content: (
                <Checkbox
                  label={null}
                  isChecked={!!this.props.selected.find(select => select.id === item.id)}
                  onChange={() => this.props.onSelect(item)}
                />
              )
            },
            {
              key: item.number,
              content: item.number
            },
            {
              key: item.user ? item.user.name : '',
              content: item.user ? item.user.name : ''
            },
            {
              content: (
                <Button iconBefore={<EditorEditIcon label="Редактировать"/>} onClick={() => this.props.onEdit(item)}/>
              )
            }
          ],
          key: item.id
        } ))}
        emptyView={<h2>Список ваучеров пуст</h2>}
        rowsPerPage={10}
        defaultPage={1}
        loadingSpinnerSize="large"
        {...this.props}
      />
    );
  }
}
