import * as React from 'react'
import { Default } from './Default'
import { IState } from '../shared'
import { IProform, IProformComponentProps, proformsCompose } from '../shared/proform'
import { UpdateProformModal } from '../components/UpdateProformModal'
import ProformTable from '../components/ProformTable'

const clearEdit: IProform = {
  id: '',
  number: 0,
  transfers: [],
  company: '',
  address: '',
  country: '',
  lang: 'ru',
  telephone: '',
  user: null,
  users: [],
}

class Proforms extends React.Component<IProformComponentProps, IState<IProform>> {
  state: IState<IProform> = { filters: new Map() }

  updateProform(proform: IProform, previous?: IProform) {
    return this.props.update({
      variables: {
        data: {
          number: proform.number,
          transfers: proform.transfers,
          company: proform.company,
          address: proform.address,
          country: proform.country,
          lang: proform.lang,
          telephone: proform.telephone,
          user: proform.user
            ? { connect: { id: proform.user.id } }
            : previous && previous.user
              ? { disconnect: true }
              : null,
          users: {
            set: proform.users,
          },
        },
        where: { id: proform.id },
      },
    })
  }

  render() {
    const proforms: IProform[] = Array.from(this.state.filters.values()).reduce<IProform[]>(
      (prev, item) => prev.filter(item), this.props.data.proforms || [],
    )

    return (
      <Default
        title={ 'Проформы' }
        clearEdit={ clearEdit }
        defaultSortKey={ 'number' }
        items={ proforms }
        table={ props => (
          <ProformTable
            items={ proforms }
            isLoading={ this.props.data.loading }
            { ...props }
          />
        ) }
        modal={ props => (
          <UpdateProformModal
            { ...props }
          />
        ) }
        noClose={ true }
        onSave={ (item: IProform) => this.updateProform(item, proforms.find(v => v.id === item.id)) }
        onDelete={ (items: IProform[]) =>
          this.props.deleteMany({ variables: { where: { id_in: items.map(item => item.id as string) } } })
        }
        onSearch={ (text: string) =>
          this.setState({
            filters: this.state.filters.set('search', item =>
              [
                item.number,
                item.company,
                item.address,
                item.country,
                item.telephone,
              ]
                .join('')
                .toUpperCase()
                .replace(/ /g, '')
                .includes(text.toUpperCase()),
            ),
          })
        }
      />
    )
  }
}

export default proformsCompose(Proforms)
